import React from "react";
import ManageVestingData from "../../helpers/manageVestingData";
import {VestingTable} from "./component/vestingTable/vestingTable";
import {CheckNetwork} from "../../components/network/checkNetwork";
import css from "./Vest.module.css";
import {CardComponent} from "../../components/cards/cardComponent/CardComponent";

export const Vest: React.FC = () => {
    return (
        <div>
            <CheckNetwork/>
            <div className={css.container}>
                <div style={{padding: '20px 0 0 0', width: '100%', maxWidth: 1300}}>
                    {ManageVestingData.vestData.map(element =>
                        <VestingTable key={element.id} vestContract={element}/>
                    )}
                </div>

                <CardComponent>
                    <div className={css.faq}>
                        <h1 className={css.faqTitle}>VESTING STAKING</h1>
                        <p className={css.description}>Staking vesting allows you to open several staking positions and
                            of different durations (from 1 to 365 days)

                            There is no minimum required, you can put in as much staking as you want.

                            In case of emergency you can withdraw before the locking period expires losing 100% of the
                            rewards</p>

                        <p className={css.description}>Contract:

                            Monthly 2% (compound annual 26.82%)
                            Quarterly 9% (compound annual 41.16%)
                            Semi-annual 25% (compound annual 56.25%)
                            Annual 72%</p>

                        <p className={css.description}>Penalty:
                            100% of rewards if you withdraw before time runs out.
                        </p>
                    </div>
                </CardComponent>

                <CardComponent>
                    <div className={css.faq}>
                        <h1 className={css.faqTitle}>VESTING STAKING TUTORIAL</h1>
                        <p className={css.description}>1- Copy DAPP link: <a href="https://dapp.coinofchampions.com">https://dapp.coinofchampions.com</a></p>
                        <p className={css.description}>2- Paste it into your wallet browser</p>
                        <p className={css.description}>3- Click on the 3 lines at the top left and select VESTING</p>
                        <p className={css.description}>4- Click ENABLE and pay the fee</p>
                        <p className={css.description}>5- Click on NEW DEPOSIT and choose staking amount and duration (1 to 365 days)</p>
                        <p className={css.description}>6- Click CONFIRM and pay the second fee</p>
                    </div>
                </CardComponent>
            </div>
        </div>
    );
}
