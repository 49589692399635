import React, {useState} from "react";
import ReactLoading from "react-loading";
import {useReadCollectionFactory} from "../../../customHooks/useReadCollectionFactory";
import {MarketplaceCreate} from "./marketplaceCreate";
import css from "../../nft/Nft.module.css";

interface CollectionCreateReadProps {
    counter: number;
}

export const CollectionCreateRead: React.FC<CollectionCreateReadProps> = ({counter}) => {
    const [collectionsCounter, setCollectionsCounter] = useState<number>();

    const collectionsCounterRead = useReadCollectionFactory('collectionsCounter');

    if (collectionsCounterRead && collectionsCounterRead._isBigNumber && collectionsCounter === undefined) {
        const count = Number(collectionsCounterRead.toLocaleString()) - 1;
        if (count === counter) {
            setCollectionsCounter(count);
        }
    }

    return (
        <>
            {
                collectionsCounter ?
                    <MarketplaceCreate collectionsCounter={collectionsCounter}/> :
                    <ReactLoading className={css.loading}
                                  width={21}
                                  type={'balls'}
                                  color="#E4F600"/>
            }
        </>
    );
}
