/* eslint-disable react-hooks/rules-of-hooks */
import {useEthers} from "@usedapp/core";
import {BigNumber} from "@ethersproject/bignumber";
import {useReadCOCContract} from "../customHooks/useReadCOCContract";
import StakingHelper from "./stakingHelper";
import {environment, GetEnv} from "../environments/environment";

export default class StakingRewards {
    static stakingRewards(contractIndex: number): BigNumber {
        const {account} = useEthers();

        const rewardBalance: BigNumber = StakingHelper.readStakingContract(
            "rewardBalance",
            contractIndex
        );
        const amount: BigNumber = StakingHelper.readStakingContract(
            "stakeOf", contractIndex, [
                account,
            ]);
        const stakedBalance: BigNumber = StakingHelper.readStakingContract(
            "stakedBalance",
            contractIndex
        );

        let reward: BigNumber = BigNumber.from(0);

        if (rewardBalance._isBigNumber && amount._isBigNumber && stakedBalance._isBigNumber && stakedBalance.gt(0)) {
            reward = (rewardBalance.mul(amount)).div(stakedBalance);
        }

        return reward;
    }

    static stakingReflection(contractIndex: number): BigNumber {
        const contractAddress = `${
            GetEnv()[`REACT_APP_STAKING_CONTRACT_ADDRESS_${contractIndex}` as keyof environment]
        }`;

        const balance = useReadCOCContract(
            "balanceOf",
            [contractAddress]
        );
        const stakedBalance = StakingHelper.readStakingContract(
            "stakedBalance",
            contractIndex
        );
        const totalReward = StakingHelper.readStakingContract(
            "totalReward",
            contractIndex
        );

        let reflectedFees: BigNumber = BigNumber.from(0);
        if (balance._isBigNumber && stakedBalance._isBigNumber && totalReward._isBigNumber) {
            reflectedFees = balance.sub(stakedBalance.add(totalReward));
        }

        return reflectedFees;
    }

    static stakingRewardReflected(contractIndex: number): BigNumber {
        const {account} = useEthers();

        const rewardReflected = this.stakingReflection(contractIndex);
        const stakedBalance = StakingHelper.readStakingContract(
            "stakedBalance",
            contractIndex
        );
        const amount = StakingHelper.readStakingContract(
            "stakeOf",
            contractIndex, [
                account,
            ]);

        let earnedReflection: BigNumber = BigNumber.from(0);

        if (rewardReflected._isBigNumber && amount._isBigNumber && stakedBalance._isBigNumber && stakedBalance.gt(0)) {
            earnedReflection = (rewardReflected.mul(amount)).div(stakedBalance);
        }

        return earnedReflection;
    }
}
