import React, {useState} from "react";
import ReactLoading from "react-loading";
import {useEthers} from "@usedapp/core";
import Axios from "axios";
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import {ButtonComponent} from "../../../../../components/button/ButtonComponent";
import {FarmInputComponent} from "../../../../../components/input/FarmInputComponent";
import {AuctionInputComponent} from "../../../../../components/input/AuctionInputComponent";
import {GetEnvAPI} from "../../../../../environments/environmentAPI";
import css from "../../../loadNft.module.css";
import {GetEnv} from "../../../../../environments/environment";

interface LoadNftViewProps {
    collectionAddress: string;
    mintEnd: any;
}

export const LoadNftView: React.FC<LoadNftViewProps> = ({collectionAddress, mintEnd}) => {
    const {account} = useEthers();

    const [nftName, setNFTName] = useState("");
    const [description, setDescription] = useState("");
    const [readerProfile, setReaderProfile] = useState<any>();
    const [loadConfirm, setLoadConfirm] = useState(false);
    const [type, setType] = useState('');
    const [name, setName] = useState('');
    const [image, setImage] = useState<any>();
    const [attributes, setAttributes] = useState<any>([]);

    const token = localStorage.getItem('admin_token') || '';

    const addAttributes = (type: string, name: string) => {
        attributes.push({trait_type: type, value: name});
        setType('');
        setName('');
    };

    const uploadFileProfile = async (event: any) => {
        const file = event.target.files[0];
        setReaderProfile(event.target.files[0]);
        const formData = new FormData();
        formData.append('files', file);
        if (formData) {
            const headers = {
                'Authorization': 'Bearer ' + token,
                'Content-Type': 'application/json'
            };
            await Axios.post(` ${GetEnvAPI().apiUrl}/nft/uploadResources`, formData, {
                headers
            })
                .then(res => {
                    setImage(res.data[0])
                });
        }
    }

    const confirmData = () => {
        setLoadConfirm(true);
        const headers = {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json'
        };
        const img = 'ipfs://' + image.CID
        Axios.post(` ${GetEnvAPI().apiUrl}/nft/uploadJSON`, {
            json: {
                name: nftName,
                description: description,
                attributes: attributes,
                image: img
            }
        }, {
            headers
        })
            .then(resp => {
                Axios.post(`${GetEnvAPI().apiUrl}/nft/collection/mint`, {
                    chainId: +`${GetEnv().REACT_APP_CHAIN_ID}`,
                    nftContractAddress: collectionAddress,
                    CID: resp.data.CID,
                    toAddress: account,
                    amount: 1, // 1 default e massimo 100
                    confirmations: 3
                }, {
                    headers
                })
                    .then(res => {
                        setLoadConfirm(false);
                        mintEnd(true);
                        setReaderProfile(null)
                        setAttributes([])
                        setNFTName('')
                        setDescription('')
                    });
            });
    }

    const openUpload = () => {
        document.getElementById('inputFile')?.click();
    }

    return (
        <>
            <div className={css.boxColumn}>
                {readerProfile ?
                    <div className={css.renderContainer}>
                        {readerProfile.name}
                        <div className={css.renderIconContainer} onClick={() => {
                            setReaderProfile(null)
                        }}>
                            <DeleteOutlineIcon/>
                        </div>
                    </div>
                    :
                    <div className={css.uploadContainer}>
                        <div className={css.uploadIconContainer} onClick={() => {
                            openUpload()
                        }}>
                            <label className={css.upload}>+</label>
                        </div>
                        <input id={'inputFile'} style={{width: 81, height: 81, backgroundColor: '#171819'}}
                               onChange={(e) => {
                                   uploadFileProfile(e)
                               }} hidden type="file" accept=".png, .jpg, .jpeg, .gif"/>
                    </div>
                }
                <div className={'text-start justify-content-start'}
                     style={{padding: '20px 0', fontSize: 15, fontStretch: 'expanded', fontWeight: 'bolder'}}>
                    Name
                </div>
                <FarmInputComponent
                    style={{maxWidth: 300}}
                    type="text"
                    value={nftName}
                    onChange={(e) => setNFTName(e.target.value)}
                    placeholder="Name"
                />
                <div className={'text-start justify-content-start'}
                     style={{padding: '20px 0', fontSize: 15, fontStretch: 'expanded', fontWeight: 'bolder'}}>
                    Description
                </div>
                <textarea
                    className={css.description}
                    style={{maxWidth: 300}}
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    placeholder="Description"
                />
            </div>

            <div className={css.boxColumn1}>
                <div style={{margin: '0'}}>
                    <div className={'text-start justify-content-start'} style={{
                        margin: '10px 0 0 0',
                        padding: '0',
                        fontSize: 15,
                        fontStretch: 'expanded',
                        fontWeight: 'normal'
                    }}>
                        Attributes
                    </div>

                    <div>
                        <div className={css.containerAttributes}>
                            <AuctionInputComponent value={type} onChange={(e) => {
                                setType(e.target.value)
                            }} placeholder={'Type'} className={css.inputWeek1} type={'text'}/>
                        </div>
                        <div className={css.containerAttributes}>
                            <AuctionInputComponent value={name} onChange={(e) => {
                                setName(e.target.value)
                            }} placeholder={'Name'} className={css.inputWeek1} type={'text'}/>
                        </div>
                        <div style={{margin: '10px 0',}}>
                            <div className={css.containerButton}>
                                <ButtonComponent disabled={type === '' || name === ''} style={{width: 30}}
                                                 onClick={() => {
                                                     addAttributes(type, name)
                                                 }}>Add</ButtonComponent>
                                <ButtonComponent style={{width: 30}} disabled={!attributes.length}
                                                 onClick={() => {
                                                     setAttributes([])
                                                 }}>Clear</ButtonComponent>
                            </div>
                        </div>
                    </div>

                </div>
                {attributes &&
                    attributes.map((a: any, index: number) => {
                        return <div key={index}>
                            <label style={{color: "white", fontSize: 15, margin: '0 5px'}}>
                                type: <label style={{
                                color: "gray",
                                fontSize: 15,
                                margin: '0 5px'
                            }}>{a.trait_type}</label>
                            </label>
                            <label style={{color: "white", fontSize: 15, margin: '0 5px'}}>
                                name: <label
                                style={{color: "gray", fontSize: 15, margin: '0 5px'}}>
                                {a.value}
                            </label>
                            </label>
                        </div>
                    })
                }
                {loadConfirm ?
                    <div className={css.containerButton}>
                        <ReactLoading width={30} type={'spinningBubbles'} color="#E4F600"/>
                    </div>
                    :
                    <div style={{marginTop: '70px'}}>
                        <ButtonComponent
                            disabled={readerProfile === null || (attributes && attributes.length === 0) || description === '' || nftName === ''}
                            style={{width: '100%'}} onClick={confirmData}>Confirm</ButtonComponent>
                    </div>
                }
            </div>
        </>
    );

}
