import styled from 'styled-components'
import { Card } from "@material-ui/core";

export const CardComponent = styled(Card)`
    min-height: 170px;
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7) !important;
    background-color: #171819;
    backdrop-filter: blur(40px);
    border-radius: 20px !important;
`;
