import styled from 'styled-components'
import {Table} from "@mui/material";

export const TableComponent = styled(Table)`
    font-family: 'Montserrat' !important;
    min-height: 190px;
    backdrop-filter: blur(40px);
    background-color: rgba(23, 24, 25, 0.7)!important;
    border-radius: 17px!important;
`;
