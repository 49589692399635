import styled from 'styled-components'
import { Button } from "@material-ui/core";

export const ButtonComponentDisabled = styled(Button)`
    font-family: 'Montserrat' !important;
    border-radius: 25px !important;
    padding: 3px 20px !important;
    margin: 0 !important;
    text-transform: none !important;
    background-color: #2E2E2F;
    color: black;
    font-size: 15px;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))!important;
    
`;
