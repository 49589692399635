import React, {useState} from "react";
import ReactLoading from "react-loading";
import {useEthers} from "@usedapp/core";
import {BigNumber} from "@ethersproject/bignumber";
import {Snackbar} from "@mui/material";
import {ButtonComponent} from "../../../components/button/ButtonComponent";
import {FarmInputComponent} from "../../../components/input/FarmInputComponent";
import {useWriteMarketplaceFactory} from "../../../customHooks/useWriteMarketplaceFactory";
import {useReadCollectionFactory} from "../../../customHooks/useReadCollectionFactory";
import css from '../collectionCreate.module.css';

interface MarketplaceCreateProps {
    collectionsCounter: number;
}

export const MarketplaceCreate: React.FC<MarketplaceCreateProps> = ({collectionsCounter}) => {
    const {account} = useEthers();
    const [minBid, setMinBid] = useState("");
    const [open, setOpen] = useState(true);
    const [collections, setCollections] = useState<string>();

    const collectionsRead = useReadCollectionFactory('collections', [collectionsCounter])

    if (collectionsRead && typeof collectionsRead === "string" && collections === undefined) {
        setCollections(collectionsRead)
    }

    const {send, state} = useWriteMarketplaceFactory("deployMarketplace");

    const bigInt18 = 1000000000000000000;

    const confirm = () => {
        let amountToSend = (+minBid * bigInt18).toLocaleString('fullwide', {useGrouping: false});
        send(account, collections, account, BigNumber.from(amountToSend.toString()), true, true);
    }

    if (state.status === 'Exception') {
        setInterval(() => {
            window.location.reload()
        }, 2000);
    }

    if (state.status === 'Success') {
        setInterval(() => {
            window.location.href = '/profile';
        }, 2000);
    }

    return (
        <>
            {!!collections ?
                <div>
                    <div className={css.label}>Min bid increase</div>
                    <FarmInputComponent
                        type="text"
                        value={minBid}
                        onChange={(e) => setMinBid(e.target.value)}
                        placeholder="Min bid increase"
                    />
                    <div className={css.confirmBtn}>
                        <ButtonComponent onClick={confirm}>Create</ButtonComponent>
                    </div>
                </div> :
                <ReactLoading className={css.confirmBtn}
                              width={21}
                              type={'balls'}
                              color="#E4F600"/>
            }

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </>
    );
}
