import {styled} from "@mui/system";

export const FarmInputComponent = styled('input')`
  width: 100% !important;
  line-height: 2.5em !important;
  padding: 2px 10px !important;
  background: rgba(66, 66, 66, 0.53)!important;
  border-radius: 8px !important;
  font-size: 13px !important;
  transition: width 300ms ease;
  color: #e5e8ec!important;
  border: transparent;
  
  :focus-visible {
    outline: none;
  }
`;

export const FarmInputComponentSm = styled('input')`
  width: 100% !important;
  line-height: 2.5em !important;
  padding: 2px 10px !important;
  background: rgba(66, 66, 66, 0.53)!important;
  color: #e5e8ec!important;
  border-radius: 8px !important;
  font-size: 13px !important;
  transition: width 300ms ease;
  border: transparent;
  
  :focus-visible {
    outline: none;
  }
`;
