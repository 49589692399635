import React from "react";
import {useReadMarketplaceFactoryContract} from "../../customHooks/useReadMarketplaceFactoryContract";
import {GetMarketplace} from "./components/getMarketplace";
import {CheckNetwork} from "../../components/network/checkNetwork";
import css from "../nft/Nft.module.css";

export const ChooseCollection: React.FC = () => {
    const marketplaceCounter = useReadMarketplaceFactoryContract('marketplacesCounter');

    let numbers = [];
    if (marketplaceCounter && marketplaceCounter > 0) {
        for (let i = 0; i < marketplaceCounter; i++) {
            if (i !== 1 && i !== 2 && i !== 5 && i !== 6) {
                numbers.push(i);
            }
        }
    }

    return (
        <div>
            <CheckNetwork/>
            <div className={css.container}>
                <h2 style={{color: '#FFFFFF'}}>Collections </h2>

                {
                    !!numbers.length &&
                    <div style={{display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {
                            numbers.map((id, index) => (
                                <GetMarketplace id={id} key={index}/>))
                        }
                    </div>
                }
            </div>
        </div>
    )
}
