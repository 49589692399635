import ManageStakingData from "../../../../helpers/manageStakingData";
import {StakeItem} from "./stakeItem/stakeItem";
import {WithdrawItem} from "./withdrawItem/withdrawItem";

interface StakeProps {
    view: string;
}

export const StakeContent: React.FC<StakeProps> = ({view}) => {
    if (view === 'live') {
        return (
            <div style={{padding: '0', width: '100%', maxWidth: 1300}}>
                {ManageStakingData.liveData.map(element =>
                    <StakeItem key={element.id} stakeContract={element}/>
                )}
            </div>
        );
    } else if (view === 'ended') {
        return (
            <div style={{padding: '0', width: '100%', maxWidth: 1300}}>
                {ManageStakingData.endedData.map(element =>
                    <div>
                        <WithdrawItem key={element.id} stakeContract={element}/>
                    </div>
                )}
            </div>

        );
    } else {
        return (
            <div style={{padding: '0', width: '100%', maxWidth: 1300}}>
                {ManageStakingData.allData.map(element => {
                        return element.isEnded ?
                            <WithdrawItem key={element.id} stakeContract={element}/> :
                            <StakeItem key={element.id} stakeContract={element}/>
                    }
                )}
            </div>
        );
    }
};
