/* eslint-disable react-hooks/rules-of-hooks */
import { useEthers } from "@usedapp/core";
import FarmingHelper from "./farmingHelper";
import {GetEnv} from "../environments/environment";

export function farmingRewards(): string {
    const { account } = useEthers();

    let cocPoolId = 0;
    let poolInfo = FarmingHelper.readFarmingContract(`${GetEnv()[`REACT_APP_FARMING_CONTRACT_ADDRESS_1`]}`,"poolInfo", [cocPoolId]);
    let userInfo = FarmingHelper.readFarmingContract(`${GetEnv()[`REACT_APP_FARMING_CONTRACT_ADDRESS_1`]}`,"userInfo", [cocPoolId, account]);
    let accShare = poolInfo["accERC20PerShare"];
    let divider = "1000000000000000000000000000000000000"; //1e36
    let lpUserAmount = userInfo["amount"];
    let userDebt = userInfo["rewardDebt"];
    let rewards = (+lpUserAmount) * (+accShare) / (+divider) - (+userDebt);

    return rewards.toString();
}
