import {GetEnv} from "../environments/environment";
import {Interface} from "@ethersproject/abi";
import {useContractCall} from "@usedapp/core";
import abi from "../assets/abi/testnet/collectionFactory.abi.json";

export function useReadCollectionFactory(method: string, args?: any[]) {
    if (args === undefined) {
        args = [];
    }
    const CONTRACT_ADDRESS = `${GetEnv()[`REACT_APP_NFT_COLLECTION_FACTORY_ADDRESS`]}`;
    const abiInterface = new Interface(abi);


    const contractResult = useContractCall({
        abi: abiInterface,
        address: CONTRACT_ADDRESS,
        method,
        args
    });

    return contractResult === undefined ? [] : contractResult[0];
}
