export interface environment {
    REACT_APP_COC_CONTRACT_ADDRESS: string;
    REACT_APP_WBNB_CONTRACT_ADDRESS?: string;
    REACT_APP_BUSD_CONTRACT_ADDRESS?: string;
    REACT_APP_CAKE_CONTRACT_ADDRESS?: string;
    REACT_APP_CROWDSALE_CONTRACT_ADDRESS?: string;
    REACT_APP_STAKING_CONTRACT_ADDRESS_1?: string;
    REACT_APP_STAKING_CONTRACT_ADDRESS_2?: string;
    REACT_APP_STAKING_CONTRACT_ADDRESS_3?: string;
    REACT_APP_STAKING_CONTRACT_ADDRESS_4?: string;
    REACT_APP_STAKING_CONTRACT_ADDRESS_5?: string;
    REACT_APP_VESTING_CONTRACT_ADDRESS_1?: string;
    REACT_APP_FARMING_CONTRACT_ADDRESS_1?: string;
    REACT_APP_FARMING_CONTRACT_ADDRESS_2?: string;
    REACT_APP_LP_TOKEN_CONTRACT_ADDRESS?: string;
    REACT_APP_CHAIN_ID: string;
    REACT_APP_RPC_URL: string;
    REACT_APP_WEB_SOCKET_URL?: string;
    REACT_APP_NET_TYPE: string;
    REACT_APP_LP_COC_BNB_ADDRESS?: string;
    REACT_APP_LP_COC_BUSD_ADDRESS?: string;
    REACT_APP_LP_COC_CAKE_ADDRESS?: string;
    REACT_APP_NFT_MARKETPLACE_FACTORY_ADDRESS?: string;
    REACT_APP_NFT_COLLECTION_FACTORY_ADDRESS?: string;
}

export const prodBSCEnvironment: environment = {
    "REACT_APP_COC_CONTRACT_ADDRESS": "0xbDC3b3639f7AA19e623A4d603A3Fb7Ab20115A91",
    "REACT_APP_WBNB_CONTRACT_ADDRESS": "0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c",
    "REACT_APP_BUSD_CONTRACT_ADDRESS": "0xe9e7cea3dedca5984780bafc599bd69add087d56",
    "REACT_APP_CAKE_CONTRACT_ADDRESS": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82",
    "REACT_APP_CROWDSALE_CONTRACT_ADDRESS": "0x0dde2544b7CF1294DfA615bD3430544399f56360",
    "REACT_APP_STAKING_CONTRACT_ADDRESS_1": "0xC484489dF90d773D2Fb9a0BcA1c183e4a5F17df1",
    "REACT_APP_STAKING_CONTRACT_ADDRESS_2": "0x5325122C539F8E6273c81BAd67ECe9EA4c062774",
    "REACT_APP_STAKING_CONTRACT_ADDRESS_3": "0x8E4C7D868FBC1800160C21acD3D82BB5Dabf91Db",
    "REACT_APP_STAKING_CONTRACT_ADDRESS_4": "0xb88a7154e13f9e4F69a4013a7152cbE720130aAD",
    "REACT_APP_STAKING_CONTRACT_ADDRESS_5": "0xC0DeC8ac8F3C0562c62EE6B66DC350003217aAf5",
    "REACT_APP_VESTING_CONTRACT_ADDRESS_1": "0xcbA0D0703885c62FB28aD20C6503686838578d50",
    "REACT_APP_FARMING_CONTRACT_ADDRESS_1": "0x0406bF6C5D76F2038D03c49818214dE325f81F5C",
    "REACT_APP_FARMING_CONTRACT_ADDRESS_2": "0x7Fc31fa8a88Fe7a811d4d1FF538B177fF00d796f",
    "REACT_APP_LP_TOKEN_CONTRACT_ADDRESS": "0xc241891dd5b73c2dbe00a2f3cbe8fd047c874f10",
    "REACT_APP_CHAIN_ID": "56",
    "REACT_APP_RPC_URL": "https://bsc-dataseed.binance.org/",
    "REACT_APP_WEB_SOCKET_URL": "wss://bsc-ws-node.nariox.org/",
    "REACT_APP_NET_TYPE": "MAINNET",
    "REACT_APP_LP_COC_BNB_ADDRESS": "0xC241891dd5B73c2dbe00A2f3CBe8FD047C874f10",
    "REACT_APP_LP_COC_BUSD_ADDRESS": "0x89CA0d7fB209fca92f7779768bf225998B00448f",
    "REACT_APP_LP_COC_CAKE_ADDRESS": "0xeF87F84cb04E9eE2eA5Cd3989ab62af4dFCa5E22",
    "REACT_APP_NFT_MARKETPLACE_FACTORY_ADDRESS": "0xF6A34BEe1b14D7721c26F033af88015ddb0cB111",
    "REACT_APP_NFT_COLLECTION_FACTORY_ADDRESS": "0xba719d5C61B210C2E259786f489a9141D75Ef728",
}

export const prodETHEnvironment: environment = {
    "REACT_APP_COC_CONTRACT_ADDRESS": "0xc4bb7277a74678f053259cb1f96140347efbfd46",
    "REACT_APP_CHAIN_ID": "1",
    "REACT_APP_RPC_URL": "https://mainnet.infura.io/v3",
    "REACT_APP_NET_TYPE": "MAINNET",
}

export const devBSCEnvironment: environment = {
    "REACT_APP_COC_CONTRACT_ADDRESS": "0x1b9dc5836cb4d9be8ec35cfe88effb1073fd3dd8",
    "REACT_APP_CHAIN_ID": "97",
    "REACT_APP_RPC_URL": "https://data-seed-prebsc-1-s1.binance.org:8545/",
    "REACT_APP_WEB_SOCKET_URL": "wss://speedy-nodes-nyc.moralis.io/c61f401caf47e4f66dbbff83/bsc/testnet/ws",
    "REACT_APP_NET_TYPE": "TESTNET",
    "REACT_APP_NFT_MARKETPLACE_FACTORY_ADDRESS": "0xfbA1CDd827351A377B8aB1694DC11EC9ec3a95F9",
    "REACT_APP_NFT_COLLECTION_FACTORY_ADDRESS": "0x1C2e68095e743e5f173dCcE09aD94FAC05ed28A9",
}

export const GetEnv = () => {
    const chainId = localStorage.getItem('chainId');

    if (chainId === "1") {
        return prodETHEnvironment
    } else if (chainId === "97") {
        return devBSCEnvironment
    } else {
        return prodBSCEnvironment
    }
}
