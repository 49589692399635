import React, {useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {Card, CardContent} from "@mui/material";
import {useReadGenericErc721} from "../../../customHooks/useReadGenericErc721";
import {TokenUriModel} from "../../nft/components/nftBuilder/collectionView/collectionView";
import css from "../../nft/Nft.module.css";

interface GetCollectionInfoNftProps {
    collection: string;
    marketplace: string;
    name: string;
}

export const GetCollectionInfoNft: React.FC<GetCollectionInfoNftProps> = ({collection, marketplace, name}) => {
    const [info, setInfo] = useState<any>();

    const tokenURI = useReadGenericErc721(collection, 'tokenURI', [0]);
    const tokenReplace = !!tokenURI.length ? String(tokenURI).replace("ipfs://", "https://ipfs.io/ipfs/") : undefined;

    if (tokenReplace && info === undefined) {
        axios.get(tokenReplace).then(res => {
            const json: TokenUriModel = res.data
            let frm;
            if (json.image.includes("ipfs://")) {
                frm = String(json.image).replace("ipfs://", "https://ipfs.io/ipfs/")
            } else {
                frm = "https://ipfs.io/ipfs/" + String(json.image)
            }
            setInfo({...json, formattedImage: frm})
        })
    }

    return (
        <>
            <div className={css.boxCard}>
                <Card sx={{
                    width: '240px',
                    margin: '20px',
                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                    borderRadius: '25px'
                }}>
                    <Link className={css.linkStyle}
                          to={{
                              pathname: window.location.pathname === '/profile' ? `/profile/${marketplace}/${collection}` : `/nft/${marketplace}/${collection}`
                          }}>
                        {
                            info && <div style={{
                                textAlign: 'center',
                                height: '120px',
                                width: '240px',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat',
                                backgroundSize: 'cover',
                                backgroundImage: `url(${info.formattedImage})`
                            }}/>}
                    </Link>
                    <CardContent style={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <div style={{marginBottom: '30px'}}>
                            <h1 style={{color: 'white', fontSize: '20px', lineHeight: '35px'}}>{name}</h1>
                        </div>

                    </CardContent>
                </Card>
            </div>

        </>
    )
}
