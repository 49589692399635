import {styled} from "@mui/system";

export const AuctionInputComponent = styled('input')`
  width: 100%;
  min-width:250px;
  line-height: 1.4375em !important;
  padding: 7px 10px !important;
  background: rgb(50, 50, 50) !important;
  font-size: 13px !important;
  color: #e5e8ec !important;
  border-radius: 5px !important;
  transition: width 300ms ease;
  border: transparent;
  margin: 10px 0;
  
  :focus-visible {
    outline: none;
  }
`;
export const AuctionInputComponentSelect = styled('input')`
  width:100%;
  line-height: 1.4375em !important;
  padding: 7px 10px !important;
  background: rgb(50, 50, 50) !important;
  font-size: 13px !important;
  color: #e5e8ec !important;
  border-radius: 5px 0 0 5px !important;
  transition: width 300ms ease;
  border: transparent;
  margin: 10px 0;
  
  :focus-visible {
    outline: none;
  }
`;

export const AuctionInputComponentSm = styled('input')`
  line-height: 1.4375em !important;
  padding: 7px 10px !important;
  background: rgb(50, 50, 50) !important;
  font-size: 13px !important;
  color: #e5e8ec !important;
  border-color: #424242;
  border-radius: 5px !important;
  transition: width 300ms ease;
  border: transparent;
  
  :focus-visible {
    outline: none;
  }
`;
