import React from "react";
import {formatUnits} from "ethers/lib/utils";
import moment from "moment";
import {
    TableCell,
    TableRow
} from "@mui/material";
import css from "../../../../Nft.module.css";

export interface InfoTable {
    bid: any;
    sellOrders: any;
}

export const TableBidsDetails: React.FC<InfoTable> = ({bid, sellOrders}) => {
    const data = new Date(bid.timestamp.toString() * 1000);

    return (
        <TableRow className={css.tableRawBody}>
            <TableCell className={css.tableCell}
                       align="left">{bid.bidder.slice(0, 4) + '...' + bid.bidder.slice(-3)}</TableCell>
            <TableCell className={css.tableCell} align="left">{moment(data).format('DD/MM/YYYY')}</TableCell>
            <TableCell className={css.tableCell} align="left">{moment(data).format('HH:mm')}</TableCell>

            <TableCell className={css.tableCell}
                       align="left">
                {formatUnits(bid.price, 18)} {sellOrders.paymentToken === '0x0000000000000000000000000000000000000000' ? ' BNB' : ' COC'}
            </TableCell>
        </TableRow>
    );

}
