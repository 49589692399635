export const environmentAPI = {
    production: false,
    apiUrl: 'https://nft-creator-engine-staging.herokuapp.com'
};

export const environmentAPIProd = {
    production: true,
    apiUrl: 'https://nft-creator-engine.herokuapp.com'
};

export const GetEnvAPI = () => {
    const chainId = localStorage.getItem('chainId');

    if (chainId === "97") {
        return environmentAPI
    } else {
        return environmentAPIProd
    }
}
