/* eslint-disable react-hooks/rules-of-hooks */
import {useContractCall, useContractFunction} from "@usedapp/core";
import {Contract} from "@ethersproject/contracts";
import {utils} from "ethers";
import {Lock} from "./manageVestingData";
import {environment, GetEnv} from "../environments/environment";

export default class VestingHelper {
    static get vestContractLength(): number {
        const envObject: Object = GetEnv();

        let vestIndex: number = 0;

        const listKeys = Object.keys(envObject).filter((currentKey) =>
            currentKey.match("REACT_APP_VESTING_CONTRACT_ADDRESS_")
        );

        listKeys.forEach((key) => {
            vestIndex = Number(key.split("REACT_APP_VESTING_CONTRACT_ADDRESS_")[1]);
        });

        return vestIndex;
    }

    static readVestingContract(
        methodName: string,
        contractIndex: number,
        args?: any[]
    ) {
        if (args === undefined) {
            args = [];
        }

        const contractResult = useContractCall({
            abi: new utils.Interface(
                require(`../assets/abi/testnet/vesting.abi.json`)
            ),
            address: `${GetEnv()[`REACT_APP_VESTING_CONTRACT_ADDRESS_${contractIndex}` as keyof environment]
            }`,
            method: methodName,
            args: args,
        });

        if (methodName === 'locks') {
            return contractResult === undefined ? null : contractResult;
        }

        return contractResult === undefined ? [] : contractResult[0];
    }

    static writeVestingContract(
        methodName: string,
        contractIndex: number
    ) {
        const contractAbi = new utils.Interface(
            require(`../assets/abi/testnet/vesting.abi.json`)
        );

        const contractAddress = `${GetEnv()[`REACT_APP_VESTING_CONTRACT_ADDRESS_${contractIndex}` as keyof environment]
        }`;

        const contract = new Contract(contractAddress, contractAbi);

        return useContractFunction(contract, methodName);
    }

    static calculateAPR(
        reward: string,
        amount: string,
        days: number
    ) {
        return +reward * days * 100;
    }

    static totalRewards(locksIds: number[], id: number): Lock[] {
        let data: Lock[] = [];

        for (let i = 0; i < locksIds.length; i++) {
            data.push(
                VestingHelper.readVestingContract('locks', id, [locksIds[i]])
            )
        }

        return data;
    }
}
