import React, {useState} from "react";
import ReactLoading from "react-loading";
import {Snackbar} from "@mui/material";
import {Lock, VestData} from "../../../../helpers/manageVestingData";
import VestingHelper from "../../../../helpers/vestingHelper";
import {ButtonComponent} from "../../../../components/button/ButtonComponent";
import css from "./vestingTable.module.css";

interface EmergencyWithdrawProps {
    lock: Lock;
    vestContract: VestData;
    lockId: number;
}

export const EmergencyWithdraw: React.FC<EmergencyWithdrawProps> = ({lock, vestContract, lockId}) => {
    const [open, setOpen] = useState(true);

    const {state, send} = VestingHelper.writeVestingContract("emergencyWithdraw", vestContract.id);

    const emergencyWithdraw = async () => {
        await send(lockId)
    }

    return (
        <div>
            <div className={css.cardEmergency}>
                <span>EMERGENCY WITHDRAW</span>
                <span className={css.cardEmergencyComment}>With this withdraw you will lose all rewards</span>
                <div className={css.profit}>
                    {
                        state.status === 'Mining' ?
                            <ReactLoading className={css.loading}
                                          width={21}
                                          type={'spinningBubbles'}
                                          color="#E4F600"/>
                            :
                            <ButtonComponent
                                onClick={emergencyWithdraw}>Withdraw</ButtonComponent>
                    }
                </div>
            </div>

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={2000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </div>
    )
}