import React from "react";
import {Box, Modal} from "@material-ui/core";
import {AddNetwork} from "./addNetwork";
import {BSC_network, ETH_network} from "./utils/networkList";
import {CHAIN_NAMES, ChainId} from "@usedapp/core";

const style = {
    position: 'absolute' as 'absolute',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    color: '#e5e8ec',
    padding: '20px',
    boxShadow: '2px 2px 1px -1px #E4F600 !important',
    background: 'linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%)',
    backdropFilter: 'blur(40px)',
    borderRadius: '20px !important'
};

interface SwitchNetworkProps {
    chainId?: ChainId;
    updateModal: any;
}

export interface Chain {
    chain: string;
    chainId: number;
    explorers: any[];
    faucets: string[];
    infoURL: string;
    name: string;
    nativeCurrency: any;
    network: string;
    networkId: number;
    rpc: string[];
    shortName: string;
}

const SUPPORTED_NETWORKS = [
    ETH_network,
    BSC_network
]

export const SwitchNetwork: React.FC<SwitchNetworkProps> = ({chainId, updateModal}) => {
    const [open, setOpen] = React.useState(true);
    const handleClose = () => {
        setOpen(false);
        updateModal(false);
    };

    const openModal = (data: boolean) => {
        setOpen(data)
    }

    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <Box sx={style}>
                    <h1 style={{fontSize: '1.5em'}}>Select a Network</h1>

                    {chainId && <p>You are currently browsing COC on the {CHAIN_NAMES[chainId]} network</p>}

                    {window.ethereum && <AddNetwork supportedNetworks={SUPPORTED_NETWORKS} openModal={openModal}/>}
                </Box>
            </Modal>
        </div>
    );
}