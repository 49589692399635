import { styled } from '@mui/system';

export const WithdrawInputComponent = styled('input')`
  width: 100% !important;
  line-height: 1.4375em !important;
  padding: 7px 10px !important;
  background: rgb(50, 50, 50) !important;
  font-size: 13px !important;
  color: #e5e8ec !important;
  border-radius: 10px !important;
  transition: width 300ms ease;
  border: transparent;
  
  :focus-visible {
    outline: none;
  }
`;
export const WithdrawInputComponentSm = styled('input')`
  line-height: 1.4375em !important;
  padding: 7px 10px !important;
  background: rgb(50, 50, 50) !important;
  font-size: 13px !important;
  color: #e5e8ec !important;
  border-color: #424242;
  border-radius: 10px !important;
  transition: width 300ms ease;
  border: transparent;
  
  :focus-visible {
    outline: none;
  }
`;
export const WithdrawInputComponentFarmSm = styled('input')`
  width: 100% !important;
  line-height: 2.5em !important;
  padding: 0 10px !important;
  margin:0 auto !important;
  background: rgba(66, 66, 66, 0.53)!important;
  font-size: 13px !important;
  color: #e5e8ec !important;
  border-radius: 9px !important;
  transition: width 300ms ease;
  border: transparent;
  
  :focus-visible {
    outline: none;
  }
`;
export const WithdrawInputComponentFarm = styled('input')`
  width: 100% !important;
  line-height: 2.5em !important;
  padding: 0 10px !important;
  background: rgba(66, 66, 66, 0.53)!important;
  font-size: 13px !important;
  color: #e5e8ec !important;
  border-radius: 9px !important;
  transition: width 300ms ease;
  border: transparent;
  
  :focus-visible {
    outline: none;
  }
`;
