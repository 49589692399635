import {useState} from "react";
import {Box, ToggleButton, ToggleButtonGroup} from "@mui/material";
import {StakeContent as StakeBuilder} from "./components/stakeBuilder/stakeBuilder";
import {CheckNetwork} from "../../components/network/checkNetwork";
import css from './Stake.module.css';

export const Stake: React.FC = () => {
    const [view, setView] = useState<string>('all');

    const handleView = (
        event: React.MouseEvent<HTMLElement>,
        newView: string,
    ) => {
        setView(newView);
    };

    return (
        <div>
            <CheckNetwork/>
            <div className={css.container} >
                <Box sx={{display: {xs: 'block', md: 'none'}}} className={css.toggle}>
                    <ToggleButtonGroup className={css.toggleGroupXs}
                                       value={view}
                                       exclusive
                                       onChange={handleView}
                    >
                        <ToggleButton value="all" className={css.toggleButtonXs}>All</ToggleButton>
                        <ToggleButton value="live" className={css.toggleButtonXs}>Live</ToggleButton>
                        <ToggleButton value="ended" className={css.toggleButtonXs}>Ended</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <Box sx={{display: {xs: 'none', md: 'block'}}} className={css.toggle}>
                    <ToggleButtonGroup className={css.toggleGroup}
                                       value={view}
                                       exclusive
                                       onChange={handleView}
                    >
                        <ToggleButton value="all" className={css.toggleButton}>All</ToggleButton>
                        <ToggleButton value="live" className={css.toggleButton}>Live</ToggleButton>
                        <ToggleButton value="ended" className={css.toggleButton}>Ended</ToggleButton>
                    </ToggleButtonGroup>
                </Box>
                <StakeBuilder view={view}/>
            </div>
        </div>
    );
}

