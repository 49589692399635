import { useContractFunction } from "@usedapp/core";
import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";

export function useWriteGenericErc721(contractAddress: string, methodName: string) {
    const contractAbi = new utils.Interface(
        require(`../assets/abi/testnet/GenericERC721.json`)
    );

    const contract = new Contract(contractAddress, contractAbi);

    return useContractFunction(contract, methodName);
}
