import React from "react";
import {Button} from "@mui/material";
import styled from "styled-components";

declare let window: any

interface AddNetworkProps {
    supportedNetworks: any[];
    openModal?: any;
}

export const AddNetwork: React.FC<AddNetworkProps> = ({supportedNetworks, openModal}) => {

    const switchNetwork = async (network: any) => {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{chainId: network.chainId}],
            });
            openModal(false);
        } catch (switchError: any) {
            if (switchError.code === 4902) {
            delete network.icon
                try {
                    await window.ethereum.request({
                        method: 'wallet_addEthereumChain',
                        params: [network],
                    });
                    openModal(false);
                } catch (addError) {
                    console.error(addError)
                }
            } else {
                console.error(switchError)
            }
        }
    }

    return (
        <div>
            {supportedNetworks.map(network => (
                <NetworkButton
                    key={network.chainId}
                    onClick={() => switchNetwork(network)}
                >
                    <img src={network.icon} width="45px" style={{borderRadius: '20px', padding: '5px'}} alt=""/>
                    {network.chainName}
                </NetworkButton>
            ))}
        </div>
    )
}

const NetworkButton = styled(Button)`
    font-family: 'Montserrat' !important;
    border-radius: 25px !important;
    padding: 0 20px !important;
    font-size: 12px !important;
    background-color: rgba(255, 255, 255, 0.4) !important;
    margin: 5px !important;
    text-transform: none !important;
    color: #171819 !important;
    cursor: pointer;
`;