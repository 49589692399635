import React, { useState } from "react";
import { CardContent } from "@material-ui/core";
import { Col, Row } from "react-bootstrap";
import { formatUnits } from "ethers/lib/utils";
import { BigNumber } from "@ethersproject/bignumber";
import { EndedCardComponent } from "../../../../../components/cards/cardComponent/EndedCardComponent";
import { WithdrawTextInput } from "../withdrawTextInput/withdrawTextInput";
import { StakeData } from "../../../../../helpers/manageStakingData";
import css from './withdrawItem.module.css'
import buttonArrow from "../../../../../assets/icons/button.svg";
import buttonArrowHover from "../../../../../assets/icons/button_hover.svg";

interface WithdrawItemProps {
    stakeContract: StakeData;
}

export const WithdrawItem: React.FC<WithdrawItemProps> = ({ stakeContract }) => {
    const [ended, setEnded] = useState(false);
    const [view, setView] = useState(false);
    const [timeLeft, setTimeLeft] = useState<number>();
    const now = + new Date() / 1000;

    // TODO: fix
    const rewardReflectionEnded = BigNumber.from(0)

    if (+stakeContract.statWithdraw > 0 && timeLeft === undefined) {
        setTimeLeft(+stakeContract.statWithdraw - now / 1000)
        if ((+stakeContract.statWithdraw - now) < 0) {
            setEnded(true)
        }
    }

    return (
        <Row style={{ margin: ' 10px 0', padding: 0, justifyContent: 'center' }}>
            <Col style={{ margin: '0 auto  20px 0', padding: 0 }} xs={12} sm={12} md={12} lg={8} xl={8}>
                <EndedCardComponent >
                    <CardContent >
                        <div className={css.name}>
                            {stakeContract.name}
                        </div>
                        <Row style={{ margin: 0, padding: 0 }}>
                            <Col className={'d-none d-sm-block'} xs={12} sm={12} md={12} lg={12} xl={12} >
                                <WithdrawTextInput stakeContract={stakeContract} />
                                { !view ?
                                    <div className={'text-center'}>
                                        <img onClick={()=>{setView(true)}} src={buttonArrow} alt={''} style={{width:80,margin:'0 auto', cursor:'pointer'}}/>
                                    </div>
                                    :
                                    <Row className={css.box}>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ margin: '10px 0',}}>
                                            <Row style={{ margin: 0, padding: 0 }} className={css.myStake}>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span style={{fontWeight:'bold'}}>My stake</span>
                                                </Col>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                    {formatUnits(stakeContract.myStake, 18)} COC
                                                </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ margin: '10px 0', }}>
                                            <Row style={{ margin: 0, padding: 0 }} className={css.myStake}>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span style={{fontWeight:'bold'}}>Pending reward</span>
                                                </Col>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                    {formatUnits(stakeContract.rewards, 18)} COC
                                                </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ margin: '10px 0', }}>
                                            <Row style={{ margin: 0, padding: 0 }} className={css.myStake}>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span style={{fontWeight:'bold'}}>Reflection</span>
                                                </Col>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                          {
                                                              ended ?
                                                                  formatUnits(rewardReflectionEnded, 18) :
                                                                  formatUnits(stakeContract.rewardReflection, 18)
                                                          } COC
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ margin: '10px 0',}}>
                                            <Row style={{ margin: 0, padding: 0 }} className={css.myStake}>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span style={{fontWeight:'bold'}}>APY</span>
                                                </Col>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                {stakeContract.APY}%
                                                </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <img onClick={()=>{setView(false)}} src={buttonArrowHover} alt={''} style={{width:100,margin:'0 auto',transform:'rotate(180deg)', cursor:'pointer'}}/>
                                    </Row>
                                }
                            </Col>
                            <Col className={'d-block d-sm-none'} xs={12} sm={10} md={10} lg={10} xl={10} style={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                <WithdrawTextInput stakeContract={stakeContract} />
                                { !view ?
                                    <img onClick={()=>{setView(true)}} src={buttonArrow} alt={''} style={{width:80,margin:'0 auto', cursor:'pointer'}}/>
                                    :
                                    <Row style={{ margin: 0, padding: 0 }} >
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ margin: '10px auto', padding: 0 }}>
                                            <Row style={{ margin: 0, padding: 0 }} className={css.myStake}>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span style={{fontWeight:'bold'}}>My stake</span>
                                                </Col>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                 {formatUnits(stakeContract.myStake, 18)} COC
                                                </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ margin: '10px auto', padding: 0 }}>
                                            <Row style={{ margin: 0, padding: 0 }} className={css.myStake}>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span style={{fontWeight:'bold'}}>Pending reward</span>
                                                </Col>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                {formatUnits(stakeContract.rewards, 18)} COC
                                                </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ margin: '10px auto', padding: 0 }}>
                                            <Row style={{ margin: 0, padding: 0 }} className={css.myStake}>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span style={{fontWeight:'bold'}}>Reflection</span>
                                                </Col>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                        {
                                                            ended ?
                                                                formatUnits(rewardReflectionEnded, 18) :
                                                                formatUnits(stakeContract.rewardReflection, 18)
                                                        } COC
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={12} lg={6} xl={6} style={{ margin: '10px auto', padding: 0 }} >
                                            <Row style={{ margin: 0, padding: 0 }} className={css.myStake}>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span style={{fontWeight:'bold'}}>APY</span>
                                                </Col>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                  {stakeContract.APY}%
                                                </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <img onClick={()=>{setView(false)}} src={buttonArrowHover} alt={''} style={{width:100,margin:'0 auto',transform:'rotate(180deg)', cursor:'pointer'}}/>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </CardContent>
                </EndedCardComponent>
            </Col>
        </Row>
    );
}
