import React, {useState} from "react";
import {HomeCardContent} from "../../components/cards/homeCardContent/HomeCardContent";
import {useContractDetail} from "../../customHooks/useContractDetail";
import {readTotalBurn} from "../../helpers/readTotalBurn";
import {Char} from "../../components/char/char";
import css from './Home.module.css';

export const Home: React.FC = () => {
    const [totalBurn, setTotalBurn] = useState('0')

    const cd = useContractDetail();
    let volume = "0";
    if (cd) {
        const totalVolume: Object = cd.market_data.total_volume;
        volume = Object.values(totalVolume).reduce((a, b) => a + b, 0).toString();
    }

    readTotalBurn().then((r: any) => {
        setTotalBurn(r.toLocaleString())
    });

    return (
        <div className={css.container}>
            <div className={css.containerCards}>

                <div className={css.containerCardTextBottom}>
                    <HomeCardContent title="Number of tokens burned" amount={totalBurn} decimals={18} coc={true}/>
                </div>

                <div className={css.containerCardTextTop}>
                    <HomeCardContent title="Pancake exchange volume" amount={volume} coc={true}/>
                </div>
            </div>

            <div className={css.connectContainer}>
            </div>

            <div style={{width: '100%', maxWidth: 1300}}>
                <Char/>
            </div>
        </div>
    );
}
