import { useContractFunction } from "@usedapp/core";
import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";

export function useWriteMarketplaceContract(contractAddress: string, methodName: string) {

    const contractAbi = new utils.Interface(
        require(`../assets/abi/testnet/Marketplace.json`)
    );

    const contract = new Contract(contractAddress, contractAbi);
    return useContractFunction(contract, methodName);
}
