import React, { useState } from "react";
import ReactLoading from "react-loading";
import moment from "moment";
import { Snackbar } from "@mui/material";
import { ButtonComponent } from "../../../../components/button/ButtonComponent";
import { useWriteMarketplaceContract } from "../../../../customHooks/useWriteMarketplaceContract";
import { GetEnv } from "../../../../environments/environment";

interface ConfirmNftProps {
  marketplaceAddress: string;
  tokenId: number;
  priceToBuy: any;
  basePrice: any;
  tokenAddress: any;
  start: any;
  end: any;
}

export const ConfirmNft: React.FC<ConfirmNftProps> = ({
  marketplaceAddress,
  tokenId,
  priceToBuy,
  basePrice,
  tokenAddress,
  start,
  end,
}) => {
  const [open, setOpen] = useState(true);
  const bigInt18 = 1000000000000000000;

  const { send, state } = useWriteMarketplaceContract(
    marketplaceAddress,
    "sell"
  );

  if (state.status === "Success") {
    setTimeout(() => {
      window.location.href = "/nft";
    }, 5000);
  }

  let token = "";

  if (tokenAddress === "COC") {
    token = GetEnv()["REACT_APP_COC_CONTRACT_ADDRESS"] as string;
  } else if (tokenAddress === "USDT") {
    token = "0xba719d5C61B210C2E259786f489a9141D75Ef728";
  } else {
    token = "0x0000000000000000000000000000000000000000";
  }

  const confirmSell = () => {
    const basePriceToSend = (+basePrice * bigInt18).toLocaleString("fullwide", {
      useGrouping: false,
    });
    const priceToBuyToSend = (+priceToBuy * bigInt18).toLocaleString(
      "fullwide",
      { useGrouping: false }
    );
    const startDate = moment(start, "DD/MM/YYYY-HH:mm").unix();
    const endDate = moment(end, "DD/MM/YYYY-HH:mm").unix();

    send(tokenId, basePriceToSend, priceToBuyToSend, token, startDate, endDate);
  };

  return (
    <>
      {state.status === "Mining" ? (
        <ReactLoading width={25} type={"spinningBubbles"} color="#E4F600" />
      ) : (
        <ButtonComponent
          style={{ width: "215px" }}
          disabled={
            Number(basePrice) < 0 ||
            Number(priceToBuy) < 0 ||
            start === "" ||
            end === ""
          }
          onClick={confirmSell}
        >
          Confirm
        </ButtonComponent>
      )}

      {state.status === "Exception" && (
        <Snackbar
          key={state.chainId}
          open={open}
          autoHideDuration={5000}
          onClose={() => setOpen(false)}
          message={state.errorMessage}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        />
      )}

      {state.status === "Mining" && (
        <Snackbar
          key={state.chainId}
          open={open}
          autoHideDuration={5000}
          onClose={() => setOpen(false)}
          message="Waiting for confirmation!"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        />
      )}

      {state.status === "Success" && (
        <Snackbar
          key={state.chainId}
          open={open}
          autoHideDuration={5000}
          onClose={() => setOpen(false)}
          message="Confirmed transaction!"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
        />
      )}
    </>
  );
};
