/* eslint-disable react-hooks/rules-of-hooks */
import {useEthers} from "@usedapp/core";
import VestingHelper from "./vestingHelper";

export interface VestData {
    id: number;
    locksIds: number[];
    deposit: string;
    rewardsBase: string;
    rewardsAfterOneMonth: string;
    rewardsAfterOneYear: string;
    rewardsAfterThreeMonths: string;
    rewardsAfterSixMonths: string;
}

export interface Lock {
    start: number;
    end: number;
    amount: number;
    period: number;
    reward: number;
    claimed: boolean;
}

export default class ManageVestingData {

    static get vestData(): VestData[] {
        const {account} = useEthers();
        let data: VestData[] = [];

        for (let i = 0; i < VestingHelper.vestContractLength; i++) {
            data.push({
                id: i + 1,
                locksIds: VestingHelper.readVestingContract(
                    "getLocksIdByAddress",
                    i + 1,
                    [account]
                ),
                deposit: BigInt(
                    VestingHelper.readVestingContract(
                        "depositedCoc",
                        i + 1,
                        [account]
                    )
                ).toString(),
                rewardsBase: BigInt(
                    VestingHelper.readVestingContract(
                        "rewardsBase",
                        i + 1
                    )
                ).toString(),
                rewardsAfterOneYear: BigInt(
                    VestingHelper.readVestingContract(
                        "rewardsAfterOneYear",
                        i + 1
                    )
                ).toString(),
                rewardsAfterOneMonth: BigInt(
                    VestingHelper.readVestingContract(
                        "rewardsAfterOneMonth",
                        i + 1
                    )
                ).toString(),
                rewardsAfterThreeMonths: BigInt(
                    VestingHelper.readVestingContract(
                        "rewardsAfterThreeMonths",
                        i + 1
                    )
                ).toString(),
                rewardsAfterSixMonths: BigInt(
                    VestingHelper.readVestingContract(
                        "rewardsAfterSixMonths",
                        i + 1
                    )
                ).toString()
            })
        }

        return data;
    }
}