import { useContractFunction } from "@usedapp/core";
import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";
import { GetEnv } from "../environments/environment";

export function useWriteCOC(methodName: string) {
  const contracAbi = new utils.Interface(
    require(`../assets/abi/testnet/coc.abi.json`)
  );
  const contractAddress = `${GetEnv()[`REACT_APP_COC_CONTRACT_ADDRESS`]}`;

  const contract = new Contract(contractAddress, contracAbi);

  return useContractFunction(contract, methodName);
}
