/* eslint-disable react-hooks/rules-of-hooks */
import { useEthers } from "@usedapp/core";
import StakingHelper from "./stakingHelper";
import StakingRewards from "./stakingRewards";
import { BigNumber } from "@ethersproject/bignumber";

export interface StakeData {
    id: number;
    name: string;
    myStake: string;
    APY: string;
    isEnded: boolean;
    timeLeft: number;
    statWithdraw: string;
    rewards: BigNumber;
    rewardReflectionEnded: BigNumber;
    rewardReflection: BigNumber;
}

export default class ManageStakingData {
    static getTimeLeft(index: number) {
        const time = {
            start: BigInt(
                StakingHelper.readStakingContract(
                    "stakingStarts",
                    index + 1
                )
            ).toString(),
            end: BigInt(
                StakingHelper.readStakingContract(
                    "stakingEnds",
                    index + 1
                )
            ).toString()
        };

        return (Number(time.end) * 1000)
    }

    static getIsEnded(index: number) {
        const end = BigInt(
            StakingHelper.readStakingContract(
                "stakingEnds",
                index + 1
            )
        )

        const now = + new Date();

        return (Number(end) * 1000) < now;
    }

    static get stakeData(): StakeData[] {
        const { account } = useEthers();
        let data: StakeData[] = [];

        for (let i = 0; i < StakingHelper.stakeContractLength; i++) {
            data.push({
                id: i + 1,
                name: StakingHelper.readStakingContract(
                    "name",
                    i + 1
                ),
                myStake: BigInt(
                    StakingHelper.readStakingContract(
                        "stakeOf",
                        i + 1,
                        [account]
                    )
                ).toString(),
                APY: StakingHelper.calculateAPY(
                    BigInt(
                        StakingHelper.readStakingContract(
                            "withdrawStart",
                            i + 1
                        )
                    ),
                    BigInt(
                        StakingHelper.readStakingContract(
                            "stakingEnds",
                            i + 1
                        )
                    ),
                    BigInt(
                        StakingHelper.readStakingContract(
                            "totalReward",
                            i + 1
                        )
                    ),
                    BigInt(
                        StakingHelper.readStakingContract(
                            "stakedTotal",
                            i + 1
                        )
                    )
                ),
                isEnded: ManageStakingData.getIsEnded(i),
                timeLeft: ManageStakingData.getTimeLeft(i),
                statWithdraw: BigInt(
                    StakingHelper.readStakingContract(
                        "withdrawStart",
                        i + 1
                    )
                ).toString(),
                rewards: StakingRewards.stakingRewards(i + 1),
                // TODO: fix
                rewardReflectionEnded: StakingHelper.readStakingContract(
                    "reflectedFees",
                    i + 1
                ),
                rewardReflection: StakingRewards.stakingRewardReflected(i + 1)
            })
        }

        return data;
    }

    static get allData() {
        return this.stakeData
    }

    static get liveData() {
        return this.stakeData.filter(item => item.isEnded === false)
    }

    static get endedData() {
        return this.stakeData.filter(item => item.isEnded === true)
    }
}
