import {useContractCall} from "@usedapp/core";
import {Interface} from '@ethersproject/abi';
import abi from '../assets/abi/mainnet/coc.abi.json';
import {GetEnv} from "../environments/environment";

export function useReadCOCContract(method: string, args?: any[]) {
    if (args === undefined) {
        args = [];
    }
    const CONTRACT_ADDRESS = GetEnv()['REACT_APP_COC_CONTRACT_ADDRESS'] as string;
    const abiInterface = new Interface(abi);
   

    const contractResult = useContractCall({
        abi: abiInterface,
        address: CONTRACT_ADDRESS,
        method,
        args
    });

    return contractResult === undefined ? [] : contractResult[0];
}
