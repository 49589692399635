import React from "react";
import {useReadGenericErc721} from "../../../../customHooks/useReadGenericErc721";
import {NftUpdateDetail} from "./nftUpdateDetails";

interface NftContentProps {
    marketplaceAddress: string;
    collectionAddress: string;
    setUpdatedList: any;
}

export const NftUpdate: React.FC<NftContentProps> = ({marketplaceAddress, collectionAddress, setUpdatedList}) => {
    const totalSupply = useReadGenericErc721(collectionAddress, 'totalSupply');

    let numbers: number[] = [];
    if (totalSupply && totalSupply > 0) {
        for (let i = 0; i < totalSupply; i++) {
            numbers.push(i)
        }
    }

    return (
        <>
            {!!numbers.length && <>
                {numbers.map((id, index) => (
                        <NftUpdateDetail tokenId={id} marketplaceAddress={marketplaceAddress}
                                        collectionAddress={collectionAddress} key={index} setUpdatedList={setUpdatedList}/>))}
            </>
            }
        </>
    );
};
