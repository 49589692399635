import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useEthers } from "@usedapp/core";
import { ButtonComponent } from "../../../components/button/ButtonComponent";
import { useReadMarketplaceContract } from "../../../customHooks/useReadMarketplaceContract";
import css from "../myNft.module.css";
import { useWriteMarketplaceContract } from "../../../customHooks/useWriteMarketplaceContract";

interface MyNftHeaderProps {
  marketplaceAddress: string;
  collectionAddress: string;
}

export const MyNftHeader: React.FC<MyNftHeaderProps> = ({
  marketplaceAddress,
  collectionAddress,
}) => {
  const { account } = useEthers();
  const [owner, setOwner] = useState<boolean>();
  const [cocWhitelisted, setCocWhitelisted] = useState<boolean>();
  const [usdtWhitelisted, setUsdtWhitelisted] = useState<boolean>();

  const marketplaceOwner = useReadMarketplaceContract(
    marketplaceAddress,
    "owner"
  );
  const marketplaceWhitelistedToken = useReadMarketplaceContract(
    marketplaceAddress,
    "getWhitelistedPaymentTokens"
  );

  const { send } = useWriteMarketplaceContract(
    marketplaceAddress,
    "whitelistPaymentToken"
  );

  if (marketplaceWhitelistedToken && cocWhitelisted === undefined) {
    if (
      marketplaceWhitelistedToken.includes(
        "0xbDC3b3639f7AA19e623A4d603A3Fb7Ab20115A91"
      )
    ) {
      setCocWhitelisted(true);
    } else if (
      marketplaceWhitelistedToken.includes(
        "0xba719d5C61B210C2E259786f489a9141D75Ef728"
      )
    ) {
      setUsdtWhitelisted(true);
    }
  }

  if (marketplaceOwner && owner === undefined) {
    if (marketplaceOwner === account) {
      setOwner(true);
    }
  }

  const whitelistCOC = () => {
    send("0xbDC3b3639f7AA19e623A4d603A3Fb7Ab20115A91");
  };

  const whitelistUSDT = () => {
    send("0xba719d5C61B210C2E259786f489a9141D75Ef728");
  };

  return (
    <>
      <div className={css.titleContainer}>
        {owner && (
          <>
            <Link
              className={css.load}
              to={`/load-new/${marketplaceAddress}/${collectionAddress}`}
            >
              <ButtonComponent>Load Nft</ButtonComponent>
            </Link>

            {!cocWhitelisted && (
              <ButtonComponent onClick={whitelistCOC}>
                Whitelist COC
              </ButtonComponent>
            )}
            {!usdtWhitelisted && (
              <ButtonComponent onClick={whitelistUSDT}>
                Whitelist USDT
              </ButtonComponent>
            )}
          </>
        )}
      </div>
    </>
  );
};
