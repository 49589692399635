import React from "react";
import {useReadMarketplaceContract} from "../../../customHooks/useReadMarketplaceContract";
import {GetCollection} from "./getCollection";

interface GetCollectionProps {
    marketplace: string;
}

export const GetCollections: React.FC<GetCollectionProps> = ({marketplace}) => {
    const nft = useReadMarketplaceContract(marketplace, 'nft');

    return (
        <>
            {!!nft.length && <GetCollection collection={nft} marketplace={marketplace} />}
        </>
    )
}
