import React from "react";
import {CHAIN_NAMES, ChainId, useEthers} from "@usedapp/core";
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';
import {AddNetwork} from "./addNetwork";
import {BSC_network} from "./utils/networkList";

export const CheckNetwork: React.FC = () => {
    const {chainId} = useEthers();
    const chainIds = [ChainId.BSC];
    const SUPPORTED_NETWORKS = [BSC_network];

    const link = (
        <a href="/home" style={{textDecoration: 'none'}}>home page</a>
    )

    return (
        <div>
            {
                (chainId && !chainIds.some(i => i === chainId)) &&
                <ErrorNetwork>

                        <span style={{fontSize: 36, fontWeight: 'bold'}}>
                            Roll it back - this feature is not yet supported on {CHAIN_NAMES[chainId]}.
                         </span>

                    <span style={{fontSize: 16}}>
                            Either return to the {link}, or change to an available network.
                        </span>

                    <span
                        style={{
                            fontSize: 18,
                            fontWeight: 'bolder',
                            textTransform: 'uppercase'
                        }}>
                            Available Networks
                        </span>

                    <div>
                        {chainIds.map((chain, index) => (
                            <div key={index}><AddNetwork supportedNetworks={SUPPORTED_NETWORKS}/></div>
                        ))}
                    </div>

                </ErrorNetwork>
            }
        </div>
    );
};

const ErrorNetwork = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;
    justify-content: center !important;
    text-align: center !important;
    font-family: 'Montserrat' !important;
    position: fixed;
    height: 100% !important;
    width: 90% !important;
    color: white !important;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    z-index: 100;
    margin-top: -15px;
`;
