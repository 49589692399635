import {useContractCall} from "@usedapp/core";
import {Interface} from '@ethersproject/abi';
import abi from '../assets/abi/testnet/GenericERC721.json';

export function useReadGenericErc721(address: string, method: string, args?: any[]) {
    if (args === undefined) {
        args = [];
    }
    const abiInterface = new Interface(abi);


    const contractResult = useContractCall({
        abi: abiInterface,
        address,
        method,
        args
    });

    return contractResult === undefined ? [] : contractResult[0];
}
