import React from "react";
import {BrowserRouter} from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import {useEthers} from "@usedapp/core";
import {ToolbarComponent} from "./components/toolbar/ToolbarComponent";
import './App.css';

export const App: React.FC = () => {
    const {chainId} = useEthers();
    const chainSaved = localStorage.getItem('chainId');

    if (chainId && chainId.toString() !== chainSaved) {
        localStorage.setItem('chainId', chainId.toString());
        window.location.reload();
    }

    return (
        <BrowserRouter>
            <ToolbarComponent/>
        </BrowserRouter>
    );
};
