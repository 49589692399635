import React, {useState} from "react";
import {useEthers} from "@usedapp/core";
import {Box, Modal} from "@material-ui/core";
import {CircularProgress, Snackbar} from "@mui/material";
import {Col, Row} from "react-bootstrap";
import ReactLoading from "react-loading";
import {BigNumber} from "@ethersproject/bignumber";
import FarmingHelper from "../../../../helpers/farmingHelper";
import {FarmData} from "../../../../helpers/manageFarmingData";
import {useWriteFarmingContract} from "../../../../customHooks/useWriteFarmingContract";
import {ButtonComponent} from "../../../../components/button/ButtonComponent";
import {
    WithdrawInputComponentFarm, WithdrawInputComponentFarmSm
} from "../../../../components/input/WithdrawInputComponent";
import {AddTokenPanel} from "../../../../components/token/token";
import css from "../farmSend/farmSend.module.css";
import {formatEther} from "@ethersproject/units";
import {GetEnv} from "../../../../environments/environment";

interface FarmContractProps {
    farmContract: FarmData;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    boxShadow: '2px 2px 1px -1px #E4F600 !important',
    background: 'linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%)',
    backdropFilter: 'blur(40px)',
    borderRadius: '20px !important'
};

export const FarmEmergencyWithdraw: React.FC<FarmContractProps> = ({farmContract}) => {
    const {account} = useEthers();
    const [textInput, setTextInput] = useState("0");
    const {state, send} = useWriteFarmingContract("emergencyWithdraw");
    const [open, setOpen] = useState(true);
    const [openModal, setOpenModal] = useState(true);

    const lpDeposited = FarmingHelper.readFarmingContract(`${GetEnv()[`REACT_APP_FARMING_CONTRACT_ADDRESS_1`]}`,'deposited', [farmContract.id, account]);

    const withdraw = async () => {
        await send(farmContract.id);
        setOpen(true);
    }

    if (typeof lpDeposited === "object" && farmContract.pendingReward && textInput === "0") {
        let lpBalanceBigNumber: BigNumber = BigNumber.from(lpDeposited.toString())
        let amount = formatEther(lpBalanceBigNumber._hex)
        setTextInput(amount)
    }

    if (state.status === 'Success') {
        setInterval(() => {
            if (!openModal) {
                window.location.reload()
            }
        }, 2000);
    }

    return (
        <Col xs={12} sm={12} md={12} lg={12} xl={12} style={{margin: 0, padding: 0}}>
            {(state.status === 'Success' && window.ethereum) && <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <Box sx={style}>
                    <AddTokenPanel/>
                </Box>
            </Modal>}
            <Row style={{margin: 0, padding: 0}}>
                <Col className={'d-none d-sm-none d-md-block'} md={6} lg={6} xl={6} style={{margin: '10px 0',}}>
                    <WithdrawInputComponentFarm
                        type="text"
                        value={textInput}
                        onChange={(e) => setTextInput(e.target.value)}
                        readOnly={state.status === 'Mining'}
                    />
                </Col>
                <Col className={'d-none d-sm-block d-md-none'} xs={12} sm={6} style={{margin: '10px 0', padding: 0}}>
                    <WithdrawInputComponentFarmSm
                        type="text"
                        value={textInput}
                        onChange={(e) => setTextInput(e.target.value)}
                        readOnly={state.status === 'Mining'}
                    />
                </Col>
                <Col className={'d-block d-sm-none align-items-center justify-content-center'} xs={12} sm={6}
                     style={{margin: '20px 0 0 0', padding: 0}}>
                    <WithdrawInputComponentFarmSm
                        style={{margin: '0 auto'}}
                        type="text"
                        value={textInput}
                        onChange={(e) => setTextInput(e.target.value)}
                        readOnly={state.status === 'Mining'}
                    />
                </Col>
                <Col className={'d-none d-sm-block'} xs={12} sm={6} md={6} lg={6} xl={6} style={{
                    margin: '10px 0',
                    padding: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "end"
                }}>
                    {state.status === 'Mining' ?
                        <ReactLoading className={css.marginLeft30} width={25} type={'spinningBubbles'} color="#E4F600"/>
                        :
                        <ButtonComponent onClick={withdraw} disabled={!account}>Emergency Withdraw</ButtonComponent>
                    }
                </Col>
                <Col className={'d-block d-sm-none align-items-center justify-content-center '} xs={12} sm={6} md={6}
                     lg={6} xl={6} style={{
                    margin: '5px 0',
                    padding: 0,
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "start"
                }}>
                    {state.status === 'Mining' ?
                        <CircularProgress style={{color: '#E4F600', width: 15}}/>
                        :
                        <ButtonComponent onClick={withdraw} disabled={!account}>Withdraw</ButtonComponent>
                    }
                </Col>
            </Row>

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </Col>
    );
}
