import { Snackbar } from "@mui/material";
import React, { useState } from "react";
import { ButtonComponent } from "../../../../../components/button/ButtonComponent";
import { useWriteStakingContract } from "../../../customHooks/useWriteStakingContract";
import ReactLoading from "react-loading";
import css from "../stakeTextInput/stakeTextInput.module.css";

interface ISendStake {
  stakeIndex: number;
  stakeAmount: string;
}

export const SendStake: React.FC<ISendStake> = (props) => {
  const { state, send } = useWriteStakingContract("stake", props.stakeIndex);
  const [open, setOpen] = useState(true);
  const bigInt18 = 1000000000000000000;

  if (state.status === 'Success') {
    setTimeout(() => {
      window.location.reload()
    }, 5000);
  }

  async function sendStakeTx() {
    let amountToSend = (+props.stakeAmount * bigInt18).toLocaleString('fullwide', {useGrouping:false});
    await send(amountToSend);
  }

  return (
    <div>
      {
        state.status === 'Mining' ?
            <ReactLoading className={css.marginLeft30} width={25} type={'spinningBubbles'}  color="#E4F600" />
            :
            <ButtonComponent onClick={sendStakeTx} >Stake</ButtonComponent>
      }

      {state.status === 'Exception' &&
        <Snackbar
          key={state.chainId}
          open={open}
          autoHideDuration={5000}
          onClose={() => setOpen(false)}
          message={state.errorMessage}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        />
      }

      {state.status === 'Mining' &&
        <Snackbar
          key={state.chainId}
          open={open}
          autoHideDuration={5000}
          onClose={() => setOpen(false)}
          message="Waiting for confirmation!"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        />
      }

      {state.status === 'Success' &&
        <Snackbar
          key={state.chainId}
          open={open}
          autoHideDuration={5000}
          onClose={() => setOpen(false)}
          message="Confirmed transaction!"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
          }}
        />
      }
    </div>
  );
};
