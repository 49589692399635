import React, {useState} from "react";
import {Snackbar} from "@mui/material";
import {useWriteMarketplaceContract} from "../../../../../../customHooks/useWriteMarketplaceContract";
import {ButtonComponent} from "../../../../../../components/button/ButtonComponent";

interface ClaimProps {
    tokenId: string;
    marketplaceAddress: string;
}

export const Claim: React.FC<ClaimProps> = ({tokenId, marketplaceAddress}) => {
    const {send, state} = useWriteMarketplaceContract(marketplaceAddress, 'claim');
    const [open, setOpen] = useState(true);

    const claim = async () => {
        await send(tokenId);
    }

    if (state.status === 'Success') {
        setInterval(() => {
            window.location.reload()
        }, 2000);
    }

    return (
        <>
            <ButtonComponent onClick={claim}>Claim</ButtonComponent>

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </>
    )
}
