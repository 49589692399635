import React, {useState} from "react";
import {formatUnits} from "ethers/lib/utils";
import moment from "moment";
import {Collapse} from "react-bootstrap";
import ReactLoading from "react-loading";
import {Box, IconButton, Snackbar} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {Lock, VestData} from "../../../../helpers/manageVestingData";
import VestingHelper from "../../../../helpers/vestingHelper";
import {ButtonComponent} from "../../../../components/button/ButtonComponent";
import {EmergencyWithdraw} from "./emergencyWithdraw";
import css from "./vestingTable.module.css";

interface LockItemProps {
    vestContract: VestData;
    lockId: number;
}

export const LockItem: React.FC<LockItemProps> = ({vestContract, lockId}) => {
    const lock: Lock = VestingHelper.readVestingContract('locks', vestContract.id, [lockId])
    const [timeLeft, setTimeLeft] = useState<number>();
    const [ended, setEnded] = useState(false);
    const [open, setOpen] = useState(true);
    const [openAcc, setOpenAcc] = React.useState(false);
    const {state, send} = VestingHelper.writeVestingContract("withdraw", vestContract.id);
    const now = +new Date();
    const apr = lock ? VestingHelper.calculateAPR(
        formatUnits(lock.reward, 18),
        formatUnits(lock.amount, 18),
        +lock.period
    ) : 0;

    if (lock && timeLeft === undefined) {
        setTimeLeft(+lock.end - now / 1000)
        if (+lock.end * 1000 < now) {
            setEnded(true)
        }
    }

    const withdraw = async () => {
        await send(lockId)
    }

    return (
        <>
            {lock &&
                <>
                    <TableRow id={css.tableRowTitles} sx={{'& > *': {borderBottom: 'unset'}}}>
                        <TableCell>
                            <IconButton
                                aria-label="expand row"
                                size="small"
                                onClick={() => setOpenAcc(!openAcc)}
                                style={{borderRadius: '25px'}}
                            >
                                {openAcc ? <KeyboardArrowUpIcon className={css.iconStyle}/> :
                                    <KeyboardArrowDownIcon className={css.iconStyle}/>}
                            </IconButton>
                        </TableCell>
                        <TableCell className={css.textTable}>{formatUnits(lock.amount, 18)} COC</TableCell>
                        <TableCell
                            className={css.textTable}>{apr.toFixed(8).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1')}%</TableCell>
                        <TableCell className={css.textTable}>{+lock.period} days</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                            <Collapse in={openAcc} unmountOnExit>
                                <Box>
                                    <div className={css.cards}>
                                        <div style={{
                                            display: 'flex',
                                            flexDirection: 'row',
                                            justifyContent: 'space-between',
                                            gap: '10px',
                                            width: '100%'
                                        }}>
                                            <div id={css.cardsOpen}>
                                                <span>COC REWARD</span>
                                                <div className={css.profit}>
                                                    <span>{(+formatUnits(lock.reward, 18) * +formatUnits(lock.amount, 18) * +lock.period).toFixed(2)}</span>
                                                </div>
                                            </div>
                                            <div id={css.cardsOpen}>
                                                <span>WITHDRAW</span>
                                                <div className={css.profit}>
                                                    {
                                                        lock.claimed ?
                                                            <span>Claimed</span> :
                                                            !ended ?
                                                                <span>unlocks {moment(+lock.end * 1000).fromNow()}</span> :
                                                                (
                                                                    <div>
                                                                        {
                                                                            state.status === 'Mining' ?
                                                                                <ReactLoading className={css.loading}
                                                                                              width={21}
                                                                                              type={'spinningBubbles'}
                                                                                              color="#E4F600"/>
                                                                                :
                                                                                <ButtonComponent
                                                                                    onClick={withdraw}>Withdraw</ButtonComponent>
                                                                        }
                                                                    </div>
                                                                )
                                                    }
                                                </div>
                                            </div>
                                            {
                                                (!lock.claimed && !ended) &&
                                                <div id={css.cardsOpen}>
                                                    <EmergencyWithdraw lock={lock}
                                                                       vestContract={vestContract}
                                                                       lockId={lockId}/>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </Box>
                            </Collapse>
                        </TableCell>
                    </TableRow>
                </>
            }

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={2000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </>
    )
}
