import React, {useState} from "react";
import {Col, Row} from "react-bootstrap";
import ReactLoading from "react-loading";
import moment from 'moment';
import {formatUnits} from "ethers/lib/utils";
import {Snackbar} from "@material-ui/core";
import {BigNumber} from "@ethersproject/bignumber";
import {Box, Modal} from "@material-ui/core";
import {ButtonComponent} from "../../../../../components/button/ButtonComponent";
import {AddTokenPanel} from "../../../../../components/token/token";
import {WithdrawInputComponent, WithdrawInputComponentSm} from "../../../../../components/input/WithdrawInputComponent";
import {useWriteStakingContract} from "../../../customHooks/useWriteStakingContract";
import {StakeData} from "../../../../../helpers/manageStakingData";
import css from "./withdrawTextInput.module.css"

interface ITextInputProps {
    stakeContract: StakeData;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    boxShadow: '2px 2px 1px -1px #E4F600 !important',
    background: 'linear-gradient(117.9deg, rgba(255, 255, 255, 0.4) 10.75%, rgba(255, 255, 255, 0.1) 79.25%)',
    backdropFilter: 'blur(40px)',
    borderRadius: '20px !important'
};

export const WithdrawTextInput: React.FC<ITextInputProps> = ({stakeContract}) => {
    const [textInput, setTextInput] = useState("");
    const {state, send} = useWriteStakingContract("withdraw", stakeContract.id);
    const [open, setOpen] = useState(true);
    const [openModal, setOpenModal] = useState(true);
    const [ended, setEnded] = useState(false);
    const [timeLeft, setTimeLeft] = useState<number>();

    const now = +new Date() / 1000;

    const withdraw = async () => {
        await send(stakeContract.myStake)
        setOpen(true);
    }

    if (+stakeContract.statWithdraw > 0 && timeLeft === undefined) {
        setTimeLeft(+stakeContract.statWithdraw - now / 1000)
        if ((+stakeContract.statWithdraw - now) < 0) {
            setEnded(true)
        }

        const myStake = BigNumber.from(stakeContract.myStake)
        let textInputNumber = formatUnits(myStake.toString(), 18)

        setTextInput(textInputNumber.toString());
    }

    if (state.status === 'Success') {
        setInterval(() => {
            if (!openModal) {
                window.location.reload()
            }
        }, 2000);
    }

    return (
        <div>
            {(state.status === 'Success' && window.ethereum) && <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
            >
                <Box sx={style}>
                    <AddTokenPanel/>
                </Box>
            </Modal>}
            <div className={css.send}>
                <Row style={{margin: 0, padding: 0, width: '100%'}}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={11} style={{margin: 0, padding: 0}}>
                        <Row style={{margin: 0, padding: 0}}>
                            <Col className={'d-none d-sm-block'} xs={12} sm={12} md={8} lg={12} xl={8}
                                 style={{margin: 0, padding: 0}}>
                                <WithdrawInputComponent
                                    type="text"
                                    value={textInput}
                                    disabled
                                />
                            </Col>
                            <Col className={'d-block d-sm-none'} xs={12} sm={12} md={12} lg={12} xl={8}
                                 style={{margin: 0, padding: 0}}>
                                <WithdrawInputComponentSm
                                    type="text"
                                    value={textInput}
                                    disabled
                                />
                            </Col>
                            <Col className={'d-none d-sm-block'} xs={12} sm={12} md={4} lg={12} xl={4}
                                 style={{margin: 0, padding: '-1px', alignItems: "center", justifyContent: "center"}}>
                                {
                                    state.status === 'Mining' ?
                                        <ReactLoading className={css.marginLeft30} width={25} type={'spinningBubbles'}
                                                      color="#E4F600"/>
                                        :
                                        <ButtonComponent style={{minWidth:150}} onClick={withdraw} className={!ended ? css.enableBtn : css.disabledBtn}
                                                         disabled={!ended}>Withdraw</ButtonComponent>
                                }
                            </Col>
                            <Col  className={'d-block d-sm-none'} xs={12} sm={12} md={4} lg={12} xl={4}
                                 style={{margin: 0, padding: '-1px', alignItems: "center", justifyContent: "center"}}>
                                {
                                    state.status === 'Mining' ?
                                        <ReactLoading className={css.marginLeft30} width={25} type={'spinningBubbles'}
                                                      color="#E4F600"/>
                                        :
                                        <ButtonComponent style={{width:'90%',padding:0, margin: '0 auto'}} onClick={withdraw} className={!ended ? css.enableBtn : css.disabledBtn}
                                                         disabled={!ended}>Withdraw</ButtonComponent>
                                }
                            </Col>
                        </Row>
                    </Col>
                    <Col className={'d-none d-sm-block'} xs={12} sm={12} md={12} lg={12} xl={1}
                         style={{margin: 0, padding: 0}}>
                        {!ended && <div className={css.timeLeft}>
                            <span style={{fontSize: '10px', marginTop: '-6px', marginBottom: '-8px'}}>unlocks</span>
                            <span>{moment(stakeContract.statWithdraw ? (+stakeContract.statWithdraw * 1000) : 0).fromNow()}</span>
                        </div>}
                    </Col>
                    <Col className={'d-block d-sm-none'} xs={12} sm={12} md={12} lg={12} xl={1}
                         style={{margin: 0, textAlign: "center", padding: 0}}>
                        {!ended && <div className={css.timeLeft} style={{margin: '0 auto'}}>
                            <span style={{fontSize: '10px', margin: '0 auto 0 auto'}}>unlocks</span>
                            <span>{moment(stakeContract.statWithdraw ? (+stakeContract.statWithdraw * 1000) : 0).fromNow()}</span>
                        </div>}
                    </Col>
                </Row>
            </div>

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </div>
    );
};
