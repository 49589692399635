import {ChainId} from "@usedapp/core";
import Mainnet from '../../../assets/icons/networks/mainnet.jpeg'
import BSC from '../../../assets/icons/networks/bsc.jpeg'
import Fantom from '../../../assets/icons/networks/fantom.jpeg'
import Goerli from '../../../assets/icons/networks/fantom.jpeg'
import Harmony from '../../../assets/icons/networks/fantom.jpeg'
import Kovan from '../../../assets/icons/networks/fantom.jpeg'
import Polygon from '../../../assets/icons/networks/polygon.jpeg'
import Rinkeby from '../../../assets/icons/networks/rinkeby.jpeg'
import Ropsten from '../../../assets/icons/networks/ropsten.jpeg'
import xDai from '../../../assets/icons/networks/xdai.jpeg'
import Palm from '../../../assets/icons/networks/palm.jpeg'
import Moonriver from '../../../assets/icons/networks/moonriver.jpeg'

export const ETH_network = {
    chainId: '0x1',
    chainName: 'Ethereum',
    nativeCurrency: {
        name: 'Ethereum',
        symbol: 'ETH',
        decimals: 18,
    },
    rpcUrls: ['https://mainnet.infura.io/v3'],
    blockExplorerUrls: ['https://etherscan.com'],
    icon: Mainnet
}

export const BSC_network = {
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    nativeCurrency: {
        name: 'Binance Coin',
        symbol: 'BNB',
        decimals: 18,
    },
    rpcUrls: ['https://bsc-dataseed.binance.org'],
    blockExplorerUrls: ['https://bscscan.com'],
    icon: BSC
}

export const BSCTestnet_network = {
    chainId: '0x61',
    chainName: 'Binance Smart Chain Testnet',
    nativeCurrency: {
        name: 'Smart Chain - Testnet',
        symbol: 'BNB',
        decimals: 18,
    },
    rpcUrls: ['https://data-seed-prebsc-1-s1.binance.org:8545/'],
    blockExplorerUrls: ['https://testnet.bscscan.com'],
    icon: BSC
}

export const NETWORK_ICON: { [chainId in ChainId]?: string } = {
    [ChainId.Mainnet]: Mainnet,
    [ChainId.Ropsten]: Ropsten,
    [ChainId.Rinkeby]: Rinkeby,
    [ChainId.Goerli]: Goerli,
    [ChainId.Kovan]: Kovan,
    [ChainId.Fantom]: Fantom,
    [ChainId.BSC]: BSC,
    [ChainId.BSCTestnet]: BSC,
    [ChainId.Polygon]: Polygon,
    [ChainId.xDai]: xDai,
    [ChainId.Harmony]: Harmony,
    [ChainId.Palm]: Palm,
    [ChainId.Moonriver]: Moonriver,
}
