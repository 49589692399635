import {formatUnits} from "ethers/lib/utils";
import {CardContent} from "@material-ui/core";
import {Col, Row} from "react-bootstrap";
import {StakeData} from "../../../../../helpers/manageStakingData";
import {CardComponent} from "../../../../../components/cards/cardComponent/CardComponent";
import {StakeTextInput} from "../stakeTextInput/stakeTextInput";
import css from './stakeItem.module.css'
import buttonArrow from "../../../../../assets/icons/button.svg";
import {WithdrawTextInput} from "../withdrawTextInput/withdrawTextInput";
import React, {useState} from "react";

interface StakeItemProps {
    stakeContract: StakeData;
}

export const StakeItem: React.FC<StakeItemProps> = ({stakeContract}) => {

    const [view, setView] = useState(false);


    return (
        <Row style={{margin: ' 10px 0', padding: 0, justifyContent: 'center'}}>
            <Col style={{margin: 0, padding: 0}} xs={12} sm={12} md={12} lg={8} xl={8}>
                <CardComponent>
                    <CardContent>
                        <div className={css.name}>
                            {stakeContract.name}
                        </div>
                        <Row style={{margin: 0, padding: 0}}>
                            <Col className={'d-none d-sm-block'} xs={12} sm={12} md={12} lg={12} xl={12} >
                                <StakeTextInput stakeContract={stakeContract}/>
                                { !view ?
                                    <div className={'text-center'}>
                                        <img onClick={()=>{setView(true)}} src={buttonArrow} alt={''} style={{width:100,margin:'0 auto'}}/>
                                    </div>
                                    :
                                    <Row className={css.box}>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ margin: '5px 0', width: '360px' }}>
                                            <Row style={{ margin: 0, padding: 0 }} className={css.myStake}>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span style={{fontWeight:'bold'}}>My stake</span>
                                                </Col>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                        {formatUnits(stakeContract.myStake, 18)} COC
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{ margin: '5px 0', width: '360px' }}>
                                            <Row style={{ margin: 0, padding: 0 }} className={css.apy}>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span style={{fontWeight:'bold'}}>APY</span>
                                                </Col>
                                                <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                         {stakeContract.APY}%
                                                    </span>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                }
                            </Col>
                            <Col className={'d-block d-sm-none'} xs={12} sm={10} md={10} lg={10} xl={10} style={{ alignItems: "center", justifyContent: "center", textAlign: "center" }}>
                                <WithdrawTextInput stakeContract={stakeContract} />
                                { !view ?
                                    <img onClick={()=>{setView(true)}} src={buttonArrow} alt={''} style={{width:100,margin:'0 auto'}}/>
                                    :
                                    <Row style={{ margin: 0, padding: 0 }} >
                                        <Row style={{margin: 0, padding: 0}}>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}
                                                 style={{margin: '10px auto', padding: 0}}>
                                                <Row style={{ margin: 0, padding: 0 }} className={css.myStake}>
                                                    <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                        <span style={{fontWeight:'bold'}}>My stake</span>
                                                    </Col>
                                                    <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                        {formatUnits(stakeContract.myStake, 18)} COC
                                                    </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs={12} sm={12} md={12} lg={6} xl={6}
                                                 style={{margin: '10px auto', padding: 0}}>
                                                <Row style={{ margin: 0, padding: 0 }} className={css.apy}>
                                                    <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                        <span style={{fontWeight:'bold'}}>APY</span>
                                                    </Col>
                                                    <Col xs={6} style={{ margin: 0, padding: 0 }}>
                                                    <span>
                                                         {stakeContract.APY}%
                                                    </span>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                        <img onClick={()=>{setView(false)}} src={buttonArrow} alt={''} style={{width:100,margin:'0 auto',transform:'rotate(180deg)'}}/>
                                    </Row>
                                }
                            </Col>
                        </Row>
                    </CardContent>
                </CardComponent>
            </Col>
        </Row>
    );
}
