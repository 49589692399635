import { useState, useEffect, useCallback } from "react";
import * as Plotly from "plotly.js";
import callAPI from "./utils/utils";
import { NeutralButtonComponent } from "../button/ButtonComponent";
import css from "../../pages/home/Home.module.css";
import { Col, Row } from "react-bootstrap";

export const Char = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [days, setDays] = useState("14");

  const fetchData = useCallback(async () => {
    let data = { index: [], price: [] };
    let result;
    try {
      result = await callAPI(
        `https://api.coingecko.com/api/v3/coins/coin-of-the-champions/market_chart?vs_currency=usd&days=${days}`
      );
    } catch (err) {
      console.log(err);
      result = [{ prices: [] }];
    }

    for (const item of result.prices) {
      data.index.push(item[0]);
      data.price.push(item[1]);
    }
    return data;
  }, [days]);

  const initChart = useCallback(
    (data) => {
      let trace_price = {
        name: "Price ($)",
        x: data.index.map((t) => new Date(t)),
        y: data.price,
        xaxis: "x",
        yaxis: "y1",
        type: "scatter",
        marker: { color: "#E4F600", size: 3 },
      };

      let layout = {
        autosize: true,
        height: "100%",
        margin: {
          r: 20,
          t: 35,
          pad: 5,
        },
        showlegend: false,
        xaxis: {
          domain: [1, 1],
          anchor: "y2",
          showgrid: false,
          color: "#e5e8ec",
        },
        yaxis: {
          domain: [0.1, 1],
          hoverformat: ".10f",
          anchor: "x",
          tickformat: days === "1" ? ".8f" : ".7f",
          showgrid: false,
          color: "#e5e8ec",
        },
        yaxis2: {
          showticklabels: false,
          domain: [0, 0.1],
          anchor: "x",
          showgrid: false,
        },
        grid: {
          roworder: "bottom to top",
        },
        plot_bgcolor: "transparent",
        paper_bgcolor: "transparent",
      };
      let config = { responsive: true, displayModeBar: false };
      let series = [trace_price];
      Plotly.newPlot("chart", series, layout, config);
    },
    [days]
  );

  const updateChart = useCallback((data) => {
    let trace_price = {
      x: [data.index.map((t) => new Date(t))],
      y: [data.price],
    };

    Plotly.update("chart", trace_price, {}, 0);
  }, []);

  useEffect(() => {
    fetchData().then((chartData) => {
      setIsLoading(false);
      initChart(chartData);
    });
    const timerID = setInterval(() => {
      fetchData().then((chartData) => {
        updateChart(chartData);
      });
    }, 1000 * 30);
    return () => {
      clearInterval(timerID);
    };
  }, [fetchData, initChart, updateChart]);

  const intervalChartSet = (value) => {
    setDays(value);
  };

  return (
    <div>
      {isLoading ? (
        <h6
          className="value animate__animated animate__flash animate__slow text-center"
          style={{ color: "#E4F600" }}
        >
          loading ...
        </h6>
      ) : (
        <div>
          <Row className={css.legChar}>
            <Col xs={12} md={6} style={{ margin: 0, padding: 0 }}>
              <h1>Chart</h1>
            </Col>
            <Col
              xs={12}
              md={6}
              style={{ margin: 0, padding: 0, width: "auto" }}
            >
              <Row style={{ margin: 0, padding: 0 }}>
                <Col xs={3} style={{ margin: 0, padding: 0 }}>
                  <NeutralButtonComponent
                    className={
                      days === "1" ? css.chartIconSelect : css.chartIcon
                    }
                    onClick={() => intervalChartSet("1")}
                  >
                    24h
                  </NeutralButtonComponent>
                </Col>
                <Col xs={3} style={{ margin: 0, padding: 0 }}>
                  <NeutralButtonComponent
                    className={
                      days === "14" ? css.chartIconSelect : css.chartIcon
                    }
                    onClick={() => intervalChartSet("14")}
                  >
                    14d
                  </NeutralButtonComponent>
                </Col>
                <Col xs={3} style={{ margin: 0, padding: 0 }}>
                  <NeutralButtonComponent
                    className={
                      days === "30" ? css.chartIconSelect : css.chartIcon
                    }
                    onClick={() => intervalChartSet("30")}
                  >
                    1m
                  </NeutralButtonComponent>
                </Col>
                <Col xs={3} style={{ margin: 0, padding: 0 }}>
                  <NeutralButtonComponent
                    className={
                      days === "max" ? css.chartIconSelect : css.chartIcon
                    }
                    onClick={() => intervalChartSet("max")}
                  >
                    max
                  </NeutralButtonComponent>
                </Col>
              </Row>
            </Col>
          </Row>
          <div
            id="chart"
            className="m-0"
            style={{
              background: "rgba(23, 24, 25, 0.7)",
              boxShadow: "5px 5px 50px rgba(0, 0, 0, 0.7)",
              borderRadius: "17px",
            }}
          />
        </div>
      )}
    </div>
  );
};
