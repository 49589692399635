import React, {useState} from "react";
import {useWriteMarketplaceContract} from "../../../../../../customHooks/useWriteMarketplaceContract";
import {useParams} from "react-router-dom";
import {BigNumber} from "@ethersproject/bignumber";
import {ButtonComponent} from "../../../../../../components/button/ButtonComponent";
import ReactLoading from "react-loading";
import css from "../../../../Nft.module.css";
import {Snackbar} from "@mui/material";


interface SendBidProps {
    sellOrders: any;
    textInput: string;
    marketplaceAddress: string;
}

export const SendBid: React.FC<SendBidProps> = ({sellOrders, textInput, marketplaceAddress}) => {
    const params: { id: string } = useParams();
    const tokenId = params.id;
    const [open, setOpen] = useState(true);

    const bigInt18 = 1000000000000000000;

    const {send, state} = useWriteMarketplaceContract(marketplaceAddress, 'bid');

    if (state.status === 'Success' || state.status === 'Exception') {
        setInterval(() => {
            window.location.reload()
        }, 2000);
    }

    const bid = async (amount: string) => {
        let amountToSend = (+amount * bigInt18).toLocaleString('fullwide', {useGrouping: false});

        const bigNumberAmount = BigNumber.from(amountToSend);
        const option = sellOrders.paymentToken === '0x0000000000000000000000000000000000000000' ? {value: bigNumberAmount} : {value: BigNumber.from(0)}
        await send(tokenId, BigNumber.from(amountToSend.toString()), option);
    }

    return (
        <>
            {
                state.status === 'Mining' ?
                    <ReactLoading className={css.loader} width={20}
                                  type={'spinningBubbles'}
                                  color="#E4F600"/>
                    :
                    <ButtonComponent onClick={() => bid(textInput)}
                                     style={{width: '80%'}}
                                     disabled={sellOrders.claimed}>Send</ButtonComponent>
            }

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </>
    );

}
