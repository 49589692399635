import { useState } from "react";
import { useInterval } from "@usedapp/core";
import Axios from 'axios';

export interface ContractDetail {
  market_data: {
    total_volume: Object;
  }
}

export function useContractDetail() {
  const CONTRACT_ADDRESS = '0xbDC3b3639f7AA19e623A4d603A3Fb7Ab20115A91';
  const ASSET_PLATFORM_ID = 'binance-smart-chain';

  const [timeout, setTimeout] = useState(1000);
  const [contractDetails, setContractDetails] = useState(null);

  useInterval(() => {
    Axios.get(`https://api.coingecko.com/api/v3/coins/${ASSET_PLATFORM_ID}/contract/${CONTRACT_ADDRESS}`)
      .then(res => {
        setContractDetails(res.data);
        setTimeout(60000)
      });
  }, timeout);

  return contractDetails as unknown as ContractDetail;
}
