import React from "react";
import {useReadMarketplaceFactoryContract} from "../../../customHooks/useReadMarketplaceFactoryContract";
import {GetCollections} from "./getCollections";

interface GetMarketplaceProps {
    id: number;
}

export const GetMarketplace: React.FC<GetMarketplaceProps> = ({id}) => {
    const marketplace = useReadMarketplaceFactoryContract('marketplaces', [id]);

    return (
        <>
            {!!marketplace.length && <GetCollections marketplace={marketplace} />}
        </>
    )
}
