import React, {useState} from "react";
import {Link} from "react-router-dom";
import {formatUnits} from "ethers/lib/utils";
import axios from "axios";
import {Card, CardContent} from "@mui/material";
import {useReadGenericErc721} from "../../../../../customHooks/useReadGenericErc721";
import {useReadMarketplaceContract} from "../../../../../customHooks/useReadMarketplaceContract";
import css from "../../../Nft.module.css";

interface CollectionViewProps {
    tokenId: number;
    marketplaceAddress: string;
    collectionAddress: string;
}

export interface TokenUriModel {
    image: string;
    name: string;
    description: string;
    attributes: any;
}

export const CollectionView: React.FC<CollectionViewProps> = ({tokenId, marketplaceAddress, collectionAddress}) => {
    const [info, setInfo] = useState<any>();
    const [auctionLive, setAuctionLive] = useState(false);
    const [auction, setAuction] = useState<string>();
    const [ended, setEnded] = useState(false);

    const tokenURI = useReadGenericErc721(collectionAddress, 'tokenURI', [tokenId]);
    const tokenReplace = String(tokenURI).replace("ipfs://", "https://ipfs.io/ipfs/");
    const bids = useReadMarketplaceContract(marketplaceAddress, 'getBids', [tokenId]);

    if (tokenReplace && info === undefined) {
        axios.get(tokenReplace).then(res => {
            const json: TokenUriModel = res.data
            let frm;
            if (json.image.includes("ipfs://")) {
                frm = String(json.image).replace("ipfs://", "https://ipfs.io/ipfs/")
            } else {
                frm = "https://ipfs.io/ipfs/" + String(json.image)
            }
            setInfo({...json, formattedImage: frm})
        })
    }

    const sellOrders = useReadMarketplaceContract(marketplaceAddress, 'sellOrders', [tokenId]);

    if (sellOrders && bids && sellOrders.start && auction === undefined) {
        let now = new Date().getTime()
        let start = sellOrders.start.toString() * 1000
        let end = sellOrders.end.toString() * 1000
        if (now > start && end > now) {
            setAuctionLive(true)
            setAuction(sellOrders.end.toString())
        } else {
            setAuction(sellOrders.end.toString())
        }

        if (end < now) {
            setEnded(true)
        }
    }

    return (
        <div>
            {(info && !!sellOrders.length && !sellOrders.claimed && (+formatUnits(sellOrders.directSalePrice, 18) !== 0 || +formatUnits(sellOrders.basePrice, 18) !== 0)) &&
                <Card sx={{
                    width: '240px',
                    margin: '20px',
                    filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                    borderRadius: '25px'
                }}>
                    <Link className={css.linkStyle}
                          to={{
                              pathname: `/nft/${marketplaceAddress}/${collectionAddress}/${tokenId}`
                          }}>
                        <div style={{
                            textAlign: 'center',
                            height: '240px',
                            width: '240px',
                            backgroundPosition: 'center',
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundImage: `url(${info.formattedImage})`
                        }}/>
                    </Link>
                    <CardContent style={{
                        minHeight: '180px',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between'
                    }}>
                        <div style={{marginBottom: '30px'}}>
                            <h1 style={{color: 'white', fontSize: '20px', lineHeight: '35px'}}>{info.name}</h1>
                        </div>

                        {+formatUnits(sellOrders.basePrice, 18) !== 0 && <>
                            {auctionLive ?
                                <div style={{display: 'flex', flexDirection: 'row', gap: '10px'}}>
                                    <div style={{
                                        width: '15px',
                                        height: '15px',
                                        backgroundColor: 'green',
                                        borderRadius: '50px'
                                    }}/>
                                    <h3 style={{fontSize: '15px', color: '#d0f3cd'}}>Auction live</h3>
                                </div>
                                :
                                <>
                                    {
                                        ended ?
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '10px'
                                            }}>
                                                <div style={{
                                                    width: '15px',
                                                    height: '15px',
                                                    backgroundColor: 'red',
                                                    borderRadius: '50px'
                                                }}/>
                                                <h3 style={{fontSize: '15px', color: '#f3bcbc'}}>Auction
                                                    ended</h3>
                                            </div> :
                                            <div style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                gap: '10px'
                                            }}>
                                                <div style={{
                                                    width: '15px',
                                                    height: '15px',
                                                    backgroundColor: 'orange',
                                                    borderRadius: '50px'
                                                }}/>
                                                <h3 style={{fontSize: '15px', color: '#f6cea1'}}>Coming
                                                    soon</h3>
                                            </div>
                                    }
                                </>
                            }
                        </>
                        }

                        {
                            (+formatUnits(sellOrders.directSalePrice, 18) !== 0) &&
                            <div style={{marginTop: '-10px'}}>
                                <h3 style={{fontSize: '15px', color: 'rgba(255,255,255,0.74)'}}>Direct buy
                                    available</h3>
                            </div>
                        }

                    </CardContent>
                </Card>}
        </div>
    );
}
