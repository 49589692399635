import React from "react";
import {GetCollectionInfoNft} from "./getCollectionInfoNft";
import {GetCollectionInfoNoNft} from "./getCollectionInfoNoNft";

interface GetCollectionInfoProps {
    collection: string;
    marketplace: string;
    name: string;
    totalSupply: string;
}

export const GetCollectionInfo: React.FC<GetCollectionInfoProps> = ({collection, marketplace, name, totalSupply}) => {

    return (
        <>
            {(totalSupply === '0') ?
                <GetCollectionInfoNoNft collection={collection} marketplace={marketplace} name={name} /> :
                <GetCollectionInfoNft collection={collection} marketplace={marketplace} name={name}/>
            }

        </>
    )
}
