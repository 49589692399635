import React, {useState} from "react";
import {Link} from "react-router-dom";
import {useEthers} from "@usedapp/core";
import {ButtonComponent} from "../../components/button/ButtonComponent";
import {useReadMarketplaceFactoryContract} from "../../customHooks/useReadMarketplaceFactoryContract";
import {ChooseCollection} from "../choose-collection/choose-collection";
import {CheckNetwork} from "../../components/network/checkNetwork";
import css from "./myNft.module.css";

export const MyNft: React.FC = () => {
    const {account} = useEthers();
    const [ownerFactory, setOwnerFactory] = useState<boolean>();

    const marketplaceFactoryOwner = useReadMarketplaceFactoryContract('hasRole', ['0x0000000000000000000000000000000000000000000000000000000000000000', account]);

    if (typeof marketplaceFactoryOwner === 'boolean' && ownerFactory === undefined) {
        setOwnerFactory(marketplaceFactoryOwner)
    }
    return (
        <>
            <CheckNetwork/>

            {ownerFactory && <Link className={css.load} to={'/collection'}>
                <ButtonComponent>Create collection</ButtonComponent>
            </Link>}

            <ChooseCollection />
        </>
    );
}
