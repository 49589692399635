import { useEffect, useState } from "react";
import axios from "axios";

export const getTokenPrice = async (contract: string) => {
  const addr = contract.toLowerCase();
  const url = `https://coins.llama.fi/prices/current/bsc:${addr}`;
  const data = await axios(url, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });

  const price = data.data.coins[`bsc:${addr}`]?.price.toString();
  return typeof price === "string" ? price : undefined;
};

export function useReadPrice(address: string) {
  const [price, setPrice] = useState<string | undefined>(undefined);

  useEffect(() => {
    function getPrice() {
      getTokenPrice(address).then((res) => {
        setPrice(res);
      });
    }

    getPrice();
  }, [address, price]);

  return price;
}
