import React, {useState} from "react";
import {Link} from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {ButtonComponent} from "../../../../components/button/ButtonComponent";
import {useReadGenericErc721} from "../../../../customHooks/useReadGenericErc721";
import {LoadNftView} from "./loadNftView/loadNftView";
import css from "../../loadNft.module.css";

interface LoadNftBuilderProps {
    marketplace: string;
    collection:string;
}

export const LoadNftBuilder: React.FC<LoadNftBuilderProps> = ({marketplace, collection}) => {
    const name = useReadGenericErc721(collection, 'name');
    const [confirmMint, setConfirmMint] = useState(false);

    const mintEnd = (end: boolean) => {
        setConfirmMint(end);
    }

    const confirm = () => {
        setConfirmMint(false);
        window.location.href = '/profile';
    }

    return (
        <div style={{padding: '0', width: '100%'}}>
            <div className={css.containerColor}>
                <div className={css.back}>
                    <Link className={css.linkStyle} to={{pathname: `/profile`}}>
                        <ButtonComponent>
                            <KeyboardBackspaceIcon/>
                        </ButtonComponent>
                    </Link>
                    <h2 style={{marginBottom: '-2px'}}>Create NFT</h2>
                </div>

                <div className={css.card}>
                    {!confirmMint ?
                        <>
                            <div className={css.selectBox}>
                                <div className={'text-start justify-content-start'}
                                     style={{padding: '20px 0', fontSize: 15, fontStretch: 'expanded', fontWeight: 'bolder'}}>
                                    Collection: {name}
                                </div>
                            </div>

                            <div className={css.boxCard}>
                                <LoadNftView collectionAddress={collection} mintEnd={mintEnd}/>
                            </div>
                        </> :
                        <div className={css.boxColumn}>
                            <div className={'text-start justify-content-start'}
                                 style={{padding: '20px 0', fontSize: 15, fontStretch: 'expanded', fontWeight: 'bolder'}}>
                                Congratulations, your NFT has been created successfully
                            </div>

                            <ButtonComponent style={{width: '150px'}} onClick={confirm}>Confirm</ButtonComponent>
                        </div>
                    }
                </div>
            </div>
        </div>
    );
};
