import {FarmWithdraw} from "../farmWithdraw/farmWithdraw";
import {FarmSend} from "../farmSend/farmSend";
import {FarmData} from "../../../../helpers/manageFarmingData";
import css from "./farmTextInput.module.css"
import {Row} from "react-bootstrap";
import {BigNumber} from "@ethersproject/bignumber";
import {formatEther} from "@ethersproject/units";
import {FarmEmergencyWithdraw} from "../farmEmergencyWithdraw/farmEmergencyWithdraw";

interface FarmContractProps {
    farmContract: FarmData;
}

export const FarmTextInput: React.FC<FarmContractProps> = ({farmContract}) => {
    let pendingReward = 0;

    if (farmContract.pendingReward) {
        let pendingRewardBigNumber: BigNumber = BigNumber.from(farmContract.pendingReward.toString())
        let reward = formatEther(pendingRewardBigNumber._hex)
        pendingReward = +Number(reward)
    }

    return (
        <Row style={{margin: 0, padding: 0}}>
            <FarmSend farmContract={farmContract}/>
            <div className={css.withdraw}>
                {
                    pendingReward > 0 ?
                        <FarmWithdraw farmContract={farmContract}/> :
                        <FarmEmergencyWithdraw farmContract={farmContract}/>
                }
            </div>
        </Row>
    );
};
