import {ethers, providers} from "ethers";

export function readTotalBurn() {
    const contractAddress = '0xbDC3b3639f7AA19e623A4d603A3Fb7Ab20115A91';

    const provider = new providers.JsonRpcProvider("https://bsc-dataseed.binance.org/");

    const contractManager = new ethers.Contract(contractAddress, [
        'function totalBurn() public view returns (uint256)'
    ], provider)

    return contractManager.totalBurn();
}