import React, {useState} from "react";
import ReactLoading from "react-loading";
import {useLocation} from "react-router-dom";
import ArrowBack from "@material-ui/icons/ArrowBack";
import ArrowForward from "@material-ui/icons/ArrowForward";
import {ButtonComponent} from "../../../../components/button/ButtonComponent";
import {CollectionView} from "./collectionView/collectionView";
import {NftUpdate} from "./nftUpdate";
import css from "../../../collection/collectionCreate.module.css";

interface NftContentProps {
    marketplaceAddress: string;
    collectionAddress: string;
}

export const NftContent: React.FC<NftContentProps> = ({marketplaceAddress, collectionAddress}) => {
    const {search} = useLocation();
    const [currentPage, setCurrentPage] = useState<number>(+search.slice(6) || 1);
    const [listId, setListId] = useState<number[]>([]);

    const setUpdatedList = (id: number) => {
        let newArray = listId;
        newArray.push(id)
        setListId(newArray)
    }

    if (listId.length && Math.ceil(listId.length / 50) < currentPage) {
        setCurrentPage(1)
        window.location.href = `?page=${1}`
    }

    const previewsPage = () => {
        let count = currentPage - 1

        if (count > 0) {
            setCurrentPage(count)
        } else {
            setCurrentPage(1)
        }

        window.location.href = `?page=${currentPage - 1}`
    }

    const nextPage = () => {
        let count = currentPage + 1

        if (count === 0) {
            setCurrentPage(1)
        } else if (count > 0) {
            setCurrentPage(count)
        }

        window.location.href = `?page=${currentPage + 1}`
    }

    return (
        <>
            {
                !listId.length ?
                    <>
                        <ReactLoading className={css.confirmBtn}
                                      width={35}
                                      type={'spinningBubbles'}
                                      color="#E4F600"/>
                        <NftUpdate marketplaceAddress={marketplaceAddress}
                                   collectionAddress={collectionAddress}
                                   setUpdatedList={setUpdatedList}/>
                    </>
                    :
                    <>
                        {
                            !!listId.length &&
                            <div style={{
                                padding: '0',
                                width: '100%',
                                display: 'flex',
                                flexWrap: 'wrap'
                            }}>

                                {listId.slice((currentPage - 1) * 50, 50 * currentPage).map((id, index) => (
                                    <CollectionView tokenId={id} marketplaceAddress={marketplaceAddress}
                                                    collectionAddress={collectionAddress}
                                                    key={index}/>))}

                            </div>
                        }

                        {!!listId.length &&
                            <div style={{
                                display: 'flex',
                                flexDirection: 'row',
                                alignItems: 'flex-start',
                                gap: '30px',
                                placeContent: 'space-evenly',
                                marginTop: '50px',
                                color: '#FFFFFF'
                            }}>
                                <ButtonComponent disabled={currentPage <= 1} onClick={previewsPage}>
                                    <ArrowBack/>
                                </ButtonComponent>

                                <p className={css.labelPage}>page {currentPage} of {Math.ceil(listId.length / 50)}</p>

                                <ButtonComponent disabled={currentPage > (listId.length / 50)} onClick={nextPage}>
                                    <ArrowForward/>
                                </ButtonComponent>
                            </div>
                        }
                    </>
            }
        </>
    );
};
