import React, { useState } from "react";
import { Snackbar } from "@material-ui/core";
import { ButtonComponent } from "../../../components/button/ButtonComponent";
import { useWriteFarmingContract } from "../../../customHooks/useWriteFarmingContract";
import { BigNumber } from "@ethersproject/bignumber";
import ReactLoading from "react-loading";
import css from "./farmSend/farmSend.module.css";
import {useEthers} from "@usedapp/core";

interface ISendFarm {
    farmAmount: string;
    contractId: number;
}

export const SendFarm: React.FC<ISendFarm> = ({ farmAmount, contractId }) => {
    const {account} = useEthers();
    const { state, send } = useWriteFarmingContract("deposit");
    const [open, setOpen] = useState(true);
    const bigInt18 = 1000000000000000000;

    if (state.status === 'Success') {
        setTimeout(() => {
            window.location.reload()
        }, 5000);
    }

    async function sendFarmTx() {
        let amountToSend = (+farmAmount * bigInt18).toLocaleString('fullwide', {useGrouping:false});
        await send(contractId, BigNumber.from(amountToSend.toString()));
        setOpen(true);
    }

    return (
        <div>
            {state.status === 'Mining' ?
                <ReactLoading className={css.marginLeft30} width={25} type={'spinningBubbles'} color="#E4F600" />
                :
                <ButtonComponent onClick={sendFarmTx} disabled={!account}>Farm</ButtonComponent>
            }
            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </div>
    );
};
