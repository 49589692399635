import { useContractCall } from "@usedapp/core";
import { Interface } from '@ethersproject/abi';
import abi from '../assets/abi/testnet/lptoken.abi.json';

export function useReadLPToken(method: string, address: string, args?: any[]) {
    if (args === undefined) {
        args = [];
    }
    const abiInterface = new Interface(abi);

    const contractResult = useContractCall({
        abi: abiInterface,
        address,
        method,
        args
    });

    if (method === 'allowance') {
        return contractResult === undefined ? [] : contractResult[0];
    }

    return contractResult === undefined ? [] : contractResult;
}
