import {useBlockNumber} from "@usedapp/core";
import {FarmBuilder} from "./components/FarmBuilder";
import FarmingHelper from "../../helpers/farmingHelper";
import css from './Farm.module.css';
import moment from "moment";
import {CheckNetwork} from "../../components/network/checkNetwork";
import {GetEnv} from "../../environments/environment";
import {CardComponent} from "../../components/cards/cardComponent/CardComponent";

export const Farm: React.FC = () => {
    const farmContract = GetEnv()[`REACT_APP_FARMING_CONTRACT_ADDRESS_1`];
    const endBlock = farmContract ? FarmingHelper.readFarmingContract(`${GetEnv()[`REACT_APP_FARMING_CONTRACT_ADDRESS_1`]}`,'endBlock') : 0
    const currentBlock = useBlockNumber()
    let blocksRemaining = 0;
    const now = Math.floor(Date.now() / 1000);
    if (currentBlock && endBlock) {
        blocksRemaining = +endBlock - currentBlock;
    }
    const end = now + (blocksRemaining / 10 * 30)

    return (
        <div>
            <CheckNetwork/>
            <div className={css.container}>
                <div className={css.title}>
                    {farmContract && currentBlock && endBlock
                        ? <div style={{textAlign: 'center'}}>
                            <span>will end in {+endBlock - currentBlock} blocks ~ {moment(end * 1000).fromNow()}</span>
                        </div>
                        : <div></div>
                    }
                </div>
                <FarmBuilder/>

                <CardComponent>
                    <div className={css.faq}>
                        <h1 className={css.faqTitle}>FARMING</h1>
                        <p className={css.description}>Farming is a way to earn COC using your crypto.</p>
                        <p className={css.description}>Basically, you add your funds to liquidity through smart
                            contract.</p>
                        <p className={css.description}>As a reward for your service, you receive commissions in COC.</p>
                        <p className={css.description}>You can withdraw both your LP and rewards whenever you want.</p>
                        <p className={css.description}>In this guide you will find everything you need to know:</p>
                        <p className={css.description}><a href="https://academy.binance.com/en/articles/what-is-yield-farming-in-decentralized-finance-defi">https://academy.binance.com/en/articles/what-is-yield-farming-in-decentralized-finance-defi</a></p>
                        <p className={css.description}>The following token pairs are available: (Variable APR)</p>
                        <p className={css.description}>COC/BNB</p>
                        <p className={css.description}>COC/CAKE</p>
                        <p className={css.description}>COC/BUSD</p>
                    </div>
                </CardComponent>

                <CardComponent>
                    <div className={css.faq}>
                        <h1 className={css.faqTitle}>FARMING TUTORIAL</h1>
                        <p className={css.description}>1- Open Pancakeswap.finance from your wallet's browser /
                            dapps</p>
                        <p className={css.description}>2- Go to the Trade section and select Liquidity.</p>
                        <p className={css.description}>3- Click Add Liquidity</p>
                        <p className={css.description}>4- Create a swap pair with COC and one between BNB / BUSD / CAKE,
                            proceed by clicking 'Supply'</p>
                        <p className={css.description}>5- Once you have received your LP Token, copy this link and paste
                            it into your wallet browser <a href="https://dapp.coinofchampions.com">https://dapp.coinofchampions.com</a></p>
                        <p className={css.description}>6- Click on the top left menù and select Farming</p>
                        <p className={css.description}>7- Click on the top right and ConnectWallet</p>
                        <p className={css.description}>8- Choose which pair to use and enter the LP Token amount.
                            (Without decimals numbers)</p>
                        <p className={css.description}>9- Click APPROVE, pay the fee and wait 15-20 second until the FARM
                            button appear.. Click on it and pay the 2nd fee</p>
                    </div>
                </CardComponent>
            </div>
        </div>
    );
}
