import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import axios from "axios";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import { ButtonComponent } from "../../../../components/button/ButtonComponent";
import { useReadGenericErc721 } from "../../../../customHooks/useReadGenericErc721";
import { AuctionInputComponentSelect } from "../../../../components/input/AuctionInputComponent";
import { ApproveNft } from "./approveNft";
import { TokenUriModel } from "../cardMyNft";
import css from "../../../loadNft/loadNft.module.css";

export const SellNft: React.FC = () => {
  let history = useHistory();
  const params: { marketplace: string; collection: string; id: string } =
    useParams();
  const tokenId = params.id;
  const collection = params.collection;
  const marketplace = params.marketplace;
  const [startingDate, setStartingDate] = useState<any>("");
  const [closingDate, setClosingDate] = useState<any>("");
  const [priceImport, setPriceImport] = useState<number>();
  const [priceImport2, setPriceImport2] = useState<number>();
  const [info, setInfo] = useState<any>();

  const tokenURI = useReadGenericErc721(collection, "tokenURI", [tokenId]);
  const tokenReplace = String(tokenURI).replace(
    "ipfs://",
    "https://ipfs.io/ipfs/"
  );

  if (tokenReplace && info === undefined) {
    axios.get(tokenReplace).then((res) => {
      const json: TokenUriModel = res.data;
      let frm;
      if (json.image.includes("ipfs://")) {
        frm = String(json.image).replace("ipfs://", "https://ipfs.io/ipfs/");
      } else {
        frm = "https://ipfs.io/ipfs/" + String(json.image);
      }
      setInfo({ ...json, formattedImage: frm });
    });
  }

  const [value, setValue] = React.useState("COC");

  const handleChangeAmount = (event: any) => {
    const newPrice = Number(event.target.value);
    setPriceImport(newPrice);
  };

  const handleChangeAmount2 = (event: any) => {
    const newPrice = Number(event.target.value);
    setPriceImport2(newPrice);
  };

  const handleChangeStartDate = (event: any) => {
    setStartingDate(event.target.value);
  };

  const handleChangeClosingDate = (event: any) => {
    setClosingDate(event.target.value);
  };

  return (
    <>
      <ButtonComponent onClick={history.goBack}>
        <KeyboardBackspaceIcon />
      </ButtonComponent>

      <div className={css.card}>
        <div className={css.boxCard}>
          <div className={css.boxColumn}>
            {info && (
              <div
                style={{
                  textAlign: "center",
                  height: "200px",
                  width: "200px",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                  backgroundImage: `url(${info.formattedImage})`,
                  borderRadius: "18px",
                }}
              />
            )}
            <div className={"d-flex"} style={{ margin: "20px 0 0 0" }}>
              <p style={{ marginLeft: 0, fontSize: 20, fontWeight: "bold" }}>
                Set the price
              </p>
            </div>

            <div className={"d-flex"}>
              <div style={{ flexDirection: "row", display: "flex" }}>
                <AuctionInputComponentSelect
                  onChange={handleChangeAmount}
                  placeholder={"0.00"}
                  className={css.inputWeek1}
                  type={"text"}
                />
                <div
                  style={{
                    margin: "10px 0",
                    width: 2,
                    backgroundColor: "#E4F600",
                  }}
                />
                <select
                  name="cars"
                  id="cars"
                  className={css.selectInput}
                  value={value}
                  onChange={(e) => {
                    setValue(e.target.value);
                  }}
                >
                  <option value="COC">COC</option>
                  <option value="BNB">BNB</option>
                  <option value="USDT">USDT</option>
                </select>
              </div>
            </div>
          </div>

          <div className={css.boxColumn1}>
            <div className={"d-flex"} style={{ margin: "10px 0 0 0" }}>
              <p style={{ marginLeft: 0, fontSize: 20, fontWeight: "bold" }}>
                Set the auction
              </p>
            </div>

            <div className={"d-flex"}>
              <div style={{ margin: "0" }}>
                <div
                  className={"text-start justify-content-start"}
                  style={{
                    margin: "10px 0 0 0",
                    padding: "0",
                    fontSize: 15,
                    fontStretch: "expanded",
                    fontWeight: "normal",
                  }}
                >
                  Minimum price
                </div>

                <div className={"d-flex"}>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <AuctionInputComponentSelect
                      onChange={handleChangeAmount2}
                      placeholder={"0.00"}
                      className={css.inputWeek1}
                      type={"text"}
                    />
                    <div
                      style={{
                        margin: "10px 0",
                        width: 2,
                        backgroundColor: "#E4F600",
                      }}
                    />
                    <select
                      name="cars"
                      id="cars"
                      className={css.selectInput}
                      value={value}
                      onChange={(e) => {
                        setValue(e.target.value);
                      }}
                    >
                      <option value="COC">COC</option>
                      <option value="BNB">BNB</option>
                      <option value="USDT">USDT</option>
                    </select>
                  </div>
                </div>

                <div
                  className={"text-start justify-content-start"}
                  style={{
                    margin: "5px 0 0 0",
                    padding: "0",
                    fontSize: 15,
                    fontStretch: "expanded",
                    fontWeight: "normal",
                  }}
                >
                  Starting date
                </div>

                <div className={"d-flex"}>
                  <div>
                    <input
                      className={css.auctionInput}
                      type="text"
                      onChange={handleChangeStartDate}
                      placeholder={"DD/MM/YYYY-HH:mm"}
                    />
                  </div>
                </div>

                <div
                  className={"text-start justify-content-start"}
                  style={{
                    margin: "5px 0 0 0",
                    padding: "0",
                    fontSize: 15,
                    fontStretch: "expanded",
                    fontWeight: "normal",
                  }}
                >
                  Closing date
                </div>

                <div className={"d-flex"}>
                  <div>
                    <input
                      className={css.auctionInput}
                      type="text"
                      onChange={handleChangeClosingDate}
                      placeholder={"DD/MM/YYYY-HH:mm"}
                    />
                  </div>
                </div>
              </div>
            </div>
            <ApproveNft
              marketplaceAddress={marketplace}
              collectionAddress={collection}
              tokenId={+tokenId}
              priceToBuy={priceImport}
              basePrice={priceImport2}
              tokenAddress={value}
              start={startingDate}
              end={closingDate}
            />
          </div>
        </div>
      </div>
    </>
  );
};
