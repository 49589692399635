import { CHAIN_NAMES, useEthers } from "@usedapp/core";
import {
  AppBar,
  Box,
  Button,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import MenuIcon from "@material-ui/icons/Menu";
import { NetworkButtonComponent } from "../button/NetworkButtonComponent";
import logo from "./../../assets/icons/coc-yellowtransparent.png";
import css from "./Toolbar.module.css";
import React, { useState } from "react";
import styled from "styled-components";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  Link,
} from "react-router-dom";
import { Stake } from "../../pages/stake/Stake";
import { Farm } from "../../pages/farm/Farm";
import { Home } from "../../pages/home/Home";
import { Nft } from "../../pages/nft/Nft";
import { SwitchNetwork } from "../network/switchNetwork";
import { NETWORK_ICON } from "../network/utils/networkList";
import { Vest } from "../../pages/vest/Vest";
import { ButtonComponentWallet } from "../button/ButtonComponentWallet";
import { DetailsView } from "../../pages/nft/components/nftBuilder/collectionView/detailsView/detailsView";
import { LoadNft } from "../../pages/loadNft/loadNft";
import { MyNft } from "../../pages/myNft/myNft";
import { Login } from "../../pages/loadNft/components/login/login";
import { SellNft } from "../../pages/myNft/components/sellNft/sellNft";
import { CollectionCreate } from "../../pages/collection/collectionCreate";
import { CheckAccount } from "../network/checkAccount";
import { ChooseCollection } from "../../pages/choose-collection/choose-collection";
import { MyNftBuilder } from "../../pages/myNft/components/myNftBuilder";
import { OldFarm } from "../../pages/oldFarm/OldFarm";
import { useReadPrice } from "../../customHooks/useReadPrice";

const drawerWidth = 300;

export const ToolbarComponent: React.FC = () => {
  const [location, setLocation] = useState(window.location.pathname);

  const { activateBrowserWallet, account, deactivate, chainId } = useEthers();
  const CONTRACT_ADDRESS = "0xbDC3b3639f7AA19e623A4d603A3Fb7Ab20115A91";

  const tp = useReadPrice(CONTRACT_ADDRESS);
  const tokenPrice = tp ? Number(tp) : 0.00000000000001;

  const [menuOpp, setMenuOpp] = React.useState<null | HTMLElement>(null);
  const openOpp = Boolean(menuOpp);

  const updateLocation = (route: string) => {
    setLocation(route);
  };

  const OppClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenuOpp(event.currentTarget);
  };

  const OppClose = () => {
    setMenuOpp(null);
  };

  const [openNetwork, setOpenNetwork] = React.useState<boolean>(false);

  const updateModal = (data: boolean) => {
    setOpenNetwork(data);
  };

  const font = {
    fontFamily: "Montserrat",
  };

  return (
    <Router>
      <Box sx={{ display: "flex" }}>
        <CheckAccount />
        <CssBaseline />
        <AppBar
          position="fixed"
          className={css.bar}
          style={{ zIndex: 1201, justifyContent: "center" }}
        >
          <Toolbar style={{ padding: "0 5%" }}>
            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              <img alt="logo" style={{ width: 70 }} src={logo} />
            </Box>

            <Box
              sx={{
                display: { xs: "block", sm: "block", md: "none" },
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <Button
                aria-expanded={openOpp ? "true" : undefined}
                onClick={OppClick}
                style={{
                  fontSize: 16,
                  fontFamily: "Chakra Petch, sans-serif",
                  fontWeight: "bold",
                  color: "white",
                }}
              >
                <MenuIcon />
              </Button>
              <MenuList
                id="basic-menu"
                anchorEl={menuOpp}
                onClose={OppClose}
                open={openOpp}
                style={{ margin: "20px auto" }}
              >
                <div
                  style={{ margin: 0, padding: 0, minWidth: 130 }}
                  className={location.includes("home") ? css.selectedMenu : ""}
                >
                  <Link
                    className={css.routeBtn}
                    to={"/home"}
                    style={{
                      textAlign: "left",
                      flexDirection: "row",
                      margin: 0,
                      padding: "10px 0",
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "white",
                    }}
                    onClick={() => updateLocation("home")}
                  >
                    <MenuItem className={css.txt}>Home</MenuItem>
                  </Link>
                </div>
                <div
                  style={{ margin: 0, padding: 0, minWidth: 130 }}
                  className={location.includes("stake") ? css.selectedMenu : ""}
                >
                  <Link
                    className={css.routeBtn}
                    to={"/stake"}
                    style={{
                      textAlign: "left",
                      flexDirection: "row",
                      margin: 0,
                      padding: "10px 0",
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "white",
                    }}
                    onClick={() => updateLocation("stake")}
                  >
                    <MenuItem className={css.txt}>Staking</MenuItem>
                  </Link>
                </div>
                <div
                  style={{ margin: 0, padding: 0, minWidth: 130 }}
                  className={location.includes("vest") ? css.selectedMenu : ""}
                >
                  <Link
                    className={css.routeBtn}
                    to={"/vest"}
                    style={{
                      textAlign: "left",
                      flexDirection: "row",
                      margin: 0,
                      padding: "10px 0",
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "white",
                    }}
                    onClick={() => updateLocation("vest")}
                  >
                    <MenuItem className={css.txt}>Vesting</MenuItem>
                  </Link>
                </div>
                <div
                  style={{ margin: 0, padding: 0, minWidth: 130 }}
                  className={
                    location.includes("old-farm") ? css.selectedMenu : ""
                  }
                >
                  <Link
                    className={css.routeBtn}
                    to={"/old-farm"}
                    style={{
                      textAlign: "left",
                      flexDirection: "row",
                      margin: 0,
                      padding: "10px 0",
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "white",
                    }}
                    onClick={() => updateLocation("old-farm")}
                  >
                    <MenuItem className={css.txt}>Old Farming</MenuItem>
                  </Link>
                </div>
                <div
                  style={{ margin: 0, padding: 0, minWidth: 130 }}
                  className={
                    location.includes("farming") ? css.selectedMenu : ""
                  }
                >
                  <Link
                    className={css.routeBtn}
                    to={"/farming"}
                    style={{
                      textAlign: "left",
                      flexDirection: "row",
                      margin: 0,
                      padding: "10px 0",
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "white",
                    }}
                    onClick={() => updateLocation("farm")}
                  >
                    <MenuItem className={css.txt}>Farming</MenuItem>
                  </Link>
                </div>
                <div
                  style={{ margin: 0, padding: 0, minWidth: 130 }}
                  className={location.includes("nft") ? css.selectedMenu : ""}
                >
                  <Link
                    className={css.routeBtn}
                    to={"/nft/choose-collection"}
                    style={{
                      textAlign: "left",
                      flexDirection: "row",
                      margin: 0,
                      padding: "10px 0",
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "white",
                    }}
                    onClick={() => updateLocation("nft")}
                  >
                    <MenuItem className={css.txt}>Nft</MenuItem>
                  </Link>
                </div>
                <div
                  style={{ margin: 0, padding: 0, minWidth: 130 }}
                  className={
                    location.includes("profile") ? css.selectedMenu : ""
                  }
                >
                  <Link
                    className={css.routeBtn}
                    to={"/profile"}
                    style={{
                      textAlign: "left",
                      flexDirection: "row",
                      margin: 0,
                      padding: " 0",
                      cursor: "pointer",
                      textDecoration: "none",
                      color: "white",
                    }}
                    onClick={() => updateLocation("profile")}
                  >
                    <MenuItem className={css.txt}>My Nft</MenuItem>
                  </Link>
                </div>
              </MenuList>
              <img alt="logo" style={{ width: 45 }} src={logo} />
            </Box>

            <Box sx={{ flexGrow: 1 }} />

            <Box sx={{ display: { xs: "none", md: "flex" } }}>
              {openNetwork && (
                <SwitchNetwork chainId={chainId} updateModal={updateModal} />
              )}
              {chainId && (
                <NetworkButtonComponent
                  disableRipple={true}
                  onClick={() => {
                    setOpenNetwork(!openNetwork);
                  }}
                >
                  <img
                    src={NETWORK_ICON[chainId]}
                    width="35px"
                    style={{ borderRadius: "20px", padding: "5px" }}
                    alt=""
                  />
                  {CHAIN_NAMES[chainId]}
                </NetworkButtonComponent>
              )}
              {tokenPrice && (
                <Typography className={css.balance}>
                  <TrendingUpIcon className={css.trendIcon} />
                  <span style={{ marginLeft: "12px" }}>
                    ${tokenPrice.toFixed(10)}
                  </span>
                </Typography>
              )}
            </Box>

            <Box>
              <ButtonComponentWallet
                variant="contained"
                onClick={() =>
                  account ? deactivate() : activateBrowserWallet()
                }
              >
                <span>
                  {account ? (
                    <p className={css.accountText}>
                      {" "}
                      {`${account?.slice(0, 4)}...${account?.slice(-3)}`}{" "}
                    </p>
                  ) : (
                    <p className={css.accountText}>Connect wallet</p>
                  )}
                </span>
              </ButtonComponentWallet>
            </Box>
          </Toolbar>
        </AppBar>

        <Drawer
          variant="permanent"
          sx={{
            backgroundColor: "#171819",
            width: drawerWidth,
            flexShrink: 0,
            display: { xs: "none", md: "flex" },
            [`& .MuiDrawer-paper`]: {
              height: "auto",
              width: drawerWidth,
              boxSizing: "border-box",
            },
          }}
        >
          <Toolbar style={{ marginTop: 150 }} />
          <Box
            sx={{
              margin: "0 50px",
              padding: "20px 0 20px 30px",
              border: "1px solid #E4F600",
              borderRadius: "17px",
            }}
          >
            <div>
              <Link
                to={"/home"}
                style={{ color: "white", textDecoration: "none" }}
              >
                <ListItem
                  button
                  style={{ margin: "20px 0" }}
                  className={location.includes("home") ? css.selected : ""}
                  onClick={() => updateLocation("home")}
                >
                  <ListItemText
                    primaryTypographyProps={{ style: font }}
                    style={{ padding: "0 20px" }}
                    primary={"Home"}
                  />
                </ListItem>
              </Link>

              <Link
                to={"/stake"}
                style={{ color: "white", textDecoration: "none" }}
              >
                <ListItem
                  button
                  style={{ margin: "20px 0" }}
                  className={location.includes("stake") ? css.selected : ""}
                  onClick={() => updateLocation("stake")}
                >
                  <ListItemText
                    primaryTypographyProps={{ style: font }}
                    style={{ padding: "0 20px" }}
                    primary={"Staking"}
                  />
                </ListItem>
              </Link>

              <Link
                to={"/vest"}
                style={{ color: "white", textDecoration: "none" }}
              >
                <ListItem
                  button
                  style={{ margin: "20px 0" }}
                  className={location.includes("vest") ? css.selected : ""}
                  onClick={() => updateLocation("vest")}
                >
                  <ListItemText
                    primaryTypographyProps={{ style: font }}
                    style={{ padding: "0 20px" }}
                    primary={"Vesting"}
                  />
                </ListItem>
              </Link>

              <Link
                to={"/old-farm"}
                style={{ color: "white", textDecoration: "none" }}
              >
                <ListItem
                  button
                  style={{ margin: "20px 0" }}
                  className={location.includes("old-farm") ? css.selected : ""}
                  onClick={() => updateLocation("old-farm")}
                >
                  <ListItemText
                    primaryTypographyProps={{ style: font }}
                    style={{ padding: "0 20px" }}
                    primary={"Old Farming"}
                  />
                </ListItem>
              </Link>

              <Link
                to={"/farming"}
                style={{ color: "white", textDecoration: "none" }}
              >
                <ListItem
                  button
                  style={{ margin: "20px 0" }}
                  className={location.includes("farming") ? css.selected : ""}
                  onClick={() => updateLocation("farming")}
                >
                  <ListItemText
                    primaryTypographyProps={{ style: font }}
                    style={{ padding: "0 20px" }}
                    primary={"Farming"}
                  />
                </ListItem>
              </Link>

              <Link
                to={"/nft/choose-collection"}
                style={{ color: "white", textDecoration: "none" }}
              >
                <ListItem
                  button
                  style={{ margin: "20px 0" }}
                  className={location.includes("nft") ? css.selected : ""}
                  onClick={() => updateLocation("nft/choose-collection")}
                >
                  <ListItemText
                    primaryTypographyProps={{ style: font }}
                    style={{ padding: "0 20px" }}
                    primary={"NFT"}
                  />
                </ListItem>
              </Link>
              <Link
                to={"/profile"}
                style={{ color: "white", textDecoration: "none" }}
              >
                <ListItem
                  button
                  style={{ margin: "20px 0" }}
                  className={location.includes("profile") ? css.selected : ""}
                  onClick={() => updateLocation("profile")}
                >
                  <ListItemText
                    primaryTypographyProps={{ style: font }}
                    style={{ padding: "0 20px" }}
                    primary={"My NFT"}
                  />
                </ListItem>
              </Link>
            </div>
          </Box>
        </Drawer>

        <Box
          component="main"
          sx={{
            backgroundColor: "#171819",
            flexGrow: 1,
            p: 3,
            paddingTop: "150px",
            minHeight: "100vh",
          }}
        >
          <Switch>
            <Switch>
              <Route path="/stake" exact component={Stake} />
              <Route path="/vest" exact component={Vest} />
              <Route path="/old-farm" exact component={OldFarm} />
              <Route path="/farming" exact component={Farm} />
              <Route path="/home" exact component={Home} />
              <Route
                path="/load-new/:marketplace/:collection"
                exact
                component={LoadNft}
              />
              <Route path="/profile" exact component={MyNft} />
              <Route
                path="/profile/:marketplace/:collection"
                exact
                component={MyNftBuilder}
              />
              <Route
                path="/profile/:marketplace/:collection/:id"
                exact
                component={SellNft}
              />
              <Route path="/login" exact component={Login} />
              <Route path="/collection" exact component={CollectionCreate} />
              <Route
                path="/nft/choose-collection"
                exact
                component={ChooseCollection}
              />
              <Route
                path="/nft/:marketplace/:collection"
                exact
                component={Nft}
              />
              <Route
                path="/nft/:marketplace/:collection/:id"
                exact
                component={DetailsView}
              />
              <Route path="/404" render={() => <div>404</div>} />
              <Redirect exact from="/" to="/home" />
              <Redirect exact from="/**" to="/404" />
            </Switch>
          </Switch>
        </Box>
      </Box>
    </Router>
  );
};
const MenuList = styled(Menu)`
    margin:0,
    padding:0 20px,
    .MuiList-root {
        list-style: none;
        margin: 0;
        padding: 0;
        position: relative;
        padding: 10px 0 10px 0;
        border-radius:0;
        outline: 0;
        background-color: black;
    }
`;
