import React, {useState} from "react";
import {BigNumber} from "@ethersproject/bignumber";
import {formatUnits} from "ethers/lib/utils";
import {Snackbar} from "@mui/material";
import ReactLoading from "react-loading";
import {useWriteMarketplaceContract} from "../../../../../../customHooks/useWriteMarketplaceContract";
import {ButtonComponent} from "../../../../../../components/button/ButtonComponent";
import css from "../../../../Nft.module.css";

interface DirectBuyProps {
    tokenId: string;
    sellOrders: any;
    disable: boolean;
    marketplaceAddress: string;
}

export const DirectBuy: React.FC<DirectBuyProps> = ({tokenId, sellOrders, disable, marketplaceAddress}) => {
    const bigInt18 = 1000000000000000000;
    const [open, setOpen] = useState(true);
    const {send, state} = useWriteMarketplaceContract(marketplaceAddress, 'buy');

    if (state.status === 'Success') {
        setTimeout(() => {
            window.location.reload()
        }, 5000);
    }

    const buy = async () => {
        let amount = formatUnits(sellOrders.directSalePrice, 18);
        let amountToSend = (+amount * bigInt18).toLocaleString('fullwide', {useGrouping: false});
        await send(tokenId, {value: BigNumber.from(amountToSend)});
    }

    return (
        <>
            {
                state.status === 'Mining' ?
                    <ReactLoading className={css.loader} width={25} type={'spinningBubbles'} color="#E4F600"/>
                    :
                    <ButtonComponent onClick={buy} style={{width: '80%'}}
                                     disabled={sellOrders.claimed || +formatUnits(sellOrders.directSalePrice, 18) === 0 || disable}>Buy</ButtonComponent>
            }

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </>
    )

}
