import { useContractCall } from "@usedapp/core";
import { Interface } from '@ethersproject/abi';
import abi from '../assets/abi/testnet/MarketplaceFactory.json';
import {GetEnv} from "../environments/environment";

export function useReadMarketplaceFactoryContract(method: string, args?: any[]) {
    if (args === undefined) {
        args = [];
    }
    const CONTRACT_ADDRESS = `${GetEnv()[`REACT_APP_NFT_MARKETPLACE_FACTORY_ADDRESS`]}`;
    const abiInterface = new Interface(abi);


    const contractResult = useContractCall({
        abi: abiInterface,
        address: CONTRACT_ADDRESS,
        method,
        args
    });

    return contractResult === undefined ? [] : contractResult[0];
}
