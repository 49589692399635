import { useContractFunction } from "@usedapp/core";
import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";
import {GetEnv} from "../environments/environment";

export function useWriteLPToken(methodName: string, lpTokenAddress: string) {
  const contractAbi = new utils.Interface(
    require(`../assets/abi/testnet/lptoken.abi.json`)
  );

  const contractAddress = lpTokenAddress ? lpTokenAddress :`${GetEnv()[`REACT_APP_LP_TOKEN_CONTRACT_ADDRESS`]}`;

  const contract = new Contract(contractAddress, contractAbi);

  return useContractFunction(contract, methodName);
}

