import React, {useState} from "react";
import ReactLoading from "react-loading";
import {Snackbar} from "@mui/material";
import {VestData} from "../../../../helpers/manageVestingData";
import VestingHelper from "../../../../helpers/vestingHelper";
import {StakeInputComponent} from "../../../../components/input/StakeInputComponent";
import {ButtonComponent} from "../../../../components/button/ButtonComponent";
import css from "./vestingTable.module.css";
import {useEthers} from "@usedapp/core";

interface VestingTextInputProps {
    vestContract: VestData;
}

export const VestingTextInput: React.FC<VestingTextInputProps> = ({vestContract}) => {
    const { account } = useEthers();
    const [amount, setAmount] = useState("");
    const [days, setDays] = useState("");
    const {state, send} = VestingHelper.writeVestingContract("deposit", vestContract.id);
    const [open, setOpen] = useState(true);
    const bigInt18 = 1000000000000000000;

    if (state.status === 'Success') {
        setTimeout(() => {
            window.location.reload()
        }, 2000);
    }

    async function sendStakeTx() {
        let amountToSend = (+amount * bigInt18).toLocaleString('fullwide', {useGrouping: false});

        await send(amountToSend, days);
    }

    return (
        <div>
            <div className={css.inputBox}>
                <StakeInputComponent
                    type="text"
                    value={amount}
                    onChange={(e) => setAmount(e.target.value)}
                    placeholder="Insert amount"
                    readOnly={state.status === 'Mining'}
                />

                <StakeInputComponent
                    type="text"
                    value={days}
                    onChange={(e) => setDays(e.target.value)}
                    placeholder="Insert days"
                    readOnly={state.status === 'Mining'}
                />

                {
                    state.status === 'Mining' ?
                        <ReactLoading className={css.loading} width={25} type={'spinningBubbles'} color="#E4F600"/>
                        :
                        <ButtonComponent onClick={sendStakeTx} disabled={!account}>Deposit</ButtonComponent>
                }
            </div>

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={2000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </div>
    );
}
