import React, {useEffect, useState} from "react";
import ReactLoading from "react-loading";
import {useEthers} from "@usedapp/core";
import {BigNumber} from "@ethersproject/bignumber";
import {Snackbar} from "@mui/material";
import {ButtonComponent} from "../../../../../components/button/ButtonComponent";
import {StakeInputComponent, StakeInputComponentSm} from "../../../../../components/input/StakeInputComponent";
import {useReadCOCContract} from "../../../../../customHooks/useReadCOCContract";
import {useWriteCOC} from "../../../../../customHooks/useWriteCOC";
import {StakeData} from "../../../../../helpers/manageStakingData";
import {SendStake} from "../sendStake/sendStake";
import css from "./stakeTextInput.module.css"
import {Col, Row} from "react-bootstrap";
import moment from "moment";
import {environment, GetEnv} from "../../../../../environments/environment";

interface ITextInputProps {
    stakeContract: StakeData;
}

export const StakeTextInput: React.FC<ITextInputProps> = ({stakeContract}) => {
    const {account} = useEthers();
    const [textInput, setTextInput] = useState("");
    const {state, send} = useWriteCOC("approve");
    const [approved, setApproved] = useState(false);
    const [open, setOpen] = useState(true);
    const [timeLeft, setTimeLeft] = useState<number>();
    const [timeLeft1, setTimeLeft1] = useState<number>();
    const bigInt18 = 1000000000000000000;
    const max256 = BigNumber.from("115792089237316195423570985008687907853269984665640564039457584007913129639935");
    const allowance = useReadCOCContract("allowance", [
        account,
        `${GetEnv()[`REACT_APP_STAKING_CONTRACT_ADDRESS_${stakeContract.id}` as keyof environment]}`
    ]);

    const sendApproveTx = async () => {
        await send(
            `${GetEnv()[`REACT_APP_STAKING_CONTRACT_ADDRESS_${stakeContract.id}` as keyof environment]
            }`,
            max256
        );
        setOpen(true)
    }

    const getApproved = () => {
        let amount = Number(textInput)
        let amountToSend = (amount * bigInt18).toLocaleString('fullwide', {useGrouping: false});

        allowance.gte(
            BigNumber.from(amountToSend)
        ) ?
            setApproved(true) :
            setApproved(false)
    }

    if (stakeContract.timeLeft > 0 && timeLeft === undefined) {
        setTimeLeft(stakeContract.timeLeft)
    }

    if (textInput && allowance !== undefined && timeLeft1 === undefined) {
        getApproved();
        setTimeLeft1(1000);
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            if (timeLeft && timeLeft > 0) {
                setTimeLeft(timeLeft - 1);
            }
        }, 1000);
        return () => clearTimeout(timer);
    });

    return (
        <div>
            <div className={css.send}>
                <Row style={{margin: 0, padding: 0, width: '80%'}}>
                    <Col xs={12} sm={12} md={12} lg={12} xl={11} style={{margin: 0, padding: 0}}>
                        <Row style={{margin: 0, padding: 0}}>
                            <Col className={'d-none d-sm-block'} xs={12} sm={12} md={8} lg={12} xl={8}
                                 style={{margin: 0, padding: 0}}>
                                <StakeInputComponent
                                    type="text"
                                    value={textInput}
                                    onChange={(e) => setTextInput(e.target.value)}
                                    placeholder="Insert amount"
                                    readOnly={state.status === 'Mining'}
                                />
                            </Col>
                            <Col className={'d-block d-sm-none'} xs={12} sm={12} md={12} lg={12} xl={8}
                                 style={{margin: 0, padding: 0}}>
                                <StakeInputComponentSm
                                    type="text"
                                    value={textInput}
                                    onChange={(e) => setTextInput(e.target.value)}
                                    placeholder="Insert amount"
                                    readOnly={state.status === 'Mining'}
                                />
                            </Col>
                            <Col xs={12} sm={12} md={4} lg={12} xl={4}
                                 style={{margin: 0, padding: '-1px', alignItems: "center", justifyContent: "center"}}>
                                {(state.status === "Success" || approved) ? (
                                    <SendStake stakeIndex={stakeContract.id} stakeAmount={textInput}/>
                                ) : (
                                    state.status === 'Mining' ?
                                        <ReactLoading className={css.marginLeft30} width={25} type={'spinningBubbles'}
                                                      color="#E4F600"/>
                                        :
                                        <ButtonComponent onClick={sendApproveTx}  className={css.enableBtn }>Approve</ButtonComponent>
                                )}
                            </Col>
                        </Row>
                    </Col>
                    <Col className={'d-none d-sm-block'} xs={12} sm={12} md={12} lg={12} xl={1}
                         style={{margin: 0, padding: 0}}>
                        <div className={css.timeLeft}>
                            <span style={{fontSize: '10px', marginTop: '-6px', marginBottom: '-8px'}}>will end</span>
                            <span>{moment(stakeContract.timeLeft ? stakeContract.timeLeft  : 0).fromNow()}</span>
                        </div>
                    </Col>
                    <Col className={'d-block d-sm-none'} xs={12} sm={12} md={12} lg={12} xl={1}
                         style={{margin: 0, textAlign: "center", padding: 0}}>
                        <div className={css.timeLeft} style={{margin: '0 auto'}}>
                            <span style={{fontSize: '10px', margin: '0 auto 0 auto'}}>will end</span>
                            <span>{moment(stakeContract.timeLeft ? stakeContract.timeLeft : 0).fromNow()}</span>
                        </div>
                    </Col>
                </Row>
            </div>

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </div>
    );
};
