import { utils } from "ethers";
import { useContractFunction } from "@usedapp/core";
import { Contract } from "@ethersproject/contracts";
import {GetEnv} from "../environments/environment";

export function useWriteFarmingContract(
    methodName: string
) {
    const contracAbi = new utils.Interface(
        require(`./../assets/abi/testnet/farming.abi.json`)
    );

    const contractAddress = `${GetEnv()[`REACT_APP_FARMING_CONTRACT_ADDRESS_1`]
        }`;

    const contract = new Contract(contractAddress, contracAbi);

    return useContractFunction(contract, methodName);
}

export function useWriteFarmingContractOld(
    methodName: string
) {
    const contracAbi = new utils.Interface(
        require(`./../assets/abi/testnet/farming.abi.json`)
    );

    const contractAddress = `${GetEnv()[`REACT_APP_FARMING_CONTRACT_ADDRESS_2`]
    }`;

    const contract = new Contract(contractAddress, contracAbi);

    return useContractFunction(contract, methodName);
}
