import {CardContent} from "@material-ui/core";
import {ListItem} from "@mui/material";
import {Col, Row} from "react-bootstrap";
import {BigNumber} from "@ethersproject/bignumber";
import {formatEther} from "@ethersproject/units";
import {FarmData} from "../../../../helpers/manageFarmingData";
import {FarmCardComponent, FarmCardComponentSm} from "../../../../components/cards/cardComponent/FarmCardComponent";
import {OldFarmTextInput} from "../oldFarmTextInput/oldFarmTextInput";
import css from './oldFarmItem.module.css'

interface FarmProps {
    farmData: FarmData;
}

export const OldFarmItem: React.FC<FarmProps> = ({farmData}) => {
    let lpDeposited = 0;
    let pendingReward = 0;

    if (farmData.lpDeposited) {
        let lpDepositedBigNumber: BigNumber = BigNumber.from(farmData.lpDeposited.toString())
        let lp = formatEther(lpDepositedBigNumber._hex)
        lpDeposited = +Number(lp).toFixed(4);
    }

    if (farmData.pendingReward) {
        let pendingRewardBigNumber: BigNumber = BigNumber.from(farmData.pendingReward.toString())
        let reward = formatEther(pendingRewardBigNumber._hex)
        pendingReward = +Number(reward).toFixed(4);
    }

    return (
        <ListItem className={css.listItem}>
            <FarmCardComponentSm className={'d-block d-sm-block d-md-none'}>
                <CardContent className={css.cardContent}>
                    <div className={'text-start justify-content-start'}
                         style={{padding: '10px', fontStretch: 'expanded', fontWeight: 'bolder'}}>{farmData.name}</div>
                    <div className={css.content}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={css.contentBox}>
                            <OldFarmTextInput farmContract={farmData} />
                            <Row className={css.box}>
                                <Col xs={12} sm={12} md={8} lg={8} xl={8} className={css.columnInfo}
                                     style={{margin: '5px 0', padding: 0}}>
                                    <Row style={{margin: 0, padding: 0}}>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{margin: '5px 0', padding: 0}}>
                                            <div className={css.myStake}>
                                                <b className={css.textapy}>Total LP TOKEN deposited</b>
                                            </div>
                                        </Col>
                                        <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{margin: '5px 0', padding: 0}}>
                                            <div className={css.myStake}>
                                                <span>{lpDeposited} LP TOKEN</span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} sm={12} md={4} lg={4} xl={4} style={{margin: '5px 0', padding: 0}}>
                                    <div className={css.apy}>
                                        <b className={css.textapy}>APR</b>
                                        <span>{farmData.APR}%</span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                    <div className={css.content}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={css.contentBox}>
                            <Row className={css.box}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{margin: '5px 0', padding: 0}}>
                                    <div className={css.myStake}>
                                        <b className={css.textapy}>Pending reward</b>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{margin: '5px 0', padding: 0}}>
                                    <div className={css.myStake}>
                                        <span>
                                            {pendingReward} COC
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </CardContent>
            </FarmCardComponentSm>

            <FarmCardComponent className={'d-none d-sm-none d-md-block'}>
                <CardContent className={css.cardContent}>
                    <div style={{padding: '10px', fontStretch: 'expanded', fontWeight: 'bolder'}}>{farmData.name}</div>
                    <div className={css.content}>
                        <Col xs={12} sm={12} md={12} lg={12} xl={12} className={css.contentBox}>
                            <OldFarmTextInput farmContract={farmData}/>

                            <Row className={css.box}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{margin: '5px 0'}}>
                                    <div className={css.myStake}>
                                        <b className={css.textapy}>Total LP TOKEN deposited</b>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{margin: '5px 0'}}>
                                    <div className={css.myStake}>
                                        <span>
                                            {lpDeposited} LP TOKEN
                                        </span>
                                    </div>
                                </Col>
                            </Row>

                            <Row className={css.box}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{margin: '5px 0'}}>
                                    <div className={css.myStake}>
                                        <b className={css.textapy}>APR</b>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{margin: '5px 0'}}>
                                    <div className={css.myStake}>
                                        <span>
                                            {farmData.APR}%
                                        </span>
                                    </div>
                                </Col>
                            </Row>

                            <Row className={css.box}>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{margin: '5px 0'}}>
                                    <div className={css.myStake}>
                                        <b className={css.textapy}>Pending reward</b>
                                    </div>
                                </Col>
                                <Col xs={12} sm={12} md={6} lg={6} xl={6} style={{margin: '5px 0'}}>
                                    <div className={css.myStake}>
                                        <span>
                                            {pendingReward} COC
                                        </span>
                                    </div>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </CardContent>
            </FarmCardComponent>
        </ListItem>
    );
}
