import React, {useState} from "react";
import ReactLoading from "react-loading";
import {Link} from "react-router-dom";
import {useEthers} from "@usedapp/core";
import {Snackbar} from "@mui/material";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {ButtonComponent} from "../../components/button/ButtonComponent";
import {FarmInputComponent} from "../../components/input/FarmInputComponent";
import {CollectionCreateRead} from "./components/collectionCreateRead";
import {useReadCollectionFactory} from "../../customHooks/useReadCollectionFactory";
import {useWriteCollectionFactory} from "../../customHooks/useWriteCollectionFactory";
import css from './collectionCreate.module.css';

export const CollectionCreate: React.FC = () => {
    const {account} = useEthers();
    const [nameCollection, setNameCollection] = useState("");
    const [symbolCollection, setSymbolCollection] = useState("");
    const [collectionsCounter, setCollectionsCounter] = useState<number>();

    const collectionsCounterRead = useReadCollectionFactory('collectionsCounter');

    if (collectionsCounterRead && collectionsCounterRead._isBigNumber && collectionsCounter === undefined) {
        const count = Number(collectionsCounterRead.toLocaleString());
        setCollectionsCounter(count);
    }

    const [open, setOpen] = useState(true);

    const {send, state} = useWriteCollectionFactory("deployCollection")

    const confirm = () => {
        send(account, nameCollection, symbolCollection, 'ipfs://')
    }

    if (state.status === 'Exception') {
        setInterval(() => {
            window.location.reload()
        }, 2000);
    }

    return (
        <>
            <div className={css.collectionContainer}>
                <div className={css.back}>
                    <Link className={css.linkStyle} to={{pathname: `/profile`}}>
                        <ButtonComponent>
                            <KeyboardBackspaceIcon/>
                        </ButtonComponent>
                    </Link>
                    <h2 style={{marginBottom: '-2px'}}>Create collection</h2>
                </div>

                <div className={css.boxCard}>
                    {state.status !== 'Success' ?
                        <>
                            <div className={css.label}>Name</div>
                            <FarmInputComponent
                                type="text"
                                value={nameCollection}
                                onChange={(e) => setNameCollection(e.target.value)}
                                placeholder="Name"
                            />

                            <div className={css.label}>Symbol</div>
                            <FarmInputComponent
                                type="text"
                                value={symbolCollection}
                                onChange={(e) => setSymbolCollection(e.target.value)}
                                placeholder="Symbol"
                            />

                            {
                                state.status === 'Mining' ?
                                    <ReactLoading className={css.confirmBtn}
                                                  width={21}
                                                  type={'spinningBubbles'}
                                                  color="#E4F600"/>
                                    :
                                    <div className={css.confirmBtn}>
                                        <ButtonComponent onClick={confirm}>Create</ButtonComponent>
                                    </div>
                            }
                        </> :
                        (!!collectionsCounter && <CollectionCreateRead counter={collectionsCounter}/>)
                    }
                </div>
            </div>

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </>
    );
}
