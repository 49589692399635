import React, {useState} from "react";
import {useEthers} from "@usedapp/core";
import axios from "axios";
import {Card, CardContent} from "@mui/material";
import {ButtonComponent} from "../../../components/button/ButtonComponent";
import {useReadGenericErc721} from "../../../customHooks/useReadGenericErc721";
import css from "../../nft/Nft.module.css";
import {useReadMarketplaceContract} from "../../../customHooks/useReadMarketplaceContract";

interface CardMyNftProps {
    tokenId: number;
    marketplaceAddress: string;
    collectionAddress: string;
}

export interface TokenUriModel {
    image: string;
    name: string;
    description: string;
    attributes: any;
}

export const CardMyNft: React.FC<CardMyNftProps> = ({marketplaceAddress, collectionAddress, tokenId}) => {
    const {account} = useEthers()
    const [info, setInfo] = useState<any>();
    const marketplaceOwner = useReadMarketplaceContract(marketplaceAddress, 'owner');
    const tokenURI = useReadGenericErc721(collectionAddress, 'tokenURI', [tokenId]);
    const ownerOf = useReadGenericErc721(collectionAddress, 'ownerOf', [tokenId]);
    const tokenReplace = String(tokenURI).replace("ipfs://", "");

    if (tokenReplace && info === undefined) {
        let url = `https://ipfs.io/ipfs/${tokenReplace}`
        axios.get(url).then(res => {
            const json: TokenUriModel = res.data;
            let frm;
            if (json.image.includes("ipfs://")) {
                frm = String(json.image).replace("ipfs://", "https://ipfs.io/ipfs/")
            } else {
                frm = "https://ipfs.io/ipfs/" + String(json.image)
            }
            setInfo({...json, formattedImage: frm})
        })
    }

    const goToSell = () => {
        window.location.href = `/profile/${marketplaceAddress}/${collectionAddress}/${tokenId}`;
    }

    const urlify = (text: string) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a href="' + url + '">' + url + '</a>';
        })
    }

    return (
        <div>
            {(ownerOf === account && info) && <Card sx={{
                width: '240px',
                margin: '20px',
                filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                borderRadius: '25px'
            }}>
                <div className={css.linkStyle}>
                    <div style={{
                        textAlign: 'center',
                        height: '240px',
                        width: '240px',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: 'cover',
                        backgroundImage: `url(${info.formattedImage})`
                    }}/>
                </div>

                <CardContent>
                    <div style={{marginBottom: '30px'}}>
                        <h1 style={{color: 'white', fontSize: '20px', lineHeight: '35px'}}>{info.name}</h1>
                    </div>

                    <div>
                        <p style={{fontSize: '15px'}} dangerouslySetInnerHTML={{__html: urlify(info.description)}}/>
                    </div>

                    {(ownerOf === account && marketplaceOwner === account) &&
                        <ButtonComponent style={{width: '150px'}} onClick={goToSell}>Sell</ButtonComponent>}
                </CardContent>
            </Card>}
        </div>
    );

}
