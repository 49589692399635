import detectEthereumProvider from '@metamask/detect-provider';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import {ButtonComponent} from '../button/ButtonComponent';
import css from './token.module.css'
import {GetEnv} from "../../environments/environment";

export const AddTokenPanel = () => {
    const token = {
        tokenName: 'Coin of Champions',
        tokenSymbol: 'COC',
        tokenDecimals: 18,
        tokenAddress: `${GetEnv()[`REACT_APP_COC_CONTRACT_ADDRESS`]}`,
        tokenImage: 'https://s2.coinmarketcap.com/static/img/coins/200x200/14292.png',
        tokenNet: `${GetEnv()[`REACT_APP_CHAIN_ID`]}`,
    }

    const addToken = async () => {
        const provider: any = await detectEthereumProvider()
        if (provider) {
            provider.sendAsync({
                method: 'metamask_watchAsset',
                params: {
                    'type': 'ERC20',
                    'options': {
                        'address': token.tokenAddress,
                        'symbol': token.tokenSymbol,
                        'decimals': token.tokenDecimals,
                        'image': token.tokenImage,
                    },
                },
                id: Math.round(Math.random() * 100000),
            })
        }
    }

    return (
        <div className={css.values}>
            <header className={css.header}>
                <img src={token.tokenImage} className={css.logo} alt="Coin"/>
                <h1 className={css.title}>{token.tokenName}</h1>
            </header>
            <Table>
                <TableBody>
                    <TableRow>
                        <TableCell>Symbol</TableCell>
                        <TableCell>{token.tokenSymbol}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell>Decimals</TableCell>
                        <TableCell>{token.tokenDecimals}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>

            <div style={{marginTop: '10px'}}>
                <ButtonComponent
                    onClick={addToken}
                >
                    Add token
                </ButtonComponent>
            </div>
        </div>
    )

}
