/* eslint-disable react-hooks/rules-of-hooks */
import { useContractCall } from "@usedapp/core";
import { utils } from "ethers";
import { BigNumber } from "@ethersproject/bignumber";
import { ContractDetail } from "../customHooks/useContractDetail";
import { useReadLPToken } from "../customHooks/useReadLPToken";
import { formatUnits } from "@ethersproject/units";
import { GetEnv } from "../environments/environment";
import { useReadPrice } from "../customHooks/useReadPrice";

export default class FarmingHelper {
  static farmContractLength(farmContract: string): number {
    if (!farmContract) {
      return 0;
    }

    let farmIndex: number = 3;
    const poolLength = Number(
      BigInt(FarmingHelper.readFarmingContract(farmContract, "poolLength"))
    );
    if (poolLength) {
      farmIndex = poolLength;
    }

    return farmIndex;
  }

  static readFarmingContract(
    farmContract: string,
    methodName: string,
    args?: any[]
  ) {
    if (args === undefined) {
      args = [];
    }

    const contractResult = useContractCall({
      abi: new utils.Interface(
        require(`./../assets/abi/testnet/farming.abi.json`)
      ),
      address: farmContract,
      method: methodName,
      args: args,
    });

    if (contractResult !== undefined) {
      return contractResult?.length > 1 ? contractResult : contractResult[0];
    } else {
      return [];
    }
  }

  static calculateAPR(
    farmContract: string,
    contractDetail: ContractDetail,
    pendingReward: BigNumber,
    lpTokenAddress: string,
    tokenAddress: string,
    index: number,
    rewardPerBlock: number
  ) {
    let cocRewardYear = rewardPerBlock * 28800 * 365;

    const cocPriceString = useReadPrice(
      GetEnv()[`REACT_APP_COC_CONTRACT_ADDRESS`]
    );
    const tokenPairPriceString = useReadPrice(tokenAddress);
    const reservedObj = useReadLPToken("getReserves", lpTokenAddress);
    const totalSupplyLiquidityPool: any = useReadLPToken(
      "totalSupply",
      lpTokenAddress
    );
    const balanceOfToken: any = useReadLPToken("balanceOf", lpTokenAddress, [
      farmContract,
    ]);

    let totalAPR = "0";

    if (
      typeof cocPriceString === "string" &&
      typeof tokenPairPriceString === "string" &&
      reservedObj.length > 0 &&
      totalSupplyLiquidityPool.length > 0
    ) {
      let totalSupplyFormattedNumber = Number(
        formatUnits(totalSupplyLiquidityPool[0], 18)
      ).toFixed(0);

      let cocPriceParsed = parseFloat(cocPriceString);

      let tokenPairPriceParsed = parseFloat(tokenPairPriceString);

      // calc coc generated
      let cocGeneratedValue = cocRewardYear * cocPriceParsed * 0.33;

      // calc value reserve
      let decimals = BigNumber.from(10000000000).mul(100000000);
      let reserveCOC =
        index === 1
          ? reservedObj[0]
            ? reservedObj[0].div(decimals)
            : 0
          : reservedObj[1]
          ? reservedObj[1].div(decimals)
          : 0;
      let reserveTokenPair =
        index === 1
          ? reservedObj[1]
            ? reservedObj[1].div(decimals)
            : 0
          : reservedObj[0]
          ? reservedObj[0].div(decimals)
          : 0;

      let reserveCocValue = reserveCOC * cocPriceParsed;
      let reserveTokenValue = reserveTokenPair * tokenPairPriceParsed;

      // calc value liquidity pool
      let totalValueLiquidityPool = reserveCocValue + reserveTokenValue;

      // calc value liquidity token
      let valueLiquidityPoolParsed = parseFloat(
        totalValueLiquidityPool.toString()
      );
      let totalSupplyParsed = parseFloat(totalSupplyFormattedNumber.toString());
      let tokenPoolValue = valueLiquidityPoolParsed / totalSupplyParsed;

      let tokenPoolValueTot = balanceOfToken[0] * tokenPoolValue;

      // final calc apr
      let apr = (cocGeneratedValue / tokenPoolValueTot) * 100;
      totalAPR = apr.toLocaleString("fullwide", { useGrouping: false });
    }

    return totalAPR;
  }

  static calculateAPROld(
    farmContract: string,
    contractDetail: ContractDetail,
    pendingReward: BigNumber,
    lpTokenAddress: string,
    tokenAddress: string,
    index: number,
    rewardPerBlock: number
  ) {
    let cocRewardYear = rewardPerBlock * 28800 * 365;
    const cocPriceString = useReadPrice(
      GetEnv()[`REACT_APP_COC_CONTRACT_ADDRESS`]
    );
    const tokenPairPriceString = useReadPrice(tokenAddress);
    const reservedObj = useReadLPToken("getReserves", lpTokenAddress);
    const totalSupplyLiquidityPool: any = useReadLPToken(
      "totalSupply",
      lpTokenAddress
    );
    const balanceOfToken: any = useReadLPToken("balanceOf", lpTokenAddress, [
      farmContract,
    ]);

    let totalAPR = "0";

    if (
      typeof cocPriceString === "string" &&
      typeof tokenPairPriceString === "string" &&
      reservedObj.length > 0 &&
      totalSupplyLiquidityPool.length > 0
    ) {
      let totalSupplyFormattedNumber = Number(
        formatUnits(totalSupplyLiquidityPool[0], 18)
      ).toFixed(0);

      let cocPriceParsed = parseFloat(cocPriceString);

      let tokenPairPriceParsed = parseFloat(tokenPairPriceString);

      // calc coc generated
      let cocGeneratedValue = cocRewardYear * cocPriceParsed * 0.33;

      // calc value reserve
      let decimals = BigNumber.from(10000000000).mul(100000000);
      let reserveCOC =
        index === 2
          ? reservedObj[0]
            ? reservedObj[0].div(decimals)
            : 0
          : reservedObj[1]
          ? reservedObj[1].div(decimals)
          : 0;
      let reserveTokenPair =
        index === 2
          ? reservedObj[1]
            ? reservedObj[1].div(decimals)
            : 0
          : reservedObj[0]
          ? reservedObj[0].div(decimals)
          : 0;

      let reserveCocValue = reserveCOC * cocPriceParsed;
      let reserveTokenValue = reserveTokenPair * tokenPairPriceParsed;

      // calc value liquidity pool
      let totalValueLiquidityPool = reserveCocValue + reserveTokenValue;

      // calc value liquidity token
      let valueLiquidityPoolParsed = parseFloat(
        totalValueLiquidityPool.toString()
      );
      let totalSupplyParsed = parseFloat(totalSupplyFormattedNumber.toString());
      let tokenPoolValue = valueLiquidityPoolParsed / totalSupplyParsed;

      let tokenPoolValueTot = balanceOfToken[0] * tokenPoolValue;

      // final calc apr
      let apr = (cocGeneratedValue / tokenPoolValueTot) * 100;
      totalAPR = apr.toLocaleString("fullwide", { useGrouping: false });
    }

    return totalAPR;
  }
}
