import React, {useState} from "react";
import css from './login.module.css'
import axios from "axios";
import {ButtonComponent} from "../../../../components/button/ButtonComponent";
import {GetEnvAPI} from "../../../../environments/environmentAPI";

export const Login: React.FC = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");

    const token = localStorage.getItem('admin_token') || '';
    if (token) {
        window.location.href = "/load-new"
    }

    const login = async () => {
        await axios
            .post(`${GetEnvAPI().apiUrl}/admin/loginUser`, {
                email: email,
                password: password
            })
            .then((res) => {
                localStorage.setItem('admin_token', res.data.jwtUserToken);
                window.location.href = "/load-new"
            })
            .catch((err) => {
                console.error(err);
            });
    }

    return (
        <div className={css.containerColor}>
            <div className={css.loginContainer}>
                <h1>Login</h1>

                <p>Authentication needed to load a new NFT</p>

                <div>
                    <div className={'d-flex'}>
                        <input className={css.formInput}
                               type="text"
                               onChange={(e) => setEmail(e.target.value)}
                               placeholder={'Email'}
                        />
                    </div>

                    <div className={'d-flex'}>
                        <input className={css.formInput}
                               type="password"
                               onChange={(e) => setPassword(e.target.value)}
                               placeholder={'Password'}
                        />
                    </div>

                    <ButtonComponent disabled={email === '' || password === ''}
                                     onClick={login}>
                        Login
                    </ButtonComponent>
                </div>
            </div>

        </div>
    )
}
