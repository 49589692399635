import React, {useState} from "react";
import {useEthers} from "@usedapp/core";
import {Snackbar} from "@mui/material";
import {BigNumber} from "@ethersproject/bignumber";
import {formatUnits} from "ethers/lib/utils";
import ReactLoading from "react-loading";
import {useReadCOCContract} from "../../../../customHooks/useReadCOCContract";
import {useWriteCOC} from "../../../../customHooks/useWriteCOC";
import {VestingTextInput} from "./vestingTextInput";
import {ButtonComponent} from "../../../../components/button/ButtonComponent";
import VestingHelper from "../../../../helpers/vestingHelper";
import {VestData} from "../../../../helpers/manageVestingData";
import css from "./vestingTable.module.css";
import {environment, GetEnv} from "../../../../environments/environment";
import {Col, Row} from "react-bootstrap";

interface TableVestingActionProps {
    vestContract: VestData;
}

export const VestingAction: React.FC<TableVestingActionProps> = ({vestContract}) => {
    const {account} = useEthers();
    const [enabled, setEnabled] = useState<boolean>()
    const [totalReward, setTotalReward] = useState("0")
    const [totalRewards, setTotalRewards] = useState<number>()
    const [open, setOpen] = useState(true);
    const {state, send} = useWriteCOC("approve");
    const max256 = BigNumber.from("115792089237316195423570985008687907853269984665640564039457584007913129639935");
    const locks = VestingHelper.totalRewards(vestContract.locksIds, vestContract.id);
   /*
    const allowance = useReadCOCContract("allowance", [
        account,
        `${GetEnv()[`REACT_APP_VESTING_CONTRACT_ADDRESS_${vestContract.id}` as keyof environment]}`
    ]);

    if (allowance._isBigNumber && enabled === undefined) {
        setEnabled(allowance.gt(BigNumber.from(0)))
    }
    */

    const sendApproveTx = async () => {
        await send(
            `${GetEnv()[`REACT_APP_VESTING_CONTRACT_ADDRESS_${vestContract.id}` as keyof environment]}`,
            max256
        );
        setOpen(true)
    }

    if (locks.length && totalRewards === undefined) {
        setTotalRewards(1)
        let totR = 0
        for (let i = 0; i < locks.length; i++) {
            totR = totR + +(+formatUnits(locks[i].reward, 18) * +formatUnits(locks[i].amount, 18) * +locks[i].period).toFixed(2)
        }
        setTotalReward(totR.toFixed(10).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1'))
    }

    return (
        <>
            <div className={css.cards}>
                <div id={css.cardsOpen}>
                   <Row style={{margin:0,padding:0}}>
                       <Col xs={12} sm={6} style={{margin:0,padding:0}}>
                           <div id={css.cardsOpen}>
                               <span>TOTAL COC DEPOSITED</span>
                               <div className={css.profit}>
                                   <span>{formatUnits(vestContract.deposit, 18)}</span>
                               </div>
                           </div>
                       </Col>
                       <Col xs={12} sm={6} style={{margin:0,padding:0}}>
                           <div id={css.cardsOpen}>
                               <span>TOTAL COC PROFIT</span>
                               <div className={css.profit}>
                                   <span>{totalReward}</span>
                               </div>
                           </div>
                       </Col>
                   </Row>
                </div>
                <div id={css.cardsOpen}>
                    <span style={{margin: '10px 0'}}>Deposit COC</span>
                    {(state.status === "Success") ? (
                        <VestingTextInput vestContract={vestContract}/>
                    ) : (
                        state.status === 'Mining' ?
                            <ReactLoading className={css.loading} width={21} type={'spinningBubbles'}
                                          color="#E4F600"/>
                            :
                            <ButtonComponent onClick={sendApproveTx} disabled={!account}>Approve</ButtonComponent>
                    )}
                </div>
            </div>

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={2000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </>
    );
}
