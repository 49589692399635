import React from "react";
import {useReadGenericErc721} from "../../../customHooks/useReadGenericErc721";
import {MyNftUpdateDetail} from "./myNftUpdateDetails";

interface NftContentProps {
    marketplaceAddress: string;
    collectionAddress: string;
    setUpdatedList: any;
}

export const MyNftUpdate: React.FC<NftContentProps> = ({marketplaceAddress, collectionAddress, setUpdatedList}) => {
    const totalSupply = useReadGenericErc721(collectionAddress, 'totalSupply');

    let numbers: number[] = [];
    if (totalSupply && totalSupply > 0) {
        for (let i = 0; i < totalSupply; i++) {
            numbers.push(i)
        }
    }

    return (
        <>
            {
                !!numbers.length && <>
                    {numbers.map((id, index) => (
                        <MyNftUpdateDetail tokenId={id} marketplaceAddress={marketplaceAddress}
                                           collectionAddress={collectionAddress} key={index}
                                           setUpdatedList={setUpdatedList}/>))}
                </>
            }
        </>
    );
};
