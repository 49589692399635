import {useState} from "react";
import {useEthers} from "@usedapp/core";
import {BigNumber} from "@ethersproject/bignumber";
import {formatEther} from "@ethersproject/units";
import {Slider, Snackbar} from "@mui/material";
import {makeStyles} from "@material-ui/core/styles";
import {Col, Row} from "react-bootstrap";
import ReactLoading from "react-loading";
import {FarmData} from "../../../../helpers/manageFarmingData";
import {SendOldFarm} from "../sendOldFarm";
import {useWriteLPToken} from "../../../../customHooks/useWriteLPToken";
import {useReadLPToken} from "../../../../customHooks/useReadLPToken";
import {FarmInputComponent, FarmInputComponentSm} from "../../../../components/input/FarmInputComponent";
import {ButtonComponent} from "../../../../components/button/ButtonComponent";
import css from "./oldFarmSend.module.css"
import {GetEnv} from "../../../../environments/environment";

interface FarmContractProps {
    farmContract: FarmData;
}

export const OldFarmSend: React.FC<FarmContractProps> = ({farmContract}) => {
    const {account} = useEthers();
    const [textInput, setTextInput] = useState("");
    const [timeLeft, setTimeLeft] = useState<number>();
    const {state, send} = useWriteLPToken("approve", farmContract.lpTokenAddress);
    const allowance = useReadLPToken("allowance", farmContract.lpTokenAddress, [
        account,
        `${GetEnv()[`REACT_APP_FARMING_CONTRACT_ADDRESS_2`]}`
    ]);
    const [approved, setApproved] = useState(false);

    const [open, setOpen] = useState(true);

    const bigInt18 = 1000000000000000000;
    const max256 = BigNumber.from("115792089237316195423570985008687907853269984665640564039457584007913129639935");

    const lpBalance = useReadLPToken('balanceOf', farmContract.lpTokenAddress, [account]);
    let lpTokenBalance = 0;

    let tokenAddress = '';
    if (farmContract.id === 0) {
        tokenAddress = `${GetEnv()[`REACT_APP_WBNB_CONTRACT_ADDRESS`]}`
    } else if (farmContract.id === 1) {
        tokenAddress = `${GetEnv()[`REACT_APP_CAKE_CONTRACT_ADDRESS`]}`
    } else if (farmContract.id === 2) {
        tokenAddress = `${GetEnv()[`REACT_APP_BUSD_CONTRACT_ADDRESS`]}`
    }

    const getLP = () => {
        window.open(`https://pancakeswap.finance/add/${GetEnv()[`REACT_APP_COC_CONTRACT_ADDRESS`]}/${tokenAddress}`, '_blank');
    }

    if (lpBalance && lpBalance.length > 0) {
        let lpBalanceBigNumber: BigNumber = BigNumber.from(lpBalance.toString())
        let amount = formatEther(lpBalanceBigNumber._hex)
        lpTokenBalance = +Number(amount).toFixed(10);
    }

    const getApproved = () => {
        let amount = Number(textInput)

        let amountToSend = (amount * bigInt18).toLocaleString('fullwide', {useGrouping: false});

        allowance.gte(
            BigNumber.from(amountToSend)
        ) ?
            setApproved(true) :
            setApproved(false)
    }

    if (textInput && allowance !== undefined && timeLeft === undefined) {
        getApproved();
        setTimeLeft(1000);
    }

    const sendApproveTx = async () => {
        await send(
            `${GetEnv()[`REACT_APP_FARMING_CONTRACT_ADDRESS_2`]
            }`,
            max256
        );
        setOpen(true)
    }

    const useStyles = makeStyles(theme => ({
        mark: {
            color: '#fff',
            fontSize: '10px'
        }
    }));

    const classes = useStyles();

    const [value, setValue] = useState<number | string | Array<number | string>>(0);
    const marks = [
        {
            value: 0,
            label: '0%',
        },
        {
            value: (lpTokenBalance / 4),
            label: '25%',
        },
        {
            value: (lpTokenBalance / 4) * 2,
            label: '50%',
        },
        {
            value: (lpTokenBalance / 4) * 3,
            label: '75%',
        },
        {
            value: lpTokenBalance,
            label: '100%',
        },
    ];

    const marks0 = [
        {
            value: 0,
            label: '0%',
        }
    ];

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue);
        setTextInput(Number(newValue).toFixed(2))
    };

    return (
        <Row style={{alignItems: 'center', margin: 0, padding: 0}}>
            <Col className={'d-none d-sm-none d-md-none d-lg-block'} md={12} lg={12} xl={12}
                 style={{margin: '10px 0', padding: 0, width: '100%'}}>
                <Row style={{margin: 0, padding: 0}}>
                    <Col xs={12} sm={12} md={12} lg={6}>
                        <FarmInputComponent
                            type="text"
                            value={textInput}
                            onChange={(e) => setTextInput(e.target.value)}
                            placeholder="Insert amount for deposit"
                            readOnly={state.status === 'Mining'}
                        />
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3}>
                        <div className={css.balance}>
                            <span style={{fontSize: '15px', marginBottom: '-4px'}}>MAX Balance:</span>
                            <span>{lpTokenBalance} LP TOKEN</span>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={6} lg={3} className={'d-flex'} style={{margin: '0', padding: 0}}>
                        <div className={css.lp} style={{margin: '0 0 0 auto'}}>
                            <ButtonComponent onClick={getLP}>Get LP</ButtonComponent>
                        </div>
                    </Col>
                </Row>

                <div style={{marginTop: '15px', marginBottom: '-16px', display: 'flex'}}>
                    <Slider
                        size="small"
                        classes={{markLabel: classes.mark}}
                        style={{color: '#E4F600', width: '100%', maxWidth: '385px', margin: '0 0 20px 15px'}}
                        value={typeof value === 'number' ? value : 0}
                        onChange={handleSliderChange}
                        valueLabelDisplay="auto"
                        max={lpTokenBalance}
                        min={0}
                        step={0.01}
                        marks={lpTokenBalance > 0 ? marks : marks0}
                        aria-labelledby="input-slider"
                        disabled={state.status === 'Mining' || !account}
                    />
                    <Col className={'d-none d-sm-block'} style={{textAlign: 'end'}}>
                        {(state.status === "Success" || approved) ? (
                            <SendOldFarm farmAmount={textInput} contractId={farmContract.id}/>
                        ) : (
                            state.status === 'Mining' ?
                                <ReactLoading className={css.marginLeft30} width={25} type={'spinningBubbles'}
                                              color="#E4F600"/>
                                :
                                <ButtonComponent onClick={sendApproveTx} disabled={!account}>Approve</ButtonComponent>
                        )}
                    </Col>
                    <Col className={'d-block d-sm-none'} style={{textAlign: 'end'}}>
                        {(state.status === "Success" || approved) ? (
                            <SendOldFarm farmAmount={textInput} contractId={farmContract.id}/>
                        ) : (
                            state.status === 'Mining' ?
                                <ReactLoading width={25} type={'spinningBubbles'} color="#E4F600"/>
                                :
                                <ButtonComponent onClick={sendApproveTx} disabled={!account}>Approve</ButtonComponent>
                        )}
                    </Col>
                </div>
            </Col>
            <Col className={'d-none d-sm-block d-md-block d-lg-none'} xs={12} sm={12} md={12}
                 style={{margin: '10px 0', padding: 0}}>
                <Row style={{margin: 0, padding: 0}}>
                    <Col xs={12} sm={12} md={12}>
                        <FarmInputComponentSm
                            type="text"
                            value={textInput}
                            onChange={(e) => setTextInput(e.target.value)}
                            placeholder="Insert amount for deposit"
                            readOnly={state.status === 'Mining'}
                        />
                    </Col>
                    <Col xs={6} sm={6} md={6}>
                        <div className={css.balance}>
                            <span style={{fontSize: '11px', margin: '10px auto 10px 0'}}>MAX Balance:</span>
                            <span>{lpTokenBalance} LP TOKEN</span>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={6} className={'d-flex'}>
                        <div className={css.lp} style={{margin: '10px 0 10px auto'}}>
                            <ButtonComponent onClick={getLP}>Get LP</ButtonComponent>
                        </div>
                    </Col>
                </Row>

                <Row style={{marginTop: '20px', marginBottom: '-16px',}}>
                    <Col xs={12} sm={6}>
                        <Slider
                            size="small"
                            classes={{markLabel: classes.mark}}
                            style={{color: '#E4F600', width: '100%', margin: '0 0 20px 8px'}}
                            value={typeof value === 'number' ? value : 0}
                            onChange={handleSliderChange}
                            valueLabelDisplay="auto"
                            max={lpTokenBalance}
                            min={0}
                            step={0.01}
                            marks={lpTokenBalance > 0 ? marks : marks0}
                            aria-labelledby="input-slider"
                            disabled={state.status === 'Mining' || !account}
                        />
                    </Col>
                    <Col xs={12} sm={6} className={'d-none d-sm-block'} style={{textAlign: 'end'}}>
                        {(state.status === "Success" || approved) ? (
                            <SendOldFarm farmAmount={textInput} contractId={farmContract.id}/>
                        ) : (
                            state.status === 'Mining' ?
                                <ReactLoading className={css.marginLeft30} width={25} type={'spinningBubbles'}
                                              color="#E4F600"/>
                                :
                                <ButtonComponent onClick={sendApproveTx} disabled={!account}>Approve</ButtonComponent>
                        )}
                    </Col>
                    <Col xs={12} sm={6} className={'d-block d-sm-none'} style={{textAlign: 'start'}}>
                        {(state.status === "Success" || approved) ? (
                            <SendOldFarm farmAmount={textInput} contractId={farmContract.id}/>
                        ) : (
                            state.status === 'Mining' ?
                                <ReactLoading width={25} type={'spinningBubbles'} color="#E4F600"/>
                                :
                                <ButtonComponent onClick={sendApproveTx} disabled={!account}>Approve</ButtonComponent>
                        )}
                    </Col>
                </Row>
            </Col>
            <Col className={'d-block d-sm-none d-md-none d-lg-none'} xs={12} sm={12} md={12}
                 style={{margin: '10px 0', padding: 0}}>
                <Row style={{margin: 0, padding: 0}}>
                    <Col xs={12} sm={12} md={4} style={{margin: 0, padding: 0}}>
                        <FarmInputComponentSm
                            type="text"
                            value={textInput}
                            onChange={(e) => setTextInput(e.target.value)}
                            placeholder="Insert amount for deposit"
                            readOnly={state.status === 'Mining'}
                        />
                    </Col>
                    <Col xs={6} sm={6} md={4} style={{margin: 0, padding: 0}}>
                        <div className={css.balance} style={{margin: 0, padding: 0}}>
                            <span style={{fontSize: '11px', margin: '10px auto 10px 0'}}>MAX Balance:</span>
                            <span>{lpTokenBalance} LP TOKEN</span>
                        </div>
                    </Col>
                    <Col xs={6} sm={6} md={4} className={'d-flex'} style={{margin: 0, padding: 0}}>
                        <div className={css.lp} style={{marginTop: '10px'}}>
                            <ButtonComponent onClick={getLP}>Get LP</ButtonComponent>
                        </div>
                    </Col>
                </Row>

                <Row style={{marginTop: '20px', marginBottom: '-16px',}}>
                    <Col xs={12} sm={6}>
                        <Slider
                            size="small"
                            classes={{markLabel: classes.mark}}
                            style={{color: '#E4F600', width: '100%', margin: '0 0 20px 8px'}}
                            value={typeof value === 'number' ? value : 0}
                            onChange={handleSliderChange}
                            valueLabelDisplay="auto"
                            max={lpTokenBalance}
                            min={0}
                            step={0.01}
                            marks={lpTokenBalance > 0 ? marks : marks0}
                            aria-labelledby="input-slider"
                            disabled={state.status === 'Mining' || !account}
                        />
                    </Col>
                    <Col xs={12} sm={6} className={'d-none d-sm-block'} style={{textAlign: 'end'}}>
                        {(state.status === "Success" || approved) ? (
                            <SendOldFarm farmAmount={textInput} contractId={farmContract.id}/>
                        ) : (
                            state.status === 'Mining' ?
                                <ReactLoading className={css.marginLeft30} width={25} type={'spinningBubbles'}
                                              color="#E4F600"/>
                                :
                                <ButtonComponent onClick={sendApproveTx} disabled={!account}>Approve</ButtonComponent>
                        )}
                    </Col>
                    <Col xs={12} sm={6} className={'d-block d-sm-none'} style={{textAlign: 'start'}}>
                        {(state.status === "Success" || approved) ? (
                            <SendOldFarm farmAmount={textInput} contractId={farmContract.id}/>
                        ) : (
                            state.status === 'Mining' ?
                                <ReactLoading width={25} type={'spinningBubbles'} color="#E4F600"/>
                                :
                                <ButtonComponent onClick={sendApproveTx} disabled={!account}>Approve</ButtonComponent>
                        )}
                    </Col>
                </Row>
            </Col>

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </Row>
    );
}
