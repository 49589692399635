import React from "react";
import {useParams} from "react-router-dom";
import {useJwt} from "react-jwt";
import {LoadNftBuilder} from "./components/loadNftBuilder/loadNftBuilder";
import {CheckNetwork} from "../../components/network/checkNetwork";
import css from "../loadNft/loadNft.module.css";

export const LoadNft: React.FC = () => {
    const params: { marketplace: string, collection: string } = useParams();
    const marketplace = params.marketplace;
    const collection = params.collection;

    const token = localStorage.getItem('admin_token') || '';
    const {isExpired} = useJwt(token);

    if (!token || isExpired) {
        localStorage.removeItem('admin_token');
        window.location.href = "/login";
    }

    return (
        <div>
            <CheckNetwork />
            <div className={css.container}>
                {!!marketplace.length &&
                    <LoadNftBuilder marketplace={marketplace} collection={collection} />}
            </div>
        </div>
    );
}
