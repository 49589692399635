import React from "react";
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { TableComponent } from "../../../../components/table/TableComponent";
import { VestData } from "../../../../helpers/manageVestingData";
import { VestingItem } from "./vestingItem";
import css from './vestingTable.module.css';

interface TableVestingProps {
    vestContract: VestData;
}

export const VestingTable: React.FC<TableVestingProps> = ({vestContract}) => {
    return (
            <TableComponent aria-label="collapsible table" id={css.tableCard}>
                <TableHead id={css.tableRowHead}>
                    <TableRow id={css.tableRowHead}>
                        <TableCell />
                        <TableCell id={css.textTable}>Deposited</TableCell>
                        <TableCell id={css.textTable}>APR</TableCell>
                        <TableCell id={css.textTable}>Period</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody >
                        <VestingItem vestContract={vestContract}/>
                </TableBody>
            </TableComponent>
    );
}
