import {utils} from "ethers";
import {useContractFunction} from "@usedapp/core";
import {Contract} from "@ethersproject/contracts";
import {environment, GetEnv} from "../../../environments/environment";

export function useWriteStakingContract(
    methodName: string,
    contractIndex: number,
) {
    const contracAbi = new utils.Interface(
        require(`../../../assets/abi/testnet/staking.abi.json`)
    );
    const contractAddress = `${GetEnv()[`REACT_APP_STAKING_CONTRACT_ADDRESS_${contractIndex}` as keyof environment]
    }`;

    const contract = new Contract(contractAddress, contracAbi);

    return useContractFunction(contract, methodName);
}
