import ManageFarmingData, { FarmData } from "../../../helpers/manageFarmingData";
import { OldFarmItem } from "./oldFarmItem/oldFarmItem";
import {GetEnv} from "../../../environments/environment";

export const OldFarmBuilder: React.FC = () => {
  const farmData: FarmData[] = ManageFarmingData.farmDataOld(`${GetEnv()[`REACT_APP_FARMING_CONTRACT_ADDRESS_2`]}`);

  return (
    <div style={{ padding: '50px 0' }}>
      {farmData.map(element => {
        return <OldFarmItem key={element.id} farmData={element} />
      })}
    </div>
  );
};
