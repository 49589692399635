import React from "react";
import {useEthers} from "@usedapp/core";
import styled from "styled-components";
import 'bootstrap/dist/css/bootstrap.min.css';
import {ButtonComponentWallet} from "../button/ButtonComponentWallet";
import css from "../toolbar/Toolbar.module.css";
import logo from "../../assets/icons/coc-yellowtransparent.png";

export const CheckAccount: React.FC = () => {
    const {activateBrowserWallet, account, deactivate} = useEthers();

    return (
        <div>
            {!account && <ErrorNetwork>

                <img alt='logo' style={{width: 100}} src={logo}/>

               <span style={{fontSize: 36, fontWeight: 'bold', width: '90%'}}>
                            In order to navigate the dApp please connect your wallet.
               </span>

                <ButtonComponentWallet
                    variant="contained"
                    onClick={() => account ? deactivate() : activateBrowserWallet()}>
                                <span>
                                    {account
                                        ?
                                        <p className={css.accountText}> {`${account?.slice(0, 4)}...${account?.slice(-3)}`} </p>
                                        : <p className={css.accountText}>Connect wallet</p>}
                                </span>
                </ButtonComponentWallet>

            </ErrorNetwork>}
        </div>
    );
};

const ErrorNetwork = styled("div")`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 60px;
    justify-content: center !important;
    text-align: center !important;
    font-family: 'Montserrat' !important;
    position: fixed;
    height: 100% !important;
    width: 100% !important;
    color: white !important;
    background-color: black;
    z-index: 2100;
`;
