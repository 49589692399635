import {useContractCall} from "@usedapp/core";
import {Interface} from '@ethersproject/abi';
import abi from '../assets/abi/testnet/Marketplace.json';

export function useReadMarketplaceContract(address: string, method: string, args?: any[]) {
    if (args === undefined) {
        args = [];
    }
    const abiInterface = new Interface(abi);


    const contractResult = useContractCall({
        abi: abiInterface,
        address,
        method,
        args
    });

    if (method === 'sellOrders') {
        return contractResult === undefined ? [] : contractResult;
    }

    return contractResult === undefined ? [] : contractResult[0];
}
