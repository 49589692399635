import styled from "styled-components";
import {Button} from "@material-ui/core";

export const NetworkButtonComponent = styled(Button)`
    font-family: 'Montserrat' !important;
    padding: 0 20px !important;
    font-size: 12px !important;
    margin: 5px !important;
    text-transform: none !important;
    color: #f8f9fa !important;
`;
