/* eslint-disable react-hooks/rules-of-hooks */
import { useContractCall } from "@usedapp/core";
import { utils } from "ethers";
import {environment, GetEnv} from "../environments/environment";

export default class StakingHelper {
  static get stakeContractLength(): number {
    const envObject: Object = GetEnv();
    let stakeIndex: number = 0;

    const listKeys = Object.keys(envObject).filter((currentKey) =>
      currentKey.match("REACT_APP_STAKING_CONTRACT_ADDRESS_")
    );

    listKeys.forEach((key) => {
      stakeIndex = Number(key.split("REACT_APP_STAKING_CONTRACT_ADDRESS_")[1]);
    });

    return stakeIndex;
  }

  static readStakingContract(
    methodName: string,
    contractIndex: number,
    args?: any[]
  ) {
    if (args === undefined) {
      args = [];
    }

    const contractResult = useContractCall({
      abi: new utils.Interface(
        require(`../assets/abi/testnet/staking.abi.json`)
      ),
      address: `${GetEnv()[`REACT_APP_STAKING_CONTRACT_ADDRESS_${contractIndex}` as keyof environment]}`,
      method: methodName,
      args: args,
    });

    return contractResult === undefined ? [] : contractResult[0];
  }

  static calculateAPY(
    withdrawStart: bigint,
    stakingEnds: bigint,
    totalReward: bigint,
    stakedTotal: bigint
  ) {
    let apyTimeline =
      Number(withdrawStart - stakingEnds) / Number(365 * 24 * 60 * 60);

    let apyPercentage = (Number(totalReward) / Number(stakedTotal)) * 100;

    let totalAPY = (apyPercentage / apyTimeline).toFixed(2);

    if (Number.isNaN(parseInt(totalAPY))) {
      totalAPY = "0";
    }

    return totalAPY;
  }
}
