import { styled } from '@mui/system';

export const StakeInputComponent = styled('input')`
  width: 100% !important;
  line-height: 1.4375em !important;
  padding: 7px 10px !important;
  background: rgb(50, 50, 50) !important;
  color: #e5e8ec !important;
  border: 1px solid #424242 !important;
  border-radius: 10px !important;
  font-size: 13px !important;
  transition: width 300ms ease;
`;

export const StakeInputComponentSm = styled('input')`
  width: 120px !important;
  line-height: 1.4375em !important;
  padding: 7px 10px !important;
  background: rgb(50, 50, 50) !important;
  color: #e5e8ec !important;
  border: 1px solid #424242 !important;
  border-radius: 10px !important;
  font-size: 13px !important;
  transition: width 300ms ease;
`;
