import ManageFarmingData, { FarmData } from "../../../helpers/manageFarmingData";
import { FarmItem } from "./farmItem/farmItem";
import {GetEnv} from "../../../environments/environment";

export const FarmBuilder: React.FC = () => {
  const farmData: FarmData[] = ManageFarmingData.farmData(`${GetEnv()[`REACT_APP_FARMING_CONTRACT_ADDRESS_1`]}`);

  return (
    <div style={{ padding: '50px 0' }}>
      {farmData.map(element => {
        return <FarmItem key={element.id} farmData={element} />
      })}
    </div>
  );
};
