import { useContractFunction } from "@usedapp/core";
import { utils } from "ethers";
import { Contract } from "@ethersproject/contracts";
import {GetEnv} from "../environments/environment";

export function useWriteCollectionFactory(methodName: string) {
    const contractAbi = new utils.Interface(
        require(`../assets/abi/testnet/collectionFactory.abi.json`)
    );
    const contractAddress = `${GetEnv()[`REACT_APP_NFT_COLLECTION_FACTORY_ADDRESS`]}`;

    const contract = new Contract(contractAddress, contractAbi);

    return useContractFunction(contract, methodName);
}
