import { BigNumber } from "@ethersproject/bignumber";
import { formatEther } from "@ethersproject/units";
import { formatHomeBignumber } from '../../../helpers/formatDecimals';
import css from './HomeCardContent.module.css';
import {Col, Row} from "react-bootstrap";

interface CardContentProps {
  title: string;
  amount: string;
  decimals?: number;
  dollars?: boolean;
  coc?: boolean;
}

export const HomeCardContent: React.FC<CardContentProps> = ({ title, amount, decimals, dollars, coc }) => {
  let amountNumber;
  if (amount && decimals && decimals > 0) {
    let amountBigNumber: BigNumber = BigNumber.from(amount)
    let amountFormat = formatEther(amountBigNumber._hex)
    amountNumber = +Number(amountFormat).toFixed(2);
    amountNumber = formatHomeBignumber(amountNumber);
  }

  return (
    <Row style={{margin:0,padding:0}}>
      <Col className={'d-block d-sm-block d-md-block d-lg-none'} style={{margin:0,padding:0}}>
        <Row style={{width:'100%', margin:'0 auto',borderBottom: '1px solid #E4F600',paddingBottom: '15px', justifyContent: 'space-between'}}>
          <Col className={'align-items-center justify-content-center d-flex text-center'} xs={12} sm={12} md={12} style={{margin:0,padding:0, width:'100%'}}>
            <Row className={'align-items-center justify-content-center d-flex text-center'}  style={{margin:'0 auto',padding:0, width:'100%'}}>
              <label style={{ color: '#FFFFFF', fontSize: 15,  width:'100%' }}>{title}</label>
            </Row>
          </Col>
          <Col className={'align-items-center justify-content-center d-flex text-center'} xs={12} sm={12} md={12} style={{margin:'0 auto',padding:0,}}>
            <Row className={'align-items-center justify-content-center d-flex text-center'}  style={{margin:'0 auto',padding:0, width:'100%'}}>
              <label className={css.cardAmount}>{(amountNumber ? amountNumber : amount) + (dollars ? " $" : "") + (coc ? " COC" : "")}</label>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col className={'d-none d-sm-none d-md-none d-lg-block '} style={{margin:0,padding:0}}>
        <Row className={'align-items-center justify-content-center d-flex'} style={{margin:'0',borderBottom: '1px solid #E4F600',paddingBottom: '15px'}}>
          <Col lg={6} xl={6} style={{margin:0,padding:0}}>
            <label style={{ color: '#FFFFFF',margin:0,padding:0 }}>{title}</label>
          </Col>
          <Col lg={6} xl={6} style={{margin:0,padding:0,}}>
            <label className={css.cardAmount} style={{margin:0,padding:0 }}>{(amountNumber ? amountNumber : amount) + (dollars ? " $" : "") + (coc ? " COC" : "")}</label>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
