import React from "react";
import {Link, useParams} from "react-router-dom";
import KeyboardBackspaceIcon from "@material-ui/icons/KeyboardBackspace";
import {ButtonComponent} from "../../../components/button/ButtonComponent";
import {useReadGenericErc721} from "../../../customHooks/useReadGenericErc721";
import {MyNftContent} from "./myNftContent";
import {MyNftHeader} from "./myNftHeader";
import css from "../myNft.module.css";


export const MyNftBuilder: React.FC = () => {
    const params: { marketplace: string, collection: string } = useParams();
    const marketplace = params.marketplace;
    const collection = params.collection;

    const name = useReadGenericErc721(collection, 'name');

    return (
        <div className={css.containerColor}>
            <MyNftHeader marketplaceAddress={marketplace} collectionAddress={collection}/>

            <div style={{margin: "50px", display: "flex", flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', gap: '30px'}}>
                <Link className={css.linkStyle} to={{pathname: `/profile`}}>
                    <ButtonComponent>
                        <KeyboardBackspaceIcon/>
                    </ButtonComponent>
                </Link>
                <h2 style={{margin: 0}}>{name}</h2>
            </div>

            <div className={css.container}>
                <MyNftContent marketplaceAddress={marketplace} collectionAddress={collection}/>
            </div>
        </div>
    );
};
