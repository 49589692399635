import React, {useState} from "react";
import {useReadMarketplaceContract} from "../../../../customHooks/useReadMarketplaceContract";
import {formatUnits} from "ethers/lib/utils";

interface NftContentProps {
    tokenId: number;
    marketplaceAddress: string;
    collectionAddress: string;
    setUpdatedList: any;
}

export const NftUpdateDetail: React.FC<NftContentProps> = ({marketplaceAddress, collectionAddress, tokenId, setUpdatedList}) => {
    const sellOrders = useReadMarketplaceContract(marketplaceAddress, 'sellOrders', [tokenId]);
    const [auction, setAuction] = useState<string>();

    if (sellOrders && sellOrders.start && auction === undefined) {
        if (!sellOrders.claimed && (+formatUnits(sellOrders.directSalePrice, 18) !== 0 || +formatUnits(sellOrders.basePrice, 18) !== 0)) {
            setUpdatedList(tokenId)
        }
        setAuction(sellOrders.end.toString())
    }

    return (
        <></>
    );
};
