import styled from 'styled-components'
import { Button } from "@material-ui/core";

export const ButtonComponentWallet = styled(Button)`
    font-family: 'Montserrat' !important;
    border-radius: 25px !important;
    background-color: #E4F600 !important;
    padding: 0 20px !important;
    font-size: 12px !important;
    margin: 5px !important;
    text-transform: none !important;
    color: #171819 !important;
    box-shadow: 0 0 5px 2px #E4F600;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;
