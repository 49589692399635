import React, {useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import {BigNumber} from "@ethersproject/bignumber";
import {formatUnits} from "ethers/lib/utils";
import {useEthers} from "@usedapp/core";
import axios from "axios";
import moment from "moment";
import {
    Card,
    CardContent, Snackbar,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@mui/material";
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import ReactLoading from "react-loading";
import {useReadGenericErc721} from "../../../../../../customHooks/useReadGenericErc721";
import {useReadMarketplaceContract} from "../../../../../../customHooks/useReadMarketplaceContract";
import {useReadCOCContract} from "../../../../../../customHooks/useReadCOCContract";
import {useWriteCOC} from "../../../../../../customHooks/useWriteCOC";
import {TableBidsDetails} from "./tableDetails";
import {DirectBuy} from "./directBuy";
import {Claim} from "./claim";
import {SendBid} from "./sendBid";
import {TokenUriModel} from "../collectionView";
import {WithdrawInputComponent} from "../../../../../../components/input/WithdrawInputComponent";
import {ButtonComponentDisabled} from "../../../../../../components/button/ButtonComponentDisabled";
import {ButtonComponent} from "../../../../../../components/button/ButtonComponent";
import css from "../../../../Nft.module.css";

export const DetailsView = () => {
    let history = useHistory();
    const {account} = useEthers();
    const [auctionLive, setAuctionLive] = useState(false);
    const [ended, setEnded] = useState(false);
    const [canClaim, setCanClaim] = useState(false);
    const [disable, setDisable] = useState(false);
    const [auction, setAuction] = useState<string>();
    const params: { marketplace: string, collection: string, id: string } = useParams();
    const tokenId = params.id;
    const marketplace = params.marketplace;
    const collection = params.collection;
    const [info, setInfo] = useState<any>();
    const [textInput, setTextInput] = useState("");
    const [enabled, setEnabled] = useState<boolean>();
    const [open, setOpen] = useState(true);
    const [max, setMax] = useState<any>();
    const [maxOwner, setMaxOwner] = useState<any>();

    const tokenURI = useReadGenericErc721(collection, 'tokenURI', [tokenId]);
    const tokenReplace = String(tokenURI).replace("ipfs://", "https://ipfs.io/ipfs/");

    if (tokenReplace && info === undefined) {
        axios.get(tokenReplace).then(res => {
            const json: TokenUriModel = res.data
            let frm;
            if (json.image.includes("ipfs://")) {
                frm = String(json.image).replace("ipfs://", "https://ipfs.io/ipfs/")
            } else {
                frm = "https://ipfs.io/ipfs/" + String(json.image)
            }
            setInfo({...json, formattedImage: frm})
        })
    }

    const {state, send} = useWriteCOC("approve");

    const max256 = BigNumber.from("115792089237316195423570985008687907853269984665640564039457584007913129639935");

    if (state.status === 'Success' || state.status === 'Exception') {
        setInterval(() => {
            window.location.reload()
        }, 2000);
    }

    const sellOrders = useReadMarketplaceContract(marketplace, 'sellOrders', [tokenId]);
    const bids = useReadMarketplaceContract(marketplace, 'getBids', [tokenId]);

    if (sellOrders && sellOrders.start && auction === undefined && bids) {
        let now = new Date().getTime();
        let start = sellOrders.start.toString() * 1000
        let end = sellOrders.end.toString() * 1000
        if (now > start && end > now) {
            setAuctionLive(true)
            setAuction(sellOrders.end.toString())
        } else {
            setAuction(sellOrders.end.toString())
        }

        if (end < now) {
            setEnded(true)
        }

        if (ended) {
            setDisable(ended && !!bids.length)
            setCanClaim(ended && maxOwner === account)
        }
    }

    if (bids && bids.length && max === undefined) {
        const max = Math.max(...bids.map((o: any) => +o.price.toLocaleString()));
        setMax(BigNumber.from(max.toString()));
        const maxOwner = bids.find((b: any) => +b.price.toLocaleString() === max)
        setMaxOwner(maxOwner.bidder)
    }

    const allowance = useReadCOCContract("allowance", [
        account,
        marketplace
    ]);

    if (allowance._isBigNumber && enabled === undefined) {
        setEnabled(allowance.gt(BigNumber.from(0)))
    }

    const sendApproveTx = async () => {
        await send(
            marketplace,
            max256
        );
    }

    const urlify = (text: string) => {
        const urlRegex = /(https?:\/\/[^\s]+)/g;
        return text.replace(urlRegex, function (url) {
            return '<a href="' + url + '">' + url + '</a>';
        })
    }

    return (
        <>
            {sellOrders && (<div className={css.containerColor}>
                <div className={css.back}>
                        <ButtonComponent onClick={history.goBack}>
                            <KeyboardBackspaceIcon/>
                        </ButtonComponent>
                </div>

                <div className={css.boxCard}>
                    {info && <Card className={css.imgCard}>
                        <div style={{
                            backgroundImage: `url(${info.formattedImage})`
                        }} className={css.imgBox}/>
                        <CardContent>
                            <div style={{marginBottom: '30px'}}>
                                <h1 className={css.name}>{info.name}</h1>
                            </div>
                            <div>
                                <p dangerouslySetInnerHTML={{__html: urlify(info.description)}}/>
                            </div>
                        </CardContent>
                    </Card>}
                    {info && <div className={css.containerInfo}>
                        {
                            sellOrders.claimed ?
                                <div>
                                    <div style={{color: '#E4F600'}}>
                                        <h1>Sold</h1>
                                    </div>
                                </div> :
                                <>
                                    {auctionLive ?
                                        (+formatUnits(sellOrders.basePrice, 18) !== 0) && <div>
                                            <div>
                                                <div style={{color: 'green'}}>
                                                    <h3>Auction live</h3>
                                                    <p>ends {moment(sellOrders.end * 1000).fromNow()}</p>
                                                </div>
                                                <div>
                                                    <p style={{fontSize: '16px', margin: 0}}>Starting price</p>
                                                    <div style={{width: '80%', marginTop: '20px'}}>
                                                        <WithdrawInputComponent
                                                            type="text"
                                                            value={Number(formatUnits(sellOrders.basePrice, 18)).toFixed(2) + (sellOrders.paymentToken === '0x0000000000000000000000000000000000000000' ? ' BNB' : ' COC')}
                                                            disabled
                                                        />
                                                    </div>
                                                </div>

                                                <div style={{margin: '20px 0 20px'}}>
                                                    <p style={{
                                                        color: 'yellow',
                                                        textDecoration: 'underline',
                                                        fontSize: '16px'
                                                    }}>
                                                        {bids.length} total offers
                                                    </p>
                                                </div>
                                            </div>

                                            <div style={{width: '80%', margin: '30px 0 10px'}}>
                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                    gap: '10px'
                                                }}>
                                                    {!!max && <p style={{fontSize: '16px', marginTop: '15px'}}>
                                                        Highest
                                                        offer: {formatUnits(max, 18) + (sellOrders.paymentToken === '0x0000000000000000000000000000000000000000' ? ' BNB' : ' COC')}
                                                    </p>}

                                                    {canClaim &&
                                                        <Claim tokenId={tokenId} marketplaceAddress={marketplace}/>
                                                    }
                                                </div>

                                                <WithdrawInputComponent
                                                    type="text"
                                                    placeholder="Your offer"
                                                    value={textInput}
                                                    onChange={(e) => setTextInput(e.target.value)}
                                                />
                                            </div>
                                            <div>
                                                {(state.status === "Success" || enabled || sellOrders.paymentToken === '0x0000000000000000000000000000000000000000') ?
                                                    (
                                                        <SendBid sellOrders={sellOrders}
                                                                 textInput={textInput} marketplaceAddress={marketplace}/>
                                                    ) :
                                                    (
                                                        state.status === 'Mining' ?
                                                            <ReactLoading className={css.loading}
                                                                          width={21}
                                                                          type={'spinningBubbles'}
                                                                          color="#E4F600"/>
                                                            :
                                                            <ButtonComponent onClick={sendApproveTx}
                                                                             style={{width: '80%'}}
                                                                             disabled={!account}>Approve</ButtonComponent>
                                                    )
                                                }
                                            </div>
                                        </div>
                                        :
                                        <>
                                            {
                                                (+formatUnits(sellOrders.directSalePrice, 18) === 0 && +formatUnits(sellOrders.basePrice, 18) === 0) ?
                                                    <div>
                                                        <div style={{color: '#E4F600'}}>
                                                            <h3>Not in sale yet</h3>
                                                        </div>
                                                    </div> :
                                                    <>
                                                        {(+formatUnits(sellOrders.basePrice, 18) !== 0) && (
                                                            <div>
                                                                <div style={{color: 'red', marginBottom: '30px'}}>
                                                                    <h3>Auction on</h3>
                                                                    <p style={{fontSize: '18px'}}>{moment(sellOrders.start * 1000).format('DD/MM/YYYY HH:mm')} - {moment(sellOrders.end * 1000).format('DD/MM/YYYY HH:mm')}</p>
                                                                </div>
                                                                <div>
                                                                    <p style={{fontSize: '16px', margin: 0}}>Starting
                                                                        price</p>
                                                                    <div style={{width: '80%', marginBottom: '30px'}}>
                                                                        <WithdrawInputComponent
                                                                            type="text"
                                                                            value={Number(formatUnits(sellOrders.basePrice, 18)).toFixed(2) + (sellOrders.paymentToken === '0x0000000000000000000000000000000000000000' ? ' BNB' : ' COC')}
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>

                                                                <div style={{margin: '20px 0 20px'}}>
                                                                    <p style={{
                                                                        color: 'yellow',
                                                                        textDecoration: 'underline',
                                                                        fontSize: '16px'
                                                                    }}>
                                                                        {bids.length} total offers
                                                                    </p>
                                                                </div>
                                                                <div style={{width: '80%', margin: '30px 0 20px'}}>
                                                                    <WithdrawInputComponent
                                                                        type="text"
                                                                        disabled
                                                                        placeholder="Your offer"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <ButtonComponentDisabled style={{width: '80%'}}
                                                                                             disabled>Send</ButtonComponentDisabled>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </>
                                            }
                                        </>
                                    }

                                    {(+formatUnits(sellOrders.directSalePrice, 18) !== 0) && (
                                        <div>
                                            <p style={{fontSize: '16px', marginTop: '50px'}}>Direct buy</p>
                                            <div style={{width: '80%', marginBottom: '10px'}}>
                                                <WithdrawInputComponent
                                                    type="text"
                                                    value={Number(formatUnits(sellOrders.directSalePrice, 18)).toFixed(2) + (sellOrders.paymentToken === '0x0000000000000000000000000000000000000000' ? ' BNB' : ' COC')}
                                                    disabled
                                                />
                                            </div>
                                            <div>
                                                <DirectBuy sellOrders={sellOrders} tokenId={tokenId}
                                                           disable={disable} marketplaceAddress={marketplace}/>
                                            </div>
                                        </div>
                                    )}
                                </>
                        }
                    </div>}
                </div>

                {(sellOrders.basePrice && +formatUnits(sellOrders.basePrice, 18) !== 0 && !!bids.length) &&
                    <div style={{width: '90%', margin: "100px auto 100px"}}>
                        <div>
                            <h1>Latest offers</h1>
                        </div>
                        <div>
                            <TableContainer className={css.tableStyle}>
                                <Table>
                                    <TableHead>
                                        <TableRow className={css.tableRawHead}>
                                            <TableCell className={css.tableCell} align="left">User</TableCell>
                                            <TableCell className={css.tableCell} align="left">Date</TableCell>
                                            <TableCell className={css.tableCell} align="left">Time</TableCell>
                                            <TableCell className={css.tableCell} align="left">Bid</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {bids && <TableBody>
                                        {bids.map((bid: any, index: number) => (
                                            <TableBidsDetails bid={bid} key={index} sellOrders={sellOrders}/>
                                        ))}
                                    </TableBody>}
                                </Table>
                            </TableContainer>
                        </div>
                    </div>}
            </div>)}

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </>
    );

}
