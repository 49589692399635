import React, {useState} from "react";
import ReactLoading from "react-loading";
import {useEthers} from "@usedapp/core";
import {Snackbar} from "@mui/material";
import {ButtonComponent} from "../../../../components/button/ButtonComponent";
import {useReadGenericErc721} from "../../../../customHooks/useReadGenericErc721";
import {useWriteGenericErc721} from "../../../../customHooks/useWriteGenericErc721";
import {ConfirmNft} from "./confirmNft";
import css from "../../../loadNft/loadNft.module.css";

interface ApproveNftProps {
    marketplaceAddress: string;
    collectionAddress: string;
    tokenId: number;
    priceToBuy: any;
    basePrice: any;
    tokenAddress: any;
    start: any;
    end: any;
}

export const ApproveNft: React.FC<ApproveNftProps> = ({
                                                          marketplaceAddress,
                                                          collectionAddress,
                                                          tokenId,
                                                          priceToBuy,
                                                          basePrice,
                                                          tokenAddress,
                                                          start,
                                                          end
                                                      }) => {
    const {account} = useEthers();
    const [open, setOpen] = useState(true);
    const {send, state} = useWriteGenericErc721(collectionAddress, "setApprovalForAll");
    const isApprovedForAll = useReadGenericErc721(collectionAddress, "isApprovedForAll", [account, marketplaceAddress]);
    const [approve, setApprove] = useState(true);
    const [isApproved, setIsApproved] = useState<boolean>();
    const tokenID = tokenId;

    if (isApprovedForAll && typeof isApprovedForAll === 'boolean' && isApproved === undefined) {
        setIsApproved(isApprovedForAll);
        setApprove(!isApprovedForAll);
    }

    const Approve = () => {
        send(marketplaceAddress, true);
    }

    if (state.status === 'Success') {
        setInterval(() => {
            setApprove(false);
        }, 2000);
    }

    return (
        <>
            <div className={css.containerButton}>
                {approve ?
                    (state.status === 'Mining' ?
                        <ReactLoading width={25} type={'spinningBubbles'} color="#E4F600"/>
                        :

                        <ButtonComponent style={{width: '215px'}} onClick={Approve}>Approve</ButtonComponent>)
                    :
                    <ConfirmNft marketplaceAddress={marketplaceAddress} tokenId={tokenID} priceToBuy={priceToBuy}
                                basePrice={basePrice} tokenAddress={tokenAddress}
                                start={start} end={end}/>
                }
            </div>

            {state.status === 'Exception' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message={state.errorMessage}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Mining' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Waiting for confirmation!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }

            {state.status === 'Success' &&
                <Snackbar
                    key={state.chainId}
                    open={open}
                    autoHideDuration={5000}
                    onClose={() => setOpen(false)}
                    message="Confirmed transaction!"
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right"
                    }}
                />
            }
        </>
    );

}
