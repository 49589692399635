import React from "react";
import {useReadGenericErc721} from "../../../customHooks/useReadGenericErc721";
import {GetCollectionInfo} from "./getCollectionInfo";

interface GetCollectionProps {
    collection: string;
    marketplace: string;
}

export const GetCollection: React.FC<GetCollectionProps> = ({collection, marketplace}) => {
    const totalSupply = useReadGenericErc721(collection, 'totalSupply');
    const name = useReadGenericErc721(collection, 'name');

    return (
        <>
            {totalSupply._isBigNumber && <GetCollectionInfo collection={collection} marketplace={marketplace} name={name}
                                totalSupply={totalSupply.toLocaleString()}/>}
        </>
    )
}
