import React from "react";
import {Collapse} from "react-bootstrap";
import {Box, IconButton} from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import {VestData} from "../../../../helpers/manageVestingData";
import {VestingAction} from "./vestingAction";
import {LockItem} from "./lockItem";
import css from "./vestingTable.module.css";

interface TableVestingItemProps {
    vestContract: VestData;
}

export const VestingItem: React.FC<TableVestingItemProps> = ({vestContract}) => {
    const [open, setOpen] = React.useState(false);

    return (
        < >
            {vestContract && vestContract.locksIds.map((lockId, index) =>
                <LockItem key={index} vestContract={vestContract} lockId={lockId}/>
            )}
            <TableRow>
                <TableCell id={css.textTable} style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                        className={!open ? css.iconButtonStyleClose : css.iconButtonStyleOpen}
                    >
                        {open ? <KeyboardArrowUpIcon className={css.iconStyle}/> :
                            <KeyboardArrowDownIcon className={css.iconStyle}/>}
                    </IconButton>
                    New deposit
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell style={{paddingBottom: 0, paddingTop: 0}} colSpan={6}>
                    <Collapse in={open} unmountOnExit>
                        <Box>
                            <VestingAction vestContract={vestContract}/>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
}
