import React from "react";
import {Link} from "react-router-dom";
import {Card, CardContent} from "@mui/material";
import css from "../../nft/Nft.module.css";

interface GetCollectionInfoNoNftProps {
    collection: string;
    marketplace: string;
    name: string;
}

export const GetCollectionInfoNoNft: React.FC<GetCollectionInfoNoNftProps> = ({collection, marketplace, name}) => {

    return (
        <>
            <div className={css.boxCard}>
                <Link className={css.linkStyle}
                      to={{
                          pathname: window.location.pathname === '/profile' ? `/profile/${marketplace}/${collection}` : `/nft/${marketplace}/${collection}`
                      }}>
                    <Card sx={{
                        width: '240px',
                        margin: '20px',
                        filter: 'drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25))',
                        borderRadius: '25px'
                    }}>

                        <CardContent style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                            <div style={{marginBottom: '30px'}}>
                                <h1 style={{color: 'white', fontSize: '20px', lineHeight: '35px'}}>{name}</h1>
                            </div>
                        </CardContent>
                    </Card>
                </Link>
            </div>

        </>
    )
}
