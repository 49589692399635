/* eslint-disable react-hooks/rules-of-hooks */
import { useEthers } from "@usedapp/core";
import FarmingHelper from "./farmingHelper";
import { farmingRewards } from "./farmingRewards";
import { useContractDetail } from "../customHooks/useContractDetail";
import {GetEnv} from "../environments/environment";

export interface FarmData {
    id: number;
    name: string;
    lpTokenAddress: string;
    pendingReward: string;
    lpDeposited: string;
    reward: string;
    APR: string;
}

export default class ManageFarmingData {
    static farmData(farmContract: string): FarmData[] {
        const { account } = useEthers();
        const names = ["COC-BNB", "COC-BUSD", "COC-CAKE"]
        const data: FarmData[] = [];

        for (let i = 0; i < FarmingHelper.farmContractLength(farmContract); i++) {
            data.push({
                id: i,
                name: names[i],
                lpTokenAddress:
                    i === 0 ? `${GetEnv()[`REACT_APP_LP_COC_BNB_ADDRESS`]}` :
                        i === 1 ? `${GetEnv()[`REACT_APP_LP_COC_BUSD_ADDRESS`]}` :
                            `${GetEnv()[`REACT_APP_LP_COC_CAKE_ADDRESS`]}`,
                pendingReward: FarmingHelper.readFarmingContract(farmContract,'pending', [i, account]).toString(),
                lpDeposited: FarmingHelper.readFarmingContract(farmContract,'deposited', [i, account]).toString(),
                APR: FarmingHelper.calculateAPR(
                    farmContract,
                    useContractDetail(),
                    FarmingHelper.readFarmingContract(farmContract,'pending', [i, account]),
                    i === 0 ? `${GetEnv()[`REACT_APP_LP_COC_BNB_ADDRESS`]}` :
                        i === 1 ? `${GetEnv()[`REACT_APP_LP_COC_BUSD_ADDRESS`]}` :
                            `${GetEnv()[`REACT_APP_LP_COC_CAKE_ADDRESS`]}`,
                    i === 0 ? `${GetEnv()[`REACT_APP_WBNB_CONTRACT_ADDRESS`]}` :
                        i === 1 ? `${GetEnv()[`REACT_APP_BUSD_CONTRACT_ADDRESS`]}` :
                            `${GetEnv()[`REACT_APP_CAKE_CONTRACT_ADDRESS`]}`,
                    i,
                    FarmingHelper.readFarmingContract(farmContract,"rewardPerBlock")
                ),
                reward: farmingRewards()
            })
        }

        return data;
    }

    static farmDataOld(farmContract: string): FarmData[] {
        const { account } = useEthers();
        const names = ["COC-BNB", "COC-CAKE", "COC-BUSD"]
        const data: FarmData[] = [];

        for (let i = 0; i < FarmingHelper.farmContractLength(farmContract); i++) {
            data.push({
                id: i,
                name: names[i],
                lpTokenAddress:
                    i === 0 ? `${GetEnv()[`REACT_APP_LP_COC_BNB_ADDRESS`]}` :
                        i === 1 ? `${GetEnv()[`REACT_APP_LP_COC_CAKE_ADDRESS`]}` :
                            `${GetEnv()[`REACT_APP_LP_COC_BUSD_ADDRESS`]}`,
                pendingReward: FarmingHelper.readFarmingContract(farmContract,'pending', [i, account]).toString(),
                lpDeposited: FarmingHelper.readFarmingContract(farmContract,'deposited', [i, account]).toString(),
                APR: FarmingHelper.calculateAPROld(
                    farmContract,
                    useContractDetail(),
                    FarmingHelper.readFarmingContract(farmContract,'pending', [i, account]),
                    i === 0 ? `${GetEnv()[`REACT_APP_LP_COC_BNB_ADDRESS`]}` :
                        i === 1 ? `${GetEnv()[`REACT_APP_LP_COC_CAKE_ADDRESS`]}` :
                            `${GetEnv()[`REACT_APP_LP_COC_BUSD_ADDRESS`]}`,
                    i === 0 ? `${GetEnv()[`REACT_APP_WBNB_CONTRACT_ADDRESS`]}` :
                        i === 1 ? `${GetEnv()[`REACT_APP_CAKE_CONTRACT_ADDRESS`]}` :
                            `${GetEnv()[`REACT_APP_BUSD_CONTRACT_ADDRESS`]}`,
                    i,
                    FarmingHelper.readFarmingContract(farmContract,"rewardPerBlock")
                ),
                reward: farmingRewards()
            })
        }

        return data;
    }
}
