import styled from 'styled-components'
import { Card } from "@material-ui/core";

export const FarmCardComponentSm = styled(Card)`
    min-width: 200px;
    padding: 0;
    min-height: 230px;
    background: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(40px);
    border-radius: 17px !important;
`;

export const FarmCardComponent = styled(Card)`
    min-width: 100%;
    padding: 0;
    min-height: 230px;
    background: rgba(23, 24, 25, 0.7);
    box-shadow: 5px 5px 50px rgba(0, 0, 0, 0.7);
    backdrop-filter: blur(40px);
    border-radius: 17px !important;
`;
